import { Header } from "./Header";
import Html from "react-pdf-html";
import { Footer } from "./GeneratePdf";
import CommentSectionPDF from "./CommentSectionPDF";
import { Page, Text, Image, View } from "@react-pdf/renderer";
import { FlagIcon, VideoIcon } from "../../../assets/svg/Svg";
import { convertFontTagsToInlineStyles, stylesheet } from "./HtmlConverter";
import { contentStyles, commonStyles, inspectionDetailsStyles, definationStyles, fullReportStyles, mediaStyles } from "./PDFStyles";

export const FullReportPDF = ({
  // Toc, setToc,
  inspection_details,
  isISN,
  inspectiontemplates,
  color,
  company_html_report_setting,
}) => {
  // const addPageEntry = (name, pageNumber, type, id) => {
  //   const newArray = Toc.pageNumberArray;
  //   newArray?.push({ title: name, number: pageNumber, type: type, id: id });
  //   setToc((prevState) => ({ ...prevState, pageNumberArray: newArray }));
  // };
  return (
    <Page style={contentStyles.body}>
      <Header isISN={isISN} address={inspection_details?.address} clientDetails={inspection_details?.orderclient} companySettings={company_html_report_setting} />
      {inspectiontemplates
        ?.filter((item) => {
          // Check if the key exists in the object
          if (item.hasOwnProperty("is_pdf_template")) {
            // Filter based on a condition (if key exists)
            return item["is_pdf_template"] === 0; // Replace with your condition
          } else {
            // If the key doesn't exist, don't filter (include the item)
            return true;
          }
        })
        ?.map((value, index) => {
          return (
            <>
              {/* <Text render={({ pageNumber }) => addPageEntry(value.report_title ? value.report_title : value.name, pageNumber, "TEMPLATE", value.template_id)}></Text> */}
              <Text style={[fullReportStyles.templateTitle, { color: color, marginTop: index === 0 ? "0px" : "15px" }]}> {value.report_title ? value.report_title : value.name}</Text>
              {/* <Text render={({ pageNumber }) => addPageEntry("Definitions", pageNumber, "CATEGORY", `${value.template_id}_defination`)}></Text> */}
              <View style={commonStyles.headingContainer} wrap={false}>
                <Text id={`${value.template_id}_defination`} style={[commonStyles.headingTitle, { backgroundColor: color }]}>
                  {" "}
                  Definitions
                </Text>
              </View>
              {value?.rating_data?.map((value) => {
                return (
                  <View wrap={false} style={definationStyles.ratingWrapper}>
                    <View style={definationStyles.iconContainer}>
                      <View style={[definationStyles.iconWrapper, { backgroundColor: value.Color }]}>{value.include_in_summary === "1" || value.include_in_summary === 1 ? <FlagIcon /> : ""}</View>
                    </View>
                    <View style={definationStyles.ratingContainer}>
                      <Text style={definationStyles.ratingName}>{value.Name}</Text>
                      <Text style={definationStyles.ratingDisclaimer}>{value.Definition}</Text>
                    </View>
                  </View>
                );
              })}
              <Text style={[definationStyles.ratingDisclaimer, definationStyles.noteContainer, { marginTop: "20px" }]}>NOTE: All definitions listed above refer to the property or item listed as inspected on this report at the time of inspection.</Text>
              <Text style={[definationStyles.ratingDisclaimer, definationStyles.noteContainer, { marginTop: "14px" }]}>Flagged items will show up in summary view.</Text>
              {value?.categories
                ?.filter((value) => value?.is_inspection_details !== 1 || value?.is_deleted === 1 || value?.is_inspection_details !== 2)
                ?.map((value, index) => {
                  return (
                    <>
                      {/* <Text render={({ pageNumber }) => addPageEntry(value?.prompt, pageNumber, "CATEGORY", value?.category_id)}></Text> */}
                      <View style={[commonStyles.headingContainer, { marginTop: "10px" }]} wrap={false}>
                        <Text id={value?.category_id} style={[commonStyles.headingTitle, { backgroundColor: color }]}>
                          {" "}
                          {index + 1}. {value.prompt}
                        </Text>
                      </View>
                      {!value.introductory_text ? (
                        ""
                      ) : (
                        <View style={[fullReportStyles.htmlContainer, { paddingRight: "15px" }]}>
                          <Html stylesheet={stylesheet} style={{ fontSize: "10px", paddingRight: "15px" }}>
                            {convertFontTagsToInlineStyles(value.introductory_text)}
                          </Html>
                        </View>
                      )}
                      {value?.lines?.filter(e => e?.is_deleted === 0)?.map((lineValue, lineIndex) => {
                        const location = lineValue?.line_input_controls?.filter((e) => e?.type === "Location");
                        const nameplate = lineValue?.line_input_controls?.filter((e) => e?.type === "Nameplate");
                        const information = lineValue?.line_input_controls?.filter((e) => e?.type === "Information");
                        const description = lineValue?.line_input_controls?.filter((e) => e?.type === "Description");
                        return (
                          <>
                            <Text style={fullReportStyles.lineTitle}>
                              {index + 1}.{lineIndex + 1} {lineValue.prompt}
                            </Text>
                            <View style={[inspectionDetailsStyles.divider, { marginLeft: "10px", marginRight: "10px", border: `1px solid ${color}` }]}></View>
                            {description[0]?.data && description[0]?.data?.length > 0 && description[0]?.data[0] !== null
                              ? description?.map((value) => {
                                const { data, prompt } = value;
                                return (
                                  <View style={fullReportStyles.lineInputContainer}>
                                    <Text style={[fullReportStyles.lineDesc, { color: color }]}>
                                      {prompt}: <Text style={[definationStyles.ratingDisclaimer]}>{data?.map((el) => el).join(", ")}</Text>
                                    </Text>
                                  </View>
                                );
                              })
                              : ""}
                            {lineValue?.line_input_controls
                              ?.filter((e) => e?.type === "Additional Input")
                              ?.map((value) => {
                                const { data, prompt } = value;
                                return data.length > 0 ? (
                                  <View style={fullReportStyles.lineInputContainer}>
                                    <Text style={[fullReportStyles.lineDesc, { color: color }]}>
                                      {prompt}: <Text style={[definationStyles.ratingDisclaimer]}>{data?.map((el) => el).join(", ")}</Text>
                                    </Text>
                                  </View>
                                ) : (
                                  ""
                                );
                              })}
                            {information[0]?.data && information[0]?.data?.length > 0 ? (
                              <>
                                {typeof information[0]?.data === "string" && information[0]?.data !== "<p><br></p>" ? (
                                  <View style={[fullReportStyles.lineInputContainer]}>
                                    <Text style={[fullReportStyles.lineDesc, { color: color, marginTop: "2px" }]}>{`${information[0].prompt}: `}</Text>
                                    {information[0]?.data ? (
                                      <View style={[fullReportStyles.commentHTMLContainer, { flex: 1 }]}>
                                        <Html stylesheet={stylesheet} style={{ fontSize: "10px" }}>
                                          {convertFontTagsToInlineStyles(information[0]?.data)}
                                        </Html>
                                      </View>
                                    ) : (
                                      ""
                                    )}
                                  </View>
                                ) : (
                                  <>
                                    {information?.map((value, index) => {
                                      const { data, prompt } = value;
                                      return (
                                        <>
                                          {data[index] && data[index]?.length > 0 && data[index] !== "<p><br></p>" ? (
                                            <View style={[fullReportStyles.lineInputContainer]}>
                                              <Text style={[fullReportStyles.lineDesc, { color: color, marginTop: "2px" }]}>{prompt}: </Text>
                                              <View style={[fullReportStyles.commentHTMLContainer, { flex: 1 }]}>
                                                <Html stylesheet={stylesheet} style={{ fontSize: "10px" }}>
                                                  {convertFontTagsToInlineStyles(data[index])}
                                                </Html>
                                              </View>
                                            </View>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      );
                                    })}
                                  </>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            {location[0]?.data && location[0]?.data?.length > 0 ? (
                              <Text style={[fullReportStyles.lineDesc, { color: color }]}>
                                {location[0]?.prompt}:{" "}
                                {typeof location[0]?.data === "string" ? (
                                  <Text style={[definationStyles.ratingDisclaimer, { flex: 1 }]}>{location[0]?.data?.replace(/,/g, ", ")}</Text>
                                ) : typeof location[0]?.data === "object" ? (
                                  location[0]?.data?.map((item, index) => {
                                    return (
                                      <>
                                        <Text style={[definationStyles.ratingDisclaimer, { flex: 1 }]}>
                                          {item}
                                          {index < location[0]?.data?.length - 1 && ", "}
                                        </Text>
                                      </>
                                    );
                                  })
                                ) : (
                                  ""
                                )}
                              </Text>
                            ) : (
                              ""
                            )}
                            {nameplate[0]?.data ? (
                              <>
                                {nameplate?.map((value) => {
                                  const { data } = value;
                                  return (
                                    <>
                                      {data.modelNumber || data.serialNumber || data.onlineUrl ? <Text style={[fullReportStyles.lineDesc, { color: color, marginTop: "5px" }]}>{nameplate[0]?.prompt}:</Text> : ""}
                                      {data.modelNumber ? <Text style={[definationStyles.ratingDisclaimer, { marginLeft: "10px", marginTop: "1px" }]}>{`Model number - ${data.modelNumber}`}</Text> : ""}
                                      {data.serialNumber ? <Text style={[definationStyles.ratingDisclaimer, { marginLeft: "10px", marginTop: "1px" }]}>{`Serial number - ${data.serialNumber}`}</Text> : ""}
                                      {data.onlineUrl && data.online === "online" && data.is_deleted !== 1 ? (
                                        <View style={mediaStyles.wrapper}>
                                          <View style={mediaStyles.div} wrap={false}>
                                            {data.onlineUrl || data?.thumbnail ? data?.thumbnail ? <Image style={mediaStyles.image} src={data.thumbnail}></Image> : data.onlineUrl ? <Image style={mediaStyles.image} src={data.onlineUrl}></Image> : "" : ""}
                                            <Text style={mediaStyles.text}>{data.description}</Text>
                                          </View>
                                        </View>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              ""
                            )}
                            {lineValue?.line_input_controls
                              ?.filter((e) => e?.type === "Media")
                              ?.map((value) => {
                                const { data, prompt } = value;
                                return data?.filter((e) => e?.online === "online")?.length > 0 ? (
                                  <>
                                    <Text style={[fullReportStyles.lineDesc, { color: color, marginTop: "1px" }]}>{prompt}:</Text>
                                    <View style={mediaStyles.wrapper}>
                                      {data
                                        ?.filter((e) => e?.online === "online")
                                        ?.map((mediaValue) => {
                                          const { type, onlineUrl, description, thumbnail } = mediaValue;
                                          return type?.includes("image") ? (
                                            <View style={mediaStyles.div}>
                                              {thumbnail ? <Image wrap={false} style={mediaStyles.image} src={thumbnail}></Image> : <Image wrap={false} style={mediaStyles.image} src={onlineUrl}></Image>}
                                              <Text style={[mediaStyles.text, { textAlign: description?.length > 50 ? "justify" : "center" }]}>{description}</Text>
                                            </View>
                                          ) : type?.includes("video") ? (
                                            <View style={mediaStyles.div}>
                                              <View style={mediaStyles.innerDiv}>
                                                <Image style={mediaStyles.image} src={thumbnail}></Image>
                                                <View style={fullReportStyles.videoContainer}>
                                                  <View wrap={false} style={fullReportStyles.videoInnerContainer}>
                                                    <View style={fullReportStyles.videoIconContainer}>
                                                      <View style={fullReportStyles.videoIconInnerContainer}>
                                                        <View style={fullReportStyles.videoIcon}>
                                                          <VideoIcon />
                                                        </View>
                                                      </View>
                                                      <View style={fullReportStyles.videoTitleContainer}>
                                                        <Text style={fullReportStyles.videoTitle}>Video</Text>
                                                      </View>
                                                    </View>
                                                  </View>
                                                </View>
                                              </View>
                                              <Text style={[mediaStyles.text, { textAlign: description?.length > 50 ? "justify" : "center" }]}>{description}</Text>
                                            </View>
                                          ) : (
                                            ""
                                          );
                                        })}
                                    </View>
                                  </>
                                ) : (
                                  ""
                                );
                              })}
                            <CommentSectionPDF comments={lineValue?.comments} color={color} />
                          </>
                        );
                      })}
                    </>
                  );
                })}
            </>
          );
        })}
      <Footer isISN={isISN} />
    </Page>
  );
};
