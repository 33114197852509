import { useState } from "react";
import ContactUs from "../../contactUs/ContactUs";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Button, Divider, Grid, Typography } from "@mui/material";
import { NarrativeLocationIcon, NarrativePhoneIcon } from "../../../../assets/svg/Svg";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LanguageIcon from '@mui/icons-material/Language';

const NarrativeInspectorDetailsLayout = (props) => {
    const { inspectorDetails, company, isISN } = props;
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [inspEmail, setInspEmail] = useState("");
    const handleOpen = (email) => {
        setInspEmail(email);
        setOpen(true);
    };

    return (
        <>
            <ContactUs isISN={isISN} open={open} handleClose={handleClose} inspEmail={inspEmail} />
            {inspectorDetails?.map((value, index) => {
                const address = `${value?.inspector?.location_address_1}, ${value?.inspector?.location_address_2 ? value?.inspector?.location_address_2 :""}, ${value?.inspector?.city}, ${value?.inspector?.state} ${value?.inspector?.zip}`;
                return (
                    <Grid container sx={{ p: "0px 12px 12px 12px" }} key={index}>
                        <Grid item xs={12}>
                            <Typography className="narrative-details-heading-small">INSPECTOR {inspectorDetails?.length > 1 ? `${index + 1}` : ""}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider className="narrative-details-divider" />
                        </Grid>
                        <Grid item xs={12} className="details-inpector-name">
                            {value?.inspector?.first_name} {value?.inspector?.last_name}
                        </Grid>
                        {!company?.name ? (
                            ""
                        ) : (
                            <Grid item xs={12} className="narrative-details-inspection-name">
                                {company?.name}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container sx={{ gap: "6px", mt: "25px" }}>
                                {address?.replace(/null,/g, "")?.replace(/null/g, "")?.replace(/ ,/g, "")?.trim() !== "" && <Grid item xs={12} className="narrative-details">
                                    <NarrativeLocationIcon />
                                    <Typography className="narrative-details-font-size" sx={{ overflowWrap: "anywhere" }}>{address?.replace(/null,/g, "")?.replace(/null/g, "")?.replace(/ ,/g, "")}
                                    </Typography>
                                </Grid>}
                                {!value?.inspector?.phone || value?.inspector?.phone === '--' ? (
                                    ""
                                ) : (
                                    <Grid item xs={12} className="narrative-details">
                                        <NarrativePhoneIcon />
                                        <Typography sx={{ overflowWrap: "anywhere" }} className="narrative-details-font-size" >
                                            {value?.inspector?.phone}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12} className="narrative-details">
                                    <MailOutlineIcon className="narrative-mail-icon" />
                                    <Typography sx={{ overflowWrap: "anywhere" }} className="narrative-details-font-size">
                                        {value?.inspector?.email}
                                    </Typography>
                                </Grid>
                                {!company?.website ? (
                                    ""
                                ) : (
                                    <Grid item xs={12} className="narrative-details">
                                        <LanguageIcon className="narrative-mail-icon" />
                                        <Typography sx={{ overflowWrap: "anywhere" }} className="narrative-details-font-size">
                                            {company?.website}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Button className="narrative-details-sendusamessage" disableRipple variant="none" onClick={() => handleOpen(value?.inspector?.email)} endIcon={<ArrowForwardIosIcon className="narrative-arrow-icon" />}>
                                Send a message
                            </Button>
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};
export default NarrativeInspectorDetailsLayout;