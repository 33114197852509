import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Grid, TextareaAutosize, TextField } from "@mui/material";
import { apiCall } from "../../utils/action";
import Preloader from "../../helpers/Preloader";
import validator from "validator";
import toast from "react-hot-toast";
import { useValidatableForm, ReactValidatableFormProvider } from "react-validatable-form";
import "../../../assets/styles/containers/_shareReport.scss";
import { LoadingButton } from "@mui/lab";
import get from "lodash.get";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CreateRequestListModal = ({ isISN, createReqList, setCreateReqList, subject, setSubject, clientData, inspectionDetails }) => {
  const [emailBody, setEmailBody] = useState("");
  let clientEmail = [];
  let clientName = [];
  console.log(isISN);
  clientData?.forEach((value) => {
    clientEmail.push(value?.client?.email);
    clientName.push(`${value?.client?.first_name} ${value?.client?.last_name}`);
  });

  const navigate = useNavigate();
  
  let count = 0;
  const [showLoader, setShowLoader] = useState(false);
  const customRule = (ruleParams) => {
    const { value } = ruleParams;
    if (!value) {
      return "Email is required";
    } else if (value && value?.includes(",")) {
      return "Please provide single email";
    } else if (!validator.isEmail(value)) {
      return "Please enter a valid email address";
    }
    return null;
  };
  const initialFormData = {
    emails: [""],
    textVal1: "",
    // textVal2: `Your request list is ready to be reviewed. Please review your inspection and feel free to reach out if you have questions. `,
    textVal2: `Hi,\n\nThe request list for the property at ${inspectionDetails?.location_address} is ready to view at \n\n${window.location.origin}/preview-report/?guid=${sessionStorage.getItem("guid")}\n\nPlease review your inspection and feel free to contact us if you have questions. You can email us at ${inspectionDetails?.orderinspector[0]?.inspector?.email} ${inspectionDetails?.orderinspector[0]?.inspector?.phone !== null ? `or call ${inspectionDetails?.orderinspector[0]?.inspector?.phone}` : ""}and we would be happy to assist you.\n\n${inspectionDetails?.orderinspector[0]?.inspector?.first_name} ${inspectionDetails?.orderinspector[0]?.inspector?.last_name}\n${inspectionDetails?.company?.name}
    `,
  };
  const rules = [
    { path: "textVal1", ruleSet: [{ rule: "required", customMessage: "Subject is required" }] },
    { path: "textVal2", ruleSet: [{ rule: "required", customMessage: "Message is required." }] },
    {
      listPath: "emails",
      ruleSet: [{ rule: customRule }],
    },
  ];
  const MyForm = () => {
    const { isValid, validationError, formData, setPathValue, setFormIsSubmitted } = useValidatableForm({
      rules,
      initialFormData,
      hideBeforeSubmit: true,
    });

    const shareReport = () => {
      setShowLoader(true);
      const body = get(formData, "textVal2");
      const params = {
        sender_name: clientName[0],
        sender_email: clientEmail,
        recipient_email: get(formData, "emails"),
        email_subject: get(formData, "textVal1"),
        email_body: body.replace(/\n/g, "<br />"),
        inspection_from: isISN ? "ISN" : "Palmtech",
      };
      apiCall(
        (response) => {
          const { message, success } = response.data;
          if (success) {
            toast.success(message);
            setCreateReqList(false);
            setShowLoader(false);
          } else {
            toast.error(message);
            setShowLoader(false);
          }
        },
        params,
        "CREATE_REPORT_MAIL"
      );
    };

    const addEmail = () => {
      const updatedEmails = get(formData, "emails");
      if (count < 4) {
        updatedEmails.push("");
        count++;
      }
      setPathValue("emails", updatedEmails);
    };

    const removeEmail = (index) => {
      const updatedEmails = get(formData, "emails");
      if (count > 0) {
        updatedEmails.splice(index, 1);
        count--;
      }
      setPathValue("emails", updatedEmails);
    };

    const formSubmitted = () => {
      setFormIsSubmitted();
      if (isValid) {
        shareReport();
      }
    };

    const emailList = get(formData, "emails").map((email, index) => {
      return (
        <TextField
          fullWidth
          variant="standard"
          id="standard-basic"
          label="Recipient Email"
          value={get(formData, `emails[${index}]`)}
          error={get(validationError, `emails{${index}}`) && "error"}
          helperText={get(validationError, `emails{${index}}`) || " "}
          onChange={(e) => setPathValue(`emails[${index}]`, e.target.value)}
          InputProps={{
            endAdornment:
              index > 0 ? (
                <IconButton onClick={() => removeEmail(index)}>
                  <CloseIcon />
                </IconButton>
              ) : (
                ""
              ),
          }}
        />
      );
    });

    return (
      <Grid container>
        <Grid item xs={12}>
          {emailList}
          {count < 4 && (
            <Button variant="none" className="add-recipient-button create_request" style={{ marginTop: "0px!important" }} endIcon={<AddIcon />} onClick={addEmail}>
              Add recipient
            </Button>
          )}
          <TextField fullWidth id="standard-basic" label="Subject" variant="standard" value={get(formData, `textVal1`)} error={get(validationError, `textVal1`) && "error"} helperText={get(validationError, `textVal1`) || " "} onChange={(e) => setPathValue(`textVal1`, e.target.value)} />
          <TextareaAutosize fullWidth multiline minRows={10} className="contactus-messagebox p-5" id="outlined-basic" placeholder="Message" variant="outlined" value={get(formData, `textVal2`)} error={get(validationError, `textVal2`) && "error"} helperText={get(validationError, `textVal2`) || " "} onChange={(e) => setPathValue(`textVal2`, e.target.value)} style={{ width: "100%", resize: "none" }} />
          <LoadingButton loading={showLoader} onClick={formSubmitted} className="contactus-send-button" variant="none">
            Send
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Preloader showPreloader={showLoader} />
      <BootstrapDialog className="req__modal" onClose={() => setCreateReqList(false)} aria-labelledby="customized-dialog-title" open={createReqList}>
        <DialogContent>
          <Grid container className="req__modal__container">
            <Grid item xs={12}>
              <Grid className="req__modal__header">
                <span>Request List</span>
                <span onClick={() => setCreateReqList(false)}>
                  <CloseIcon />
                </span>
              </Grid>
              <Grid className="req__modal__subhead">
                <span>
                  <DoneIcon /> Your request list has been created.
                </span>
                <Button className="border__button" sx={{ marginRight: "6px", width: "130px" }} disableFocusRipple onClick={() => window.open(`/preview-report/?guid=${sessionStorage.getItem("guid")},'_blank'`)}>
                  <span className="border__btn__text">Preview</span>
                </Button>
              </Grid>
              <Grid className="req__modal__email__container">
                <ReactValidatableFormProvider>
                  <MyForm />
                </ReactValidatableFormProvider>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default CreateRequestListModal;
