//file imports
import { Font, StyleSheet } from "@react-pdf/renderer";
import TinosBold from "../../../assets/fonts/Tinos-Bold.ttf";
import FontBold from "../../../assets/fonts/OpenSans-Bold.ttf";
import TinosItalic from "../../../assets/fonts/Tinos-Italic.ttf";
import TinosRegular from "../../../assets/fonts/Tinos-Regular.ttf";
import FaustinaBold from "../../../assets/fonts/Faustina-Bold.ttf";
import FontSource from "../../../assets/fonts/OpenSans-Regular.ttf";
import TimesNewRoman from "../../../assets/fonts/timesNewRoman.ttf";
import FaustinaItalic from "../../../assets/fonts/Faustina-Italic.ttf";
import OpenSansItalic from "../../../assets/fonts/OpenSans-Italic.ttf";
import FontSemiBold from "../../../assets/fonts/OpenSans-SemiBold.ttf";
import TinosItalicBold from "../../../assets/fonts/Tinos-BoldItalic.ttf";
import FaustinaRegular from "../../../assets/fonts/Faustina-Regular.ttf";
import FaustinaSemiBold from "../../../assets/fonts/Faustina-SemiBold.ttf";
import TimesNewRomanBold from "../../../assets/fonts/timesNewRoman-Bold.ttf";
import FaustinaBoldItalic from "../../../assets/fonts/Faustina-BoldItalic.ttf";
import OpensSansItalicBold from "../../../assets/fonts/OpenSans-BoldItalic.ttf";
import TimesNewRomanItalic from "../../../assets/fonts/timesNewRoman-Italic.ttf";
// the below code commented now, might be used later
// Font.registerHyphenationCallback((word) => [word]);
// OpenSans
Font.register({ family: "Open Sans", fonts: [{ src: FontSource }, { src: OpenSansItalic, fontWeight: 400, fontStyle: "italic" }, { src: OpensSansItalicBold, fontWeight: 600, fontStyle: "italic" }, { src: FontSemiBold, fontWeight: 600 }, { src: FontBold, fontWeight: 700 }], format: "truetype" });

// Faustina

Font.register({
  family: "Faustina",
  format: "truetype",
  fonts: [{ src: FaustinaRegular }, { src: FaustinaItalic, fontWeight: 400, fontStyle: "italic" }, { src: FaustinaBoldItalic, fontWeight: 600, fontStyle: "italic" }, { src: FaustinaSemiBold, fontWeight: 600 }, { src: FaustinaBold, fontWeight: 700 }],
});
// Tinos
Font.register({
  family: "Tinos",
  fonts: [{ src: TinosRegular }, { src: TinosBold, fontWeight: 700 }, { src: TinosItalic, fontStyle: "italic" }, { src: TinosItalicBold, fontStyle: "italic", fontWeight: 700 }],
});
// TimesNewRoman
Font.register({
  family: "Times New Roman",
  fonts: [{ src: TimesNewRoman }, { src: TimesNewRomanBold, fontWeight: 700 }, { src: TimesNewRomanItalic, fontStyle: "italic" }],
});
//colors
const colors = {
  grey: "#4D4D4D",
  black: "#000000",
  white: "#FFFFFF",
  greyish: "#979797",
  linkBlue: "#0000F6",
  titanGrey: "#2C2C2C",
  coverGrey: "#f1f3f4",
  lightGrey: "#3b3b3b",
  whiteGrade1: "#E6E6E6",
  lightGreyGrade1: "#666666",
  blue: sessionStorage.getItem("primaryColor") === null ? "#394B8A" : sessionStorage.getItem("primaryColor"),
};

//style sheets****************************************************

export const styles = StyleSheet.create({
  grid: { display: "grid" },
  emptyCoverPic: { height: "400px" },
  subtitle: { fontSize: 18, margin: 12 },
  text: { margin: 12, fontSize: 14, textAlign: "justify" },
  author: { fontSize: 12, textAlign: "center", marginBottom: 40 },
  companyLogo: { height: "35px", width: "163.64px", objectFit: "scale-down" },
  header: { fontSize: 12, marginBottom: 20, textAlign: "center", color: "grey" },
  coverBody: { paddingTop: 28, fontStyle: "normal", paddingHorizontal: 20, fontFamily: "Open Sans" },
  date: { fontSize: "10px", fontWeight: 400, color: colors.black, marginTop: "34.6px", marginLeft: "25.68px" },
  title: { marginTop: "6px", fontSize: "14px", color: colors.lightGrey, fontWeight: 600, marginLeft: "25.68px" },
  insptitle: { fontSize: "9px", fontWeight: 600, marginTop: "29px", marginBottom: "3px", marginLeft: "25.68px" },
  inspValue: { fontSize: "14px", marginLeft: "25.68px", marginTop: "4px", marginBottom: "5px", fontWeight: 400 },
  pageTitle: { color: colors.lightGrey, fontSize: "37px", fontWeight: 900, marginTop: "36px", marginLeft: "25.68px" },
  pageNumber: { position: "absolute", fontSize: 12, bottom: 30, left: 0, right: 0, textAlign: "center", color: "grey" },
  coverPic: { height: "100%", marginLeft: -25, marginRight: -25, marginTop: "39px", objectFit: "scale-down", backgroundColor: colors.coverGrey },
});

export const contentStyles = StyleSheet.create({
  headerWrapper: { flexDirection: "row", marginBottom: "15px" },
  footerLeft: { fontSize: "8px", fontWeight: 400, color: colors.grey },
  headerRight: { fontSize: "8px", color: colors.lightGrey, fontWeight: 400 },
  headerLeft: { height: "27px", width: "113.45px", objectFit: "scale-down" },
  headerRightInfo: { fontSize: "8px", color: colors.greyish, fontWeight: 400 },
  footerRight: { color: colors.lightGrey, fontSize: "10px", fontWeight: 600, textAlign: "right" },
  footerContainer: { flexDirection: "row", marginTop: "20px", position: "absolute", bottom: "20px", left: "24px", right: "24px" },
  body: { fontStyle: "normal", paddingVertical: "22px", fontFamily: "Open Sans", paddingHorizontal: "19px", paddingBottom: "60px" },
});

export const commonStyles = StyleSheet.create({
  flexContainer: { flex: 1 },
  flexWrapper: { flexDirection: "row" },
  iconWrapper: { flex: 0.1, paddingTop: "2px" },
  flexContainerRight: { flex: 1, textAlign: "right" },
  headingContainer: { height: "30px", paddingTop: "3px" },
  htmlBlock: { fontSize: "10px", fontFamily: "Open Sans" },
  headerWrapper: { flexDirection: "row", marginBottom: "15px" },
  alignClass: { textAlign: "right", justifyContent: "right", float: "right" },
  headingTitle: { color: colors.white, fontSize: "18px", fontWeight: 600, letterSpacing: "0.26667px" },
});

export const coverPageStyles = StyleSheet.create({
  qrContainer: { marginTop: "10px" },
  qrImage: { width: "85px", height: "85px", top: "-10px" },
  qrTitle: { top: "-38px", fontSize: "9px", color: colors.lightGrey },
  qrWrapper: { justifyContent: "flex-end", textAlign: "right", alignContent: "flex-end", display: "flex", width: "85px", height: "85px" },
});

export const ToCStyles = StyleSheet.create({
  link: { textDecoration: "none", justifyContent: "right", paddingTop: "10px" },
  tocContainer: { marginTop: "24px", paddingLeft: "100px", paddingRight: "85px" },
  summaryTitle: { fontSize: "12px", fontWeight: 600, color: colors.grey, marginTop: "32px", textDecoration: "none" },
  categoryTitle: { fontSize: "12px", fontWeight: 400, marginTop: "5px", color: colors.grey, textDecoration: "none" },
  templateTitle: { fontWeight: 600, fontSize: "9px", color: colors.blue, textTransform: "uppercase" },
});

export const inspectionDetailsStyles = StyleSheet.create({
  value: { fontWeight: 600, color: colors.grey, fontSize: "10px" },
  title: { fontWeight: 600, fontSize: "12px", color: colors.lightGrey },
  valueTitle: { fontWeight: 400, fontSize: "10px", color: colors.lightGreyGrade1 },
  titleRight: { fontSize: "10px", fontWeight: 400, color: colors.lightGreyGrade1 },
  divider: { marginTop: "12px", marginBottom: "12px", border: `1px solid ${colors.blue}` },
  wrapper: { flexDirection: "row", marginBottom: "15px", padding: "7px", marginTop: "10px" },
  dividerGrey: { marginTop: "5px", marginBottom: "5px", border: `0.5px solid ${colors.whiteGrade1}` },
  titleLeft: { fontWeight: 600, fontSize: "9px", letterSpacing: "0.19962px", color: colors.lightGreyGrade1 },
  blockTitle: { textTransform: "uppercase", color: colors.blue, fontWeight: 600, fontSize: "9px", marginBottom: "1.5px" },
});

export const fullReportStyles = StyleSheet.create({
  videoTitleContainer: { marginRight: "5px" },
  videoIconInnerContainer: { paddingTop: "2px", marginRight: "2px" },
  videoTitle: { fontSize: "9px", fontWeight: "400", color: colors.black },
  videoContainer: { position: "absolute", right: "15px", bottom: "10px" },
  htmlContainer: { paddingLeft: "10px", marginTop: "9px", paddingRight: "5px" },
  locationIcon: { flexDirection: "row", marginLeft: "15px", marginRight: "5px" },
  videoIcon: { width: "10px", height: "10px", borderRadius: "1.5px", marginLeft: "5px" },
  lineInputContainer: { flexDirection: "row", marginBottom: "5px", paddingRight: "10px" },
  videoIconContainer: { flexDirection: "row", display: "flex", justifyContent: "flex-end" },
  commentDividerGrey: { marginTop: "3px", marginBottom: "3px", border: `0.7px solid ${colors.whiteGrade1}` },
  dividerGrey: { marginTop: "5px", marginBottom: "5px", border: `0.7px solid ${colors.whiteGrade1}` },
  templateTitle: { fontWeight: 600, fontSize: "10px", color: colors.grey, textTransform: "uppercase", marginBottom: "10px" },
  lineDesc: { color: colors.blue, fontSize: "9px", textTransform: "uppercase", fontWeight: 600, marginLeft: "10px", marginTop: "1px" },
  lineTitle: { fontWeight: 600, fontSize: "12px", color: colors.lightGrey, textTransform: "uppercase", marginTop: "11px", paddingLeft: "10px", paddingRight: "10px" },
  videoInnerContainer: { align: "right", height: "15px", borderRadius: "38px", backgroundColor: colors.white, width: "50px", marginRight: "-15px", paddingLeft: "2px", paddingRight: "2px", paddingTop: "1px" },
  commentHTMLContainer: { padding: "0px 13px 0px 0px", marginRight: "15px", textAlign: "justify", textJustify: "inter-word" },
});

export const definationStyles = StyleSheet.create({
  iconContainer: { flex: 0.1 },
  ratingContainer: { flex: 1, top: -2 },
  noteContainer: { paddingLeft: "10px", paddingRight: "10px" },
  ratingName: { color: colors.grey, fontWeight: 700, fontSize: "11px" },
  ratingWrapper: { flexDirection: "row", marginTop: "13px", paddingLeft: "10px", paddingRight: "10px" },
  iconWrapper: { height: "24px", width: "24px", borderRadius: "3px", paddingTop: "6px", paddingLeft: "6px" },
  ratingDisclaimer: { color: colors.grey, textTransform: "none !important", fontWeight: 400, fontSize: "10px", textAlign: "justify", textJustify: "inter-word" },
});

export const mediaStyles = StyleSheet.create({
  innerDiv: { position: "relative" },
  div: { width: "135px", padding: "6px" },
  text: { fontFamily: "Open Sans", marginLeft: "5px", marginTop: "3px", fontWeight: 400, fontSize: "10px", color: colors.lightGrey },
  image: { width: "132.22px", height: "106px", objectFit: "contain", imageOrientation: "from-image !important", backgroundColor: "#f1f3f4" },
  wrapper: { display: "flex", flexWrap: "wrap", flexDirection: "row", marginLeft: "5px", textAlign: "center", marginBottom: "5px" },
});

export const SummaryStyles = StyleSheet.create({
  disclaimerWrapper: { paddingLeft: "6px", paddingRight: "6px" },
  templateTitleWrapper: { marginTop: "12px", marginBottom: "5px" },
  templateTitle: { fontWeight: 600, fontSize: "12px", color: colors.grey, textTransform: "uppercase" },
});

export const RatingStyles = StyleSheet.create({
  flagInnerContainer: { paddingTop: "2px", marginRight: "2px" },
  flag: { width: "10px", height: "10px", borderRadius: "1.5px", marginLeft: "5px" },
  outerContainer: { display: "flex", justifyContent: "flex-end", wordBreak: "break-all" },
  textContainer: { fontSize: "9px", fontWeight: "400", color: colors.lightGreyGrade1, wordBreak: "break-all" },
  innerContainer: { flexDirection: "row", display: "block", wordBreak: "break-all", flexWarp: "wrap" },
  flagContainer: { flexDirection: "row", display: "flex", justifyContent: "flex-end", flex: "1 0 auto", wordBreak: "break-all" },
  coloredContainer: { height: "18px", borderRadius: "38px", minWidth: "20px", marginRight: "2px", paddingLeft: "2px", paddingRight: "2px", paddingTop: "1px" },
});

export const TexasDescriptionStyles = StyleSheet.create({
  page: { padding: "44px 20px 55px 20px" },
  pageDis: { padding: "44px 20px 47px 20px" },
  pageContainer: { display: "flex", alignItems: "center" },
  detailsDivider: { border: "1px solid #000000", marginTop: "2px" },
  subHeading: { fontFamily: "Tinos", fontWeight: 700, fontSize: "10px", color: colors.black },
  italicTimes: { fontFamily: "Times New Roman", fontStyle: "italic", fontSize: "10.5px", color: colors.black },
  details: { fontFamily: "Tinos", fontStyle: "italic", fontSize: "9.5px", color: colors.black, fontWeight: 700 },
  propertyHeading: { marginLeft: "40px", marginTop: "25px", fontFamily: "Tinos", fontWeight: 700, fontSize: "22px" },
  detailTitle: { fontFamily: "Tinos", fontWeight: 400, fontSize: "11px", color: colors.titanGrey },
  additionalInfoHeading: { marginTop: "2px", fontFamily: "Open Sans", fontWeight: 700, fontSize: "10px", textAlign: "center" },
  heading: { fontFamily: "Tinos", fontWeight: 700, fontSize: "10px", color: colors.black, textDecoration: "underline" },
  content: { marginTop: "7px", fontStyle: "normal", textAlign: "left", fontFamily: "Faustina", fontWeight: 700, fontSize: "10.5px", color: colors.black, textDecoration: "underline" },
  bulletText: { fontSize: "10.5px", fontWeight: 400, fontFamily: "Faustina", fontStyle: "normal", color: colors.black },
});

export const TexasFooterStyles = StyleSheet.create({
  footerContainer: { marginTop: "20px", position: "absolute", bottom: "20px", left: "24px", right: "24px" },
  footerLeft: { fontFamily: "Times New Roman", fontStyle: "normal", fontSize: "9.5px", fontWeight: 400, color: colors.black },
  footerRight: { fontFamily: "Times New Roman", fontStyle: "normal", fontSize: "10px", fontWeight: 400, color: colors.black },
  footerRightLink: { fontFamily: "Times New Roman", textDecoration: "underline", fontSize: "10px", fontWeight: 400, color: colors.linkBlue },
  pageNumber: { textAlign: "center", fontSize: "10.5px", fontWeight: 400, fontFamily: "Faustina", fontStyle: "normal", color: colors.black },
});

export const TexasFullReportStyles = StyleSheet.create({
  div: { width: "110px", padding: "6px" },
  page: { padding: "20px 30px 60px 30px" },
  commentPrompt: { fontSize: "10.5px", fontWeight: 700, fontFamily: "Faustina", fontStyle: "normal", color: colors.black },
  ratingHeading: { fontFamily: "Times New Roman", marginTop: "8px", fontSize: "9.5px", fontWeight: 700, color: colors.black },
  reportIdentification: { marginLeft: "7px", fontSize: "10px", fontFamily: "Tinos", fontStyle: "normal", fontWeight: 400, color: colors.black },
  ratingBlock: { marginBottom: "5px", flexDirection: "row", gap: "6px", marginTop: "3px", height: "18px", border: `1.5px solid ${colors.black}`, fontFamily: "Times New Roman", paddingLeft: "6px", fontSize: "10px", fontWeight: 700, width: "100%" },
  image: { width: "104.25px", height: "75px", objectFit: "contain", imageOrientation: "from-image !important", backgroundColor: "#f1f3f4" },
  TemplateHeading: { textTransform: "uppercase", marginTop: "7px", fontFamily: "Times New Roman", fontWeight: 700, fontSize: "12px", textAlign: "center", color: colors.black },
});

export const NarrativePDFStyles = StyleSheet.create({
  bulletText: { fontSize: "10px", fontWeight: 400, paddingLeft: "5px", marginTop:"2px" },
  narrativeCoverPage: { height: "100%", width: "100%" },
  prefaceContainer: { paddingRight: "10px", paddingTop: "10px" },
  pageContainer: { padding: "15px 20px 25px 20px", color: colors?.lightGrey },
  prefaceText: { fontFamily: "Open Sans", fontSize: "10px", lineHeight: "15px", color: colors?.lightGrey, },
  prefaceHeading: { color: colors.grey, fontWeight: 700, fontSize: "11px", marginLeft: "5px", marginBottom: "10px" }
})

export const NarrativeMediaStyles = StyleSheet.create({
  innerDiv: { position: "relative" },
  div: { width: "230px", padding: "6px" },
  text: { fontFamily: "Open Sans", marginLeft: "5px", marginTop: "3px", fontWeight: 700, fontSize: "10px", color: colors.grey },
  image: { width: "229.22px", height: "150px", objectFit: "contain", imageOrientation: "from-image !important", backgroundColor: "#f1f3f4" },
  wrapper: { display: "flex", gap:"10px", flexWrap: "wrap", flexDirection: "row", marginLeft: "5px", textAlign: "center", marginBottom: "1px" },
});
