import Html from "react-pdf-html";
import moment from "moment/moment";
import { CoverPage } from "./CoverPage";
import TRECLogo from "../../../assets/images/TREC.png";
import { convertFontTagsToInlineStylesTexas, convertFontTagsToInlineStylesTexasRecommendation, stylesheet } from "./HtmlConverter";
import { Page, Text, Document, Image, View } from "@react-pdf/renderer";
import { DotIcon, VideoIcon, BulletDotIcon, CorrectTickIcon } from "../../../assets/svg/Svg";
import { commonStyles, TexasFooterStyles, fullReportStyles, mediaStyles, TexasDescriptionStyles, TexasFullReportStyles } from "./PDFStyles";
const Producer = "PalmTech";
const Subject = "Home Inspection Report";
const year = new Date();
let isSelectDefaultInspected;
let regex = /style="([^"]*)font-family:([^"]*)"/g;
const CompanyTag = `Copyright © 1998-${year.getFullYear()}, Palmtech`;

const TexasFooter = ({ showPageNumber }) => (
  <View style={TexasFooterStyles.footerContainer} fixed>
    {showPageNumber ? (
      <View style={commonStyles.flexContainer}>
        <Text style={TexasFooterStyles.pageNumber} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} />
      </View>
    ) : (
      ""
    )}
    <View style={{ flexDirection: "row", marginTop: "14px" }}>
      <View style={commonStyles.flexContainer}>
        <Text style={TexasFooterStyles.footerLeft}>REI 7-6 (8/9/21)</Text>
      </View>
      <Text style={TexasFooterStyles.footerRight}>Promulgated by the Texas Real Estate Commission </Text>
      <View style={{ marginTop: "4px", marginRight: "4px" }}>
        <BulletDotIcon />
      </View>
      <Text style={TexasFooterStyles.footerRight}>(512) 936-3000 </Text>
      <View style={{ marginTop: "4px", marginRight: "4px" }}>
        <BulletDotIcon />
      </View>
      <Text style={TexasFooterStyles.footerRightLink}>www.trec.texas.gov</Text>
    </View>
  </View>
);

const BulletPoints = ["malfunctioning, improperly installed, or missing ground fault circuit protection (GFCI) devices and arc-fault (AFCI) devices;", "ordinary glass in locations where modern construction techniques call for safety glass;", "malfunctioning or lack of fire safety features such as smoke alarms, fire-rated doors in certain locations, and functional emergency escape and rescue openings in bedrooms;", "malfunctioning carbon monoxide alarms;", "excessive spacing between balusters on stairways and porches;", "improperly installed appliances;", "improperly installed or defective safety devices;", "lack of electrical bonding and grounding; and", "lack of bonding on gas piping, including corrugated stainless steel tubing (CSST)."];

const InspectorBulletPoints = ["use this Property Inspection Report form for the inspection;", "inspect only those components and conditions that are present, visible, and accessible at the time of the inspection;", "indicate whether each item was inspected, not inspected, or not present;"];

const InpectorNotBulletPoints = ["identify all potential hazards;", "turn on decommissioned equipment, systems, utilities, or apply an open flame or light a pilot to operate any appliance;", "climb over obstacles, move furnishings or stored items;", "prioritize or emphasize the importance of one deficiency over another;", "provide follow-up services to verify that proper repairs have been made; or", "inspect system or component listed under the optional section of the SOPs (22 TAC 535.233)."];

const InspectionNotBulletPoints = ["a technically exhaustive inspection of the structure, its systems, or its components and may not reveal all deficiencies;", "an inspection to verify compliance with any building codes;", "an inspection to verify compliance with manufacturer's installation instructions for any system or component and DOES NOT imply insurability or warrantability of the structure or its components."];

function rating(ratVal, lineIdx, isSelectDefaultInspected, ipv, comments) {
  let ratingParams = [];
  ratVal?.forEach((e) => e?.forEach((val) => val?.data?.forEach((ratingVal) => ratingParams.push(ratingVal))));
  let isInspected = ipv?.find((o) => (o?.data?.length > 0 && o?.type !== "Information") || (o?.type === "Nameplate" && (o?.data?.modelNumber || o?.data?.serialNumber || o?.data?.onlineUrl)));
  return (
    <>
      {ratingParams?.length === 0 && comments?.length === 0 ? (
        <View style={{ flex: 1, flexDirection: "row", gap: "6px", marginTop: lineIdx === 0 ? "3px" : "13px" }}>
          {isInspected ? (
            <View wrap={false} style={{ width: "9.5px", height: "9px", backgroundColor: "black", paddingRight: "1px" }}>
              <CorrectTickIcon />
            </View>
          ) : (
            <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
          )}

          <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
          <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
          <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
        </View>
      ) : ratingParams?.length === 0 && comments?.length > 0 ? (
        <View style={{ flex: 1, flexDirection: "row", gap: "6px", marginTop: lineIdx === 0 ? "3px" : "13px" }}>
          {isSelectDefaultInspected === 1 || isInspected ? (
            <View wrap={false} style={{ width: "9.5px", height: "9px", backgroundColor: "black", paddingRight: "1px" }}>
              <CorrectTickIcon />
            </View>
          ) : (
            <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
          )}

          <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
          <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
          <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
        </View>
      ) : (
        <View style={{ flex: 1, flexDirection: "row", gap: "6px", marginTop: lineIdx === 0 ? "3px" : "13px" }}>
          {ratingParams?.find((e) => e?.Name === "Inspected") || ratingParams?.find((e) => e?.Name === "Deficient") || isSelectDefaultInspected === 1 ? (
            <View wrap={false} style={{ width: "9.5px", height: "9px", backgroundColor: "black", paddingRight: "1px" }}>
              <CorrectTickIcon />
            </View>
          ) : (
            <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
          )}
          {ratingParams?.find((e) => e?.Name === "Not Inspected") ? (
            <View wrap={false} style={{ width: "9.5px", height: "9px", backgroundColor: "black", paddingRight: "1px" }}>
              <CorrectTickIcon />
            </View>
          ) : (
            <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
          )}
          {ratingParams?.find((e) => e?.Name === "Not Present") ? (
            <View wrap={false} style={{ width: "9.5px", height: "9px", backgroundColor: "black", paddingRight: "1px" }}>
              <CorrectTickIcon />
            </View>
          ) : (
            <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
          )}
          {ratingParams?.find((e) => e?.Name === "Deficient") ? (
            <View wrap={false} style={{ width: "9.5px", height: "9px", backgroundColor: "black", paddingRight: "1px" }}>
              <CorrectTickIcon />
            </View>
          ) : (
            <View wrap={false} style={{ width: "9.5px", height: "9px", border: "1px solid #9C9C9C" }}></View>
          )}
        </View>
      )}
    </>
  );
}

export const TexasHomeInspectionReport = ({ data, colorCode, isTexasReport }) => {
  const { report_title, additional_information_by_inspector, inspection_details, addtional_information, cover_photo, company_html_report_setting, inspectiontemplates } = data;
  const InspectorTRECLicense = addtional_information?.filter((value) => value.prompt === "Inspector TREC License #");
  const SponsorName = addtional_information?.filter((value) => value.prompt === "Name of Sponsor (if applicable)");
  const SponsorTRECLicense = addtional_information?.filter((value) => value.prompt === "Sponsor TREC License #");

  return (
    <Document title={`${inspection_details?.address?.replace(/ ,/g, "")}`} subject={Subject} creator={CompanyTag} producer={Producer}>
      {/* Cover Page */}
      <CoverPage company_html_report_setting={company_html_report_setting} isTexasReport={isTexasReport} inspection_details={inspection_details} report_title={report_title} colorCode={colorCode} cover_photo={cover_photo} />
      <Page style={TexasDescriptionStyles.pageDis}>
        <View style={TexasDescriptionStyles.pageContainer}>
          <View style={{ flexDirection: "row", marginTop: "-30px" }}>
            <Image style={{ width: "74px", height: "65px" }} src={TRECLogo}></Image>
            <Text style={TexasDescriptionStyles.propertyHeading}>PROPERTY INSPECTION REPORT FORM</Text>
          </View>
          <View style={{ height: "117px", marginTop: "5px", width: "100%", border: "1px solid #000000", padding: "5px 6px 9px 7px" }}>
            <View style={{ flexDirection: "row", gap: "5px" }}>
              <View style={{ flex: 1 }}>
                {inspection_details?.orderclient?.length >= 1 ? <Text style={TexasDescriptionStyles.detailTitle}>{`${inspection_details?.orderclient[0]?.client?.first_name} ${inspection_details?.orderclient[0]?.client?.last_name} `}</Text> : ""}
                <View style={TexasDescriptionStyles.detailsDivider}></View>
                <Text style={TexasDescriptionStyles.details}>Name of Client</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={TexasDescriptionStyles.detailTitle}>{moment(inspection_details?.start_time)?.format("MM-DD-YYYY")}</Text>
                <View style={TexasDescriptionStyles.detailsDivider}></View>
                <Text style={TexasDescriptionStyles.details}>Date of Inspection</Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", marginTop: "5px" }}>
              <View style={{ flex: 1 }}>
                <Text style={TexasDescriptionStyles.detailTitle}>{`${inspection_details?.address?.replace(/ ,/g, "")}`}</Text>
                <View style={TexasDescriptionStyles.detailsDivider}></View>
                <Text style={TexasDescriptionStyles.details}>Address of Inspected Property</Text>
              </View>
            </View>
            <View style={{ flexDirection: "row", gap: "5px", marginTop: "5px" }}>
              <View style={{ flex: 1 }}>
                {inspection_details?.orderinspector?.length >= 1 ? <Text style={TexasDescriptionStyles.detailTitle}>{`${inspection_details?.orderinspector[0]?.inspector?.first_name} ${inspection_details?.orderinspector[0]?.inspector?.last_name} `}</Text> : ""}
                <View style={TexasDescriptionStyles.detailsDivider}></View>
                <Text style={TexasDescriptionStyles.details}>Name of Inspector</Text>
              </View>
              <View style={{ flex: 1 }}>
                {InspectorTRECLicense
                  ? InspectorTRECLicense?.map((value) => {
                      const { line_input_controls } = value;
                      const dataValue = line_input_controls?.filter((val) => Object?.keys(val)?.includes("data"));
                      return (
                        <View style={{ flex: 1 }}>
                          <Text style={TexasDescriptionStyles.detailTitle}>{dataValue[0]?.data ? `${dataValue[0]?.data?.toString()}` : ""}</Text>
                          <View style={TexasDescriptionStyles.detailsDivider}></View>
                          <Text style={TexasDescriptionStyles.details}>TREC License #</Text>
                        </View>
                      );
                    })
                  : ""}
              </View>
            </View>
            <View style={{ flexDirection: "row", gap: "5px", marginTop: "15px" }}>
              <View style={{ flex: 1 }}>
                <Text style={TexasDescriptionStyles.detailTitle}>{SponsorName ? SponsorName[0]?.line_input_controls[0]?.data : ""}</Text>
                <View style={TexasDescriptionStyles.detailsDivider}></View>
                <Text style={TexasDescriptionStyles.details}>Name of Sponsor (if applicable)</Text>
              </View>
              <View style={{ flex: 1 }}>
                {SponsorTRECLicense
                  ? SponsorTRECLicense?.map((value) => {
                      const { line_input_controls } = value;
                      const dataValue = line_input_controls?.filter((val) => Object?.keys(val)?.includes("data"));
                      return (
                        <>
                          <Text style={TexasDescriptionStyles.detailTitle}>{dataValue[0]?.data ? `${dataValue[0]?.data?.toString()}` : ""}</Text>
                          <View style={TexasDescriptionStyles.detailsDivider}></View>
                          <Text style={TexasDescriptionStyles.details}>TREC License #</Text>
                        </>
                      );
                    })
                  : ""}
              </View>
            </View>
          </View>
        </View>
        <Text style={[TexasDescriptionStyles.subHeading, { marginTop: "5px" }]}>PURPOSE OF INSPECTION</Text>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "1px" }]}>
          A real estate inspection is a visual survey of a structure and a basic performance evaluation of the systems and components of a building. It provides information regarding the general condition of a residence at the time the inspection was conducted. <Text style={TexasDescriptionStyles.italicTimes}>It is important </Text>that you carefully read ALL of this information. Ask the inspector to clarify any items or comments that are unclear.
        </Text>
        <Text style={[TexasDescriptionStyles.subHeading, { marginTop: "7px" }]}>RESPONSIBILITY OF THE INSPECTOR</Text>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "1px" }]}>This inspection is governed by the Texas Real Estate Commission (TREC) Standards of Practice (SOPs), which dictates the minim um requirements for a real estate inspection.</Text>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "7px" }]}>The inspector IS required to: </Text>
        {InspectorBulletPoints.map((value) => {
          return (
            <View style={{ flexDirection: "row", marginTop: "3px" }}>
              <View style={{ marginTop: "6px", marginRight: "5px" }}>
                <BulletDotIcon />
              </View>
              <Text style={TexasDescriptionStyles.bulletText}>{value}</Text>
            </View>
          );
        })}
        <View style={{ flexDirection: "row", marginTop: "3px" }}>
          <View style={{ marginTop: "6px", marginRight: "5px" }}>
            <BulletDotIcon />
          </View>
          <Text style={TexasDescriptionStyles.bulletText}>
            indicate an item as Deficient (D) if a condition exists that adversely and materially affects the performance of a system or component <Text style={{ fontWeight: 700 }}>OR</Text> constitutes a hazard to life, limb or property as specified by the SOPs; and
          </Text>
        </View>
        <View style={{ flexDirection: "row", marginTop: "3px" }}>
          <View style={{ marginTop: "6px", marginRight: "5px" }}>
            <BulletDotIcon />
          </View>
          <Text style={TexasDescriptionStyles.bulletText}>explain the inspector's findings in the corresponding section in the body of the report form.</Text>
        </View>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "7px" }]}>The inspector IS NOT required to:</Text>
        {InpectorNotBulletPoints.map((value) => {
          return (
            <View style={{ flexDirection: "row", marginTop: "3px" }}>
              <View style={{ marginTop: "6px", marginRight: "5px" }}>
                <BulletDotIcon />
              </View>
              <Text style={TexasDescriptionStyles.bulletText}>{value}</Text>
            </View>
          );
        })}
        <Text style={[TexasDescriptionStyles.subHeading, { marginTop: "7px" }]}>RESPONSIBILITY OF THE CLIENT</Text>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "2px" }]}>While items identified as Deficient (D) in an inspection report DO NOT obligate any party to make repairs or take other actions, in the event that any further evaluations are needed, it is the responsibility of the client to obtain further evaluations and/or cost estimates from qualified service professionals regarding any items reported as Deficient (D). It is recommended that any further evaluations and/or cost estimates take place prior to the expiration of any contractual time limitations, such as option periods. </Text>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "2px", display: "flex", flexDirection: "row" }]}>
          <Text style={[TexasDescriptionStyles.bulletText, { fontWeight: 700 }]}>Please Note: </Text>Evaluations performed by service professionals in response to items reported as Deficient (D) on the report may lead to the discovery of additional deficiencies that were not present, visible, or accessible at the time of the inspection. Any repairs made after the date of the inspection may render information contained in this report obsolete or invalid.
        </Text>
        <Text style={[TexasDescriptionStyles.subHeading, { marginTop: "7px" }]}>REPORT LIMITATIONS</Text>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "2px" }]}>This report is provided for the benefit of the named client and is based on observations made by the named inspector on the date the inspection was performed (indicated above).</Text>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "7px" }]}>ONLY those items specifically noted as being inspected on the report were inspected. </Text>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "7px" }]}>This inspection IS NOT:</Text>
        {InspectionNotBulletPoints.map((value) => {
          return (
            <View style={{ flexDirection: "row", marginTop: "1px" }}>
              <View style={{ marginTop: "6px", marginRight: "5px" }}>
                <BulletDotIcon />
              </View>
              <Text style={TexasDescriptionStyles.bulletText}>{value}</Text>
            </View>
          );
        })}
        <TexasFooter showPageNumber={false} />
      </Page>
      <Page style={TexasDescriptionStyles.page}>
        <View style={TexasDescriptionStyles.pageContainer}>
          <Text style={TexasDescriptionStyles.heading}>NOTICE CONCERNING HAZARDOUS CONDITIONS, DEFICIENCIES, AND CONTRACTUAL AGREEMENTS</Text>
          <Text style={TexasDescriptionStyles.content}>Conditions may be present in your home that did not violate building codes or common practices in effect when the home was constructed but are considered hazardous by today's standards. Such conditions that were part of the home prior to the adoption of any current codes prohibiting them may not be required to be updated to meet current code requirements. However, if it can be reasonably determined that they are present at the time of the inspection, the potential for injury or property loss from these conditions is significant enough to require inspectors to report them as Deficient (D). Examples of such hazardous conditions include:</Text>
        </View>
        <View style={{ padding: "10px 15px 10px 15px" }}>
          {BulletPoints.map((value) => {
            return (
              <View style={{ flexDirection: "row", marginTop: "3px" }}>
                <View style={{ marginTop: "6px", marginRight: "5px" }}>
                  <BulletDotIcon />
                </View>
                <Text style={TexasDescriptionStyles.bulletText}>{value}</Text>
              </View>
            );
          })}
        </View>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "5px" }]}>Please Note: items identified as Deficient (D) in an inspection report DO NOT obligate any party to make repairs or take other actions. The decision to correct a hazard or any deficiency identified in an inspection report is left up to the parties to the contract for the sale or purchase of the home.</Text>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "10px" }]}>This property inspection report may include an inspection agreement (contract), addenda, and other information related to property conditions.</Text>
        <Text style={[TexasDescriptionStyles.bulletText, { marginTop: "8px" }]}>{`INFORMATION INCLUDED UNDER “ADDITIONAL INFORMATION PROVIDED BY INSPECTOR”, OR PROVIDED AS \nAN ATTACHMENT WITH THE STANDARD FORM, IS NOT REQUIRED BY THE COMMISSION AND MAY CONTAIN \nCONTRACTUAL TERMS BETWEEN THE INSPECTOR AND YOU, AS THE CLIENT. THE COMMISSION DOES NOT \nREGULATE CONTRACTUAL TERMS BETWEEN PARTIES. IF YOU DO NOT UNDERSTAND THE EFFECT OF ANY \nCONTRACTUAL TERM CONTAINED IN THIS SECTION OR ANY ATTACHMENTS, CONSULT AN ATTORNEY.`}</Text>
        <View style={{ border: "2px solid #000000", width: "100%", marginTop: "9px" }}></View>
        <Text style={TexasDescriptionStyles.additionalInfoHeading}>ADDITIONAL INFORMATION PROVIDED BY INSPECTOR</Text>
        <View style={{ padding: "0px 10px 0px 10px" }}>
          {additional_information_by_inspector ? (
            <>
              <View>
                <View style={{ border: "1px solid #000000", width: "100%", marginTop: "2px" }}></View>
              </View>
              {additional_information_by_inspector?.introductory_text ? (
                <View style={{ fontFamily: "Faustina" }}>
                  <Html stylesheet={stylesheet} style={{ fontSize: "10px" }}>
                    {convertFontTagsToInlineStylesTexasRecommendation(additional_information_by_inspector?.introductory_text)}
                  </Html>
                </View>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </View>
        <View style={{ padding: "0px 10px 0px 10px" }}>
          <View style={{ border: "1px solid #000000", width: "100%", marginTop: "5px" }}></View>
        </View>
        <TexasFooter showPageNumber />
      </Page>
      <Page style={TexasFullReportStyles.page}>
        <View fixed style={{ flexDirection: "row" }}>
          <View>
            <Text fixed style={TexasFullReportStyles.reportIdentification}>
              Report Identification:
            </Text>
          </View>
          <View>
            <Text fixed style={TexasFullReportStyles.reportIdentification}>
              {inspection_details?.address?.replace(/ ,/g, "")}
            </Text>
            <View style={{ border: "1px solid #000000", width: "440px", marginLeft: "5px" }}></View>
          </View>
        </View>
        <View fixed style={{ marginTop: "2px", display: "flex", flexDirection: "row", paddingLeft: "3px" }}>
          <View style={{ flex: 1 }}>
            <Text style={TexasFullReportStyles.ratingHeading}>I= Inspected</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={TexasFullReportStyles.ratingHeading}>NI= Not Inspected</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={TexasFullReportStyles.ratingHeading}>NP= Not Present</Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text style={TexasFullReportStyles.ratingHeading}>D= Deficient</Text>
          </View>
          <View style={{ flex: 1 }}></View>
        </View>
        <View fixed style={TexasFullReportStyles.ratingBlock}>
          <View style={{ marginTop: "2px", flexDirection: "row", gap: "6px" }}>
            <Text>I</Text>
            <Text>NI</Text>
            <Text>NP</Text>
            <Text>D</Text>
          </View>
        </View>
        {inspectiontemplates?.map((tempVal, tempIdx) => {
          return (
            <>
              {tempVal?.categories
                ?.filter((e) => e?.is_inspection_details !== 1 && e?.is_inspection_details !== 2)
                ?.map((catVal, catIdx) => {
                  return (
                    <>
                      <Text style={TexasFullReportStyles.TemplateHeading}>{catVal.prompt}</Text>
                      {catVal?.lines?.filter((e) => e?.is_deleted === 0)?.map((lineVal, lineIdx) => {
                        const lineDes = lineVal?.line_input_controls?.filter((e) => e?.type === "Description");
                        const addInput = lineVal?.line_input_controls?.filter((e) => e?.type === "Additional Input");
                        const location = lineVal?.line_input_controls?.filter((e) => e?.type === "Location");
                        const namePlate = lineVal?.line_input_controls?.filter((e) => e?.type === "Nameplate");
                        const information = lineVal?.line_input_controls?.filter((e) => e?.type === "Information");
                        let allRating = [];
                        return (
                          <View style={{ marginTop: "14px", flexDirection: "row-reverse", marginLeft: "6px" }}>
                            <View style={{ flex: 5 }}>
                              <Text style={[TexasDescriptionStyles.subHeading, { marginTop: lineIdx === 0 ? "0px" : "10px" }]}>
                                {lineVal?.prompt}
                                <Text style={TexasDescriptionStyles.bulletText}>{lineDes[0]?.data ? `  ${lineDes[0]?.data.toString()}` : ""}</Text>
                              </Text>
                              {addInput?.length > 0
                                ? addInput?.map((val) => {
                                    return (
                                      <Text style={TexasDescriptionStyles.italicTimes}>
                                        {`${val?.prompt}:`} <Text style={TexasDescriptionStyles.bulletText}>{val?.data ? `  ${val?.data.toString()}` : ""}</Text>
                                      </Text>
                                    );
                                  })
                                : ""}
                              {lineVal?.line_input_controls
                                ?.filter((e) => e?.type === "Media")
                                ?.map((value) => {
                                  const { data } = value;
                                  return data?.filter((e) => e?.online === "online")?.length > 0 ? (
                                    <>
                                      <Text style={[TexasDescriptionStyles.italicTimes, { marginTop: "2px" }]}>Media:</Text>
                                      <View style={mediaStyles.wrapper}>
                                        {data
                                          ?.filter((e) => e?.online === "online")
                                          ?.map((mediaValue) => {
                                            const { type, onlineUrl, description, thumbnail } = mediaValue;
                                            return type?.includes("image") ? (
                                              <View style={TexasFullReportStyles.div} wrap={false}>
                                                {thumbnail ? <Image style={TexasFullReportStyles.image} src={thumbnail}></Image> : <Image style={TexasFullReportStyles.image} src={onlineUrl}></Image>}
                                                <Text style={mediaStyles.text}>{description}</Text>
                                              </View>
                                            ) : type?.includes("video") ? (
                                              <View style={TexasFullReportStyles.div} wrap={false}>
                                                <Image style={TexasFullReportStyles.image} src={thumbnail}></Image>
                                                <View style={fullReportStyles.videoContainer}>
                                                  <View wrap={false} style={fullReportStyles.videoInnerContainer}>
                                                    <View style={fullReportStyles.videoIconContainer}>
                                                      <View style={fullReportStyles.videoIconInnerContainer}>
                                                        <View style={[fullReportStyles.videoIcon, { marginTop: "2px" }]}>
                                                          <VideoIcon />
                                                        </View>
                                                      </View>
                                                      <View style={fullReportStyles.videoTitleContainer}>
                                                        <Text style={TexasDescriptionStyles.bulletText}>Video</Text>
                                                      </View>
                                                    </View>
                                                  </View>
                                                </View>
                                                <Text style={mediaStyles.text}>{description}</Text>
                                              </View>
                                            ) : (
                                              ""
                                            );
                                          })}
                                      </View>
                                    </>
                                  ) : (
                                    ""
                                  );
                                })}
                              {namePlate.length > 0 || namePlate[0]?.data?.modelNumber || namePlate[0]?.data?.serialNumber || namePlate[0]?.data?.onlineUrl ? (
                                <>
                                  <Text style={TexasDescriptionStyles.italicTimes}>Nameplate:</Text>
                                  {namePlate[0]?.data?.modelNumber ? (
                                    <Text style={TexasDescriptionStyles.italicTimes}>
                                      MODEL NUMBER: <Text style={TexasDescriptionStyles.bulletText}>{`  ${namePlate[0]?.data?.modelNumber}`}</Text>
                                    </Text>
                                  ) : (
                                    ""
                                  )}
                                  {namePlate[0]?.data?.serialNumber ? (
                                    <Text style={TexasDescriptionStyles.italicTimes}>
                                      SERIAL NUMBER: <Text style={TexasDescriptionStyles.bulletText}>{`  ${namePlate[0]?.data?.serialNumber}`}</Text>
                                    </Text>
                                  ) : (
                                    ""
                                  )}
                                  {namePlate[0]?.data?.onlineUrl ? (
                                    <View wrap={false} style={TexasFullReportStyles.div}>
                                      {namePlate[0]?.data?.thumbnail ? <Image style={TexasFullReportStyles.image} src={namePlate[0]?.data?.thumbnail}></Image> : <Image style={TexasFullReportStyles.image} src={namePlate[0]?.data?.onlineUrl}></Image>}
                                      <Text style={mediaStyles.text}>{namePlate[0]?.data?.description}</Text>
                                    </View>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {location?.length > 0 && location[0]?.data ? (
                                <Text style={TexasDescriptionStyles.italicTimes}>
                                  Location: <Text style={TexasDescriptionStyles.bulletText}>{`  ${location[0]?.data}`}</Text>
                                </Text>
                              ) : (
                                ""
                              )}
                              {information[0]?.data ? (
                                <>
                                  {typeof information[0]?.data === "string" ? (
                                    <>
                                      {
                                        <>
                                          <View style={fullReportStyles.lineInputContainer}>
                                            {data ? (
                                              <View style={{ flex: 1, flexDirection: "row", paddingTop: "5px", paddingRight: "35px", fontFamily: "Faustina" }}>
                                                <Text style={TexasDescriptionStyles.italicTimes}>{information[0].type}: </Text>
                                                <View style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                                                  <Html stylesheet={stylesheet} style={{ fontSize: "10px" }}>
                                                    {convertFontTagsToInlineStylesTexas(information[0]?.data)}
                                                  </Html>
                                                </View>
                                              </View>
                                            ) : (
                                              ""
                                            )}
                                          </View>
                                        </>
                                      }
                                    </>
                                  ) : (
                                    <>
                                      {information?.map((value, index) => {
                                        const { data, type } = value;
                                        return (
                                          <>
                                            <View style={[fullReportStyles.lineInputContainer]}>
                                              {data ? (
                                                <View style={{ flex: 1, flexDirection: "row", paddingTop: "5px", paddingRight: "35px", fontFamily: "Faustina" }}>
                                                  <Text style={TexasDescriptionStyles.italicTimes}>{type}: </Text>
                                                  <View style={{ paddingRight: "10px", paddingLeft: "10px" }}>
                                                    <Html stylesheet={stylesheet} style={{ fontSize: "10px" }}>
                                                      {convertFontTagsToInlineStylesTexas(data[index])}
                                                    </Html>
                                                  </View>
                                                </View>
                                              ) : (
                                                ""
                                              )}
                                            </View>
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              <Text style={[TexasDescriptionStyles.italicTimes, { marginTop: "2px" }]}>Comments:</Text>
                              {lineVal?.comments?.map((comVal, comIdx) => {
                                const location = comVal?.comment_input_controls?.filter((e) => e?.type === "Location");
                                const information = comVal?.recommendation;
                                const commentRating = comVal?.comment_input_controls?.filter((e) => e?.type === "Rating");
                                allRating.push(commentRating);
                                isSelectDefaultInspected = comVal?.is_select_default_inspected;
                                return (
                                  <>
                                    <View style={{ display: "flex", fontFamily: "Faustina", marginTop: 0, padding: "2px", gap: "5px", flexDirection: "row", alignItems: "flex-start" }}>
                                      <View style={{ flex: 1 }}>
                                        <View style={{ flexDirection: "row" }}>
                                          {comVal?.prompt || comVal?.recommendation ? (
                                            <View style={{ display: "flex", paddingTop: "5px", alignItems: "center", justifyContent: "top", width: "20px" }}>
                                              <BulletDotIcon />
                                            </View>
                                          ) : (
                                            ""
                                          )}
                                          <View style={{ flex: 1 }}>
                                            {comVal?.is_mannual_prompt === 0 || comVal?.prompt === "null" || comVal?.prompt === null || comVal?.prompt === "" ? (
                                              comVal?.recommendation ? (
                                                <Html resetStyles style={{ fontSize: "10px" }}>
                                                  {convertFontTagsToInlineStylesTexas(comVal?.recommendation)}
                                                </Html>
                                              ) : (
                                                ""
                                              )
                                            ) : comVal?.is_mannual_prompt === 1 && comVal?.prompt ? (
                                              <Text style={TexasFullReportStyles.commentPrompt}>{comVal.prompt}</Text>
                                            ) : comVal?.prompt ? (
                                              <Text style={TexasFullReportStyles.commentPrompt}>{comVal.prompt}</Text>
                                            ) : (
                                              ""
                                            )}
                                            {comVal?.is_mannual_prompt === 0 || comVal?.prompt === "null" || comVal?.prompt === null || comVal?.prompt === "" ? (
                                              ""
                                            ) : comVal?.recommendation ? (
                                              <View style={{ flexDirection: "row" }}>
                                                <Text style={TexasDescriptionStyles.italicTimes}>Information: </Text>
                                                <View style={{ flex: 1 }}>
                                                  <Html stylesheet={stylesheet} style={{ fontSize: "10px" }}>
                                                    {convertFontTagsToInlineStylesTexas(information)}
                                                  </Html>
                                                </View>
                                              </View>
                                            ) : (
                                              ""
                                            )}
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                    {location.length > 0 && location[0]?.data?.length > 0 ? (
                                      <Text style={TexasDescriptionStyles.italicTimes}>
                                        Location: <Text style={TexasDescriptionStyles.bulletText}>{`  ${location[0]?.data}`}</Text>
                                      </Text>
                                    ) : (
                                      ""
                                    )}
                                    {comVal?.comment_input_controls
                                      ?.filter((e) => e?.type === "Media")
                                      ?.map((value) => {
                                        const { data } = value;
                                        return data?.filter((e) => e?.online === "online")?.length > 0 ? (
                                          <>
                                            <Text style={[TexasDescriptionStyles.italicTimes, { marginTop: "2px" }]}>Media:</Text>
                                            <View style={mediaStyles.wrapper}>
                                              {data
                                                ?.filter((e) => e?.online === "online")
                                                ?.map((mediaValue) => {
                                                  const { type, onlineUrl, description, thumbnail } = mediaValue;
                                                  return type?.includes("image") ? (
                                                    <View wrap={false} style={TexasFullReportStyles.div}>
                                                      {thumbnail ? <Image style={TexasFullReportStyles.image} src={thumbnail}></Image> : <Image style={TexasFullReportStyles.image} src={onlineUrl}></Image>}
                                                      <Text style={mediaStyles.text}>{description}</Text>
                                                    </View>
                                                  ) : type?.includes("video") ? (
                                                    <View style={TexasFullReportStyles.div}>
                                                      <Image style={TexasFullReportStyles.image} src={thumbnail}></Image>
                                                      <View style={fullReportStyles.videoContainer}>
                                                        <View wrap={false} style={fullReportStyles.videoInnerContainer}>
                                                          <View style={fullReportStyles.videoIconContainer}>
                                                            <View style={fullReportStyles.videoIconInnerContainer}>
                                                              <View style={[fullReportStyles.videoIcon, { marginTop: "2px" }]}>
                                                                <VideoIcon />
                                                              </View>
                                                            </View>
                                                            <View style={fullReportStyles.videoTitleContainer}>
                                                              <Text style={TexasDescriptionStyles.bulletText}>Video</Text>
                                                            </View>
                                                          </View>
                                                        </View>
                                                      </View>
                                                      <Text style={mediaStyles.text}>{description}</Text>
                                                    </View>
                                                  ) : (
                                                    ""
                                                  );
                                                })}
                                            </View>
                                          </>
                                        ) : (
                                          ""
                                        );
                                      })}
                                  </>
                                );
                              })}
                            </View>
                            {rating(allRating, lineIdx, isSelectDefaultInspected, lineVal?.line_input_controls, lineVal?.comments)}
                            {(isSelectDefaultInspected = undefined)}
                          </View>
                        );
                      })}
                    </>
                  );
                })}
            </>
          );
        })}
        <TexasFooter showPageNumber />
      </Page>
    </Document>
  );
};
