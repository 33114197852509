const DeliveryFooter = () => {
    return (
        <div className="delivery-footer-container">
            <p className="powered-by">Powered by</p>
            <div style={{ display: 'inline', height: '19px', marginLeft: '5px' }}>
                <svg width="36" style={{ marginRight: '8px', marginLeft: '8px' }} height="19" viewBox="0 0 36 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7479 0L0 10.4747L6.16085 14.1152L17.7479 7.27189L23.1742 10.4747L21.056 11.7281L17.7479 9.76959L11.5871 13.4101L21.056 19L35.4914 10.4747L17.7479 0Z" fill="#16B58F" />
                </svg>
                <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.17005 0.622237C7.88791 0.59934 8.60272 0.727408 9.2702 0.998507C9.93768 1.26961 10.5436 1.67796 11.0504 2.19827C11.5571 2.7242 11.9538 3.35003 12.2165 4.03768C12.4791 4.72534 12.6022 5.46044 12.5783 6.19827C12.6017 6.93604 12.4784 7.67099 12.2158 8.35857C11.9531 9.04615 11.5567 9.67203 11.0504 10.1983C10.5434 10.718 9.93731 11.1256 9.26981 11.3959C8.60231 11.6663 7.88762 11.7935 7.17005 11.7697H4.2406V16.0508H0.747803V0.622237H7.17005ZM6.62923 8.86187C6.95624 8.87473 7.28205 8.8146 7.58403 8.68568C7.88601 8.55676 8.15691 8.36213 8.37789 8.11533C8.84065 7.59157 9.08487 6.9036 9.05842 6.19827C9.08584 5.49279 8.84149 4.80446 8.37789 4.28122C8.15882 4.03539 7.8903 3.84097 7.59079 3.71131C7.29127 3.58164 6.96785 3.51982 6.64275 3.53007H4.25412V8.86187H6.62923Z"
                        fill="#1F2120"
                    />
                </svg>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.293 11.3318C4.62071 11.3633 3.95014 11.2391 3.33132 10.9686C2.71249 10.6981 2.16138 10.2883 1.71908 9.76963C0.81012 8.63691 0.341564 7.20125 0.403076 5.73737C0.347724 4.27513 0.830102 2.84473 1.75513 1.72816C2.19699 1.20594 2.74798 0.791941 3.36736 0.516753C3.98675 0.241565 4.65871 0.112225 5.33356 0.138295C6.0148 0.118318 6.68971 0.276962 7.29404 0.599125C7.84347 0.899694 8.29355 1.3599 8.5875 1.92171V0.400969H11.864V11.0507H8.5875V9.54843C8.27643 10.0999 7.81636 10.5479 7.26249 10.8388C6.6567 11.1691 5.97951 11.3386 5.293 11.3318ZM6.24394 8.53461C6.5879 8.55429 6.93169 8.49308 7.2489 8.35567C7.5661 8.21827 7.84827 8.00832 8.07372 7.74198C8.51867 7.17527 8.74906 6.46362 8.72271 5.73737C8.74712 5.01704 8.5167 4.31186 8.07372 3.7512C7.84827 3.48486 7.5661 3.27491 7.2489 3.13751C6.93169 3.0001 6.5879 2.93889 6.24394 2.95857C5.90635 2.9448 5.56992 3.007 5.25841 3.14078C4.94691 3.27455 4.66794 3.47664 4.44121 3.73277C3.97475 4.28875 3.73301 5.00558 3.76518 5.73737C3.73566 6.47157 3.97694 7.19031 4.44121 7.7512C4.66621 8.00889 4.94401 8.21269 5.25482 8.3481C5.56563 8.4835 5.90182 8.54718 6.23944 8.53461H6.24394Z"
                        fill="#1F2120"
                    />
                </svg>
                <svg width="4" style={{ marginLeft: '2px', marginRight: '2px' }} height="17" viewBox="0 0 4 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.599609 16.0506V0.62207H3.91215V16.0506H0.599609Z" fill="#1F2120" />
                </svg>
                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0.616211 11.0506V0.423847H3.91522V1.96763C4.1499 1.41804 4.54501 0.956086 5.04644 0.645045C5.60058 0.298747 6.24046 0.122777 6.88973 0.138133C8.52421 0.138133 9.60886 0.792511 10.1437 2.10127C10.4303 1.50961 10.8778 1.01515 11.4326 0.677303C12.0401 0.312908 12.7339 0.126382 13.4382 0.138133C15.8929 0.138133 17.1218 1.57746 17.1248 4.45611V11.0506H13.8213V5.05979C13.8213 3.6773 13.3075 2.96763 12.2709 2.96763C11.1081 2.96763 10.5268 3.83399 10.5268 5.5667V11.0506H7.20521V5.05979C7.20521 3.6773 6.69594 2.98606 5.67739 2.98606C5.42836 2.96988 5.17914 3.01451 4.95031 3.11628C4.72148 3.21804 4.51962 3.37402 4.36139 3.57131C4.05944 3.95841 3.91071 4.63122 3.91071 5.58514V11.0506H0.616211Z"
                        fill="#1F2120"
                    />
                </svg>
                <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.02975 11.4241C7.4108 11.428 7.79168 11.4064 8.16998 11.3596V14.0508C7.44354 14.1769 6.70743 14.2355 5.97065 14.2259C5.63902 14.2303 5.30755 14.2087 4.97914 14.1614C4.64549 14.102 4.32012 14.0014 4.01017 13.8619C3.66618 13.7274 3.35757 13.5128 3.1088 13.2351C2.83933 12.8983 2.63859 12.5097 2.5184 12.0923C2.34224 11.5217 2.25851 10.9255 2.27053 10.3273V6.09228H0.643555V3.42408H2.28405V0.276611H5.60108V3.42408H7.95366V6.09228H5.60108V9.61762C5.60108 10.3365 5.72277 10.8204 5.97065 11.06C6.26351 11.3184 6.64373 11.4491 7.02975 11.4241Z"
                        fill="#1F2120"
                    />
                </svg>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.2801 5.18433C11.2779 5.67865 11.2493 6.17247 11.1944 6.6636H3.5328C3.52678 6.96793 3.58438 7.27007 3.70174 7.54981C3.8191 7.82955 3.99352 8.08044 4.21333 8.28572C4.70651 8.68845 5.32724 8.89182 5.95748 8.85715C6.96401 8.85715 7.61149 8.52688 7.89993 7.86636H11.1313C11.0474 8.38818 10.8546 8.88543 10.5661 9.32434C10.2776 9.76326 9.90012 10.1336 9.4593 10.4101C8.41531 11.0904 7.19478 11.4325 5.95748 11.3917C4.1908 11.3917 2.79819 10.8925 1.77964 9.89401C0.761098 8.89555 0.250319 7.51306 0.247314 5.74655C0.247314 4.01383 0.758094 2.64363 1.77964 1.63595C2.89576 0.677373 4.30202 0.14349 5.7595 0.125008C7.21698 0.106527 8.63575 0.604588 9.77477 1.53456C10.2684 1.99581 10.6585 2.56084 10.9184 3.19086C11.1782 3.82088 11.3016 4.50094 11.2801 5.18433ZM3.52379 4.56682H8.03063C8.05571 3.96578 7.84663 3.37911 7.44925 2.93549C7.23115 2.73507 6.97605 2.58127 6.69897 2.48313C6.42188 2.385 6.12841 2.34451 5.8358 2.36406C5.53158 2.34708 5.22709 2.39211 4.94004 2.49654C4.653 2.60097 4.38912 2.76271 4.16376 2.97235C3.95647 3.17952 3.79278 3.42782 3.68273 3.70201C3.57268 3.9762 3.51859 4.27049 3.52379 4.56682Z"
                        fill="#1F2120"
                    />
                </svg>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.26563 11.378C5.52173 11.4153 4.77821 11.2968 4.08072 11.0297C3.38322 10.7627 2.74645 10.3527 2.20947 9.82497C1.70078 9.28328 1.30474 8.64181 1.04524 7.93926C0.78575 7.23672 0.668194 6.48769 0.699679 5.73741C0.667852 4.98847 0.785273 4.24074 1.04482 3.53964C1.30436 2.83855 1.70061 2.19873 2.20947 1.65907C2.74766 1.13366 3.38484 0.725822 4.08219 0.460409C4.77954 0.194997 5.52245 0.0775709 6.26563 0.115289C7.6099 0.0535886 8.93397 0.466911 10.0153 1.2858C10.4874 1.66935 10.876 2.14947 11.1561 2.69543C11.4363 3.24138 11.6019 3.84114 11.6423 4.4563H8.34779C8.09991 3.46091 7.41036 2.95861 6.27464 2.95861C5.9617 2.94242 5.64922 2.99865 5.36049 3.12313C5.07177 3.2476 4.81425 3.4371 4.60711 3.6775C4.17116 4.26455 3.95674 4.99261 4.00319 5.72819C3.95662 6.46237 4.17111 7.18902 4.60711 7.77427C4.8137 8.01841 5.07219 8.21096 5.36279 8.33717C5.65339 8.46338 5.96838 8.51989 6.28365 8.50239C7.41937 8.50239 8.11042 8.03234 8.35679 7.09225H11.6423C11.5979 7.70027 11.4293 8.29204 11.1476 8.82939C10.8658 9.36674 10.477 9.83775 10.0063 10.2121C8.92465 11.0229 7.60571 11.434 6.26563 11.378Z"
                        fill="#1F2120"
                    />
                </svg>
                <svg width="11" style={{ marginLeft: '1px' }} height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.20532 5.1382C9.73516 5.1382 11.0001 6.5852 11.0001 9.47921V16.0506H7.68305V10.5806C7.73581 9.89182 7.58843 9.20246 7.2594 8.59903C7.09441 8.38385 6.87917 8.21453 6.63347 8.10663C6.38777 7.99873 6.11948 7.95571 5.85327 7.98152C4.51925 7.98152 3.85223 8.81869 3.85223 10.493V16.0506H0.553223V0.62207H3.85223V6.96769C4.46516 5.7511 5.58286 5.1382 7.20532 5.1382Z" fill="#1F2120" />
                </svg>
            </div>
        </div>
    );
};
export default DeliveryFooter;
