import { Button, Grid } from "@material-ui/core";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { useState,useEffect } from "react";
import { CommunicationIcon,TickIcon,AppliancesIcon,AlertIcon, PorchIcon } from "../../../../assets/svg/Svg";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import AppImage from '../../../../assets/images/porch_app.png'
import playstore from '../../../../assets/images/playstore.png'
import appstore from '../../../../assets/images/appstore.png'
import { origin } from "../../../helpers/HelperFunctions";



var QRCode = require("qrcode");


const DeliveryPorchApp = (props) => {
    const { appLinks,details } = props;
    const [qrcode,setQrCode] = useState()
    const [open, setOpen] = useState(false);

    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return null; // Return null for invalid input
      }
    
    let BASE_URL = origin();
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const generateQrCode =()=>{
        QRCode.toDataURL(`${BASE_URL}/generate-porch-mobile-app-link?guid=${sessionStorage.getItem("guid")}`, (error, dataUrl) => {
            setQrCode(dataUrl)})
    }
    useEffect(()=>{
        origin();
        generateQrCode();        
    },[])
    return (
    <>
    <Grid container className="delivery__grid app_grid">
            <Grid item xs={2} sm={1} md={1} > <div className="oval"><PhoneIphoneIcon/></div></Grid>
            <Grid item xs={9} sm={6} md={6} className="pl-20"> 
                <div className="pa_title"><span>View your inspection report in the Porch app</span></div>
                <div className="pa_subtitle">
                    <span>EXCLUSIVELY FOR HOME INSPECTIONS</span>
                </div>
                <div className="pa_info_text">
                    <span>Download your enhanced inspection report with the Porch app and get valuable insights into your report findings, create a handy to-do list, and manage your appliances.</span>
                </div>
                <div>
                <Button className="get_app" variant="none" onClick={handleClickOpen}>
                    GET THE APP
                </Button>
                </div>
                <Grid container className="qr_container">
                    <Grid item xs={12}>
                        <div className="qr__section">
                            <span>Or scan this QR code:</span>
                            <div className="qr__code"> <img src={qrcode} alt="qr-img"/></div>
                        </div>
                        <div>
                       
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1} sm={5} md={5} style={{position:"relative"}} >
                <div className="app_features">                    
                    <span>Save time, money, and know your home better with these extra app features:</span>
                </div>
                <div className="feature_list_wrapper">
                    <div className="f_alert f_flex"><AlertIcon/> <span>Get immediate appliance recall alerts</span></div>
                    <div className="f_apliance f_flex"><AppliancesIcon/><span>Manage and track appliances</span></div>
                    <div className="f_tick f_flex"><TickIcon/><span>Create to-do lists based on your inspection report</span></div>
                    <div className="f_communication f_flex"><CommunicationIcon/><span>Get free help with your move with Porch Concierge</span></div> 
                </div>
                <div className="f_powered_by">
                    <span>Powered by <PorchIcon/></span>
                </div>
                
            </Grid>
        </Grid>
        {/* modal */}        
      <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="rdp_app_modal"
    >
      <DialogTitle id="alert-dialog-title" className="rdp_app_modal_title">
        <span>How to use the app </span>
        <CloseIcon onClick={handleClose}/>
      </DialogTitle>
      <DialogContent className="rdp_app_modal_content">
        <Grid container>
            <Grid item xs={9} className="rdp_app_left_panel">
                <Grid container className="rdp_app_left_inner">
                    <Grid item xs={1}>
                    <span className="bg">1</span>
                    </Grid>
                    <Grid item xs={11} className="mt-8">
                    <span className="inner_text">Go to the app store, search for Porch, and download the app.</span>
                    <br/>
                    <div className="store_images">
                    <a href={appLinks?.porch_ios_app_link}><img src={appstore} alt="appstore"/></a>
                    <a href={appLinks?.porch_android_app_link}><img src={playstore} alt="playstore"/></a>
                    </div>
                    </Grid>   
                </Grid>
                <Grid container className="rdp_app_left_inner">
                    <Grid item xs={1}>
                    <span className="bg">2</span>
                    </Grid>
                    <Grid item xs={11} className="mt-8">
                    <span className="inner_text">When prompted, use your email  ({details?.orderclient?details?.orderclient[0]?.client?.email:''}) to access your specific report. If you use a different email than the one your inspector has on file, you will not have access to the report in the app.</span>
                    <br/>                   
                    </Grid>   
                </Grid>
                <Grid container className="rdp_app_left_inner">
                    <Grid item xs={1}>
                    <span className="bg">3</span>
                    </Grid>
                    <Grid item xs={11} className="mt-8">
                    <span className="inner_text">Have any questions or need help accessing the report? We can help.<br/><br/>
                    <span className="light_text">Call:</span> {formatPhoneNumber(details?.company?.phone)}<br/>
                    {details?.company?.email!== null && details?.company?.email!==""?<><span className="light_text">Email: </span><u>{details?.company?.email}</u></>:''}
                    </span>
                    <br/>                   
                    </Grid>   
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <img src={AppImage} alt="qr-img"/>
            </Grid>
        </Grid>
      </DialogContent>     
    </Dialog>
        </>
        )
  };
  export default DeliveryPorchApp;
  