import { Button, Divider, Paper } from "@material-ui/core"
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LaunchIcon from '@mui/icons-material/Launch';

const DeliveryInspAgntMobilev1 = ({details}) => {
  function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null; // Return null for invalid input
  }

  return (
    <Paper
          className="mobile_app_section-paper "
          style={{ margin: "35px 8px",padding:'26px 17px' }}
        >
           {details?.orderinspector?.map((insp,index)=>(
            <div className="ins_agn_section" style={{marginBottom:'5px'}} key={index}>
                <p className="ins_agn_title">Your Inspector</p>                
                <p className="ins_agn_name"> {insp?.inspector?.first_name} {insp?.inspector?.last_name}</p>
                <div className="ins_agn_flex">
                    <div className="contact_icon"><CallIcon/><span><a href={`tel:${insp?.inspector?.phone}`}>{formatPhoneNumber(insp?.inspector?.phone)}</a></span></div>
                    <div className="contact_icon"><MailIcon/><span><a href={`mailto:${insp?.inspector?.email}`}>{insp?.inspector?.email}</a></span></div>
                    {details?.company?.website !=='' && details?.company?.website !== null?<div className="contact_icon"><LaunchIcon/><span><a href={`mailto:${details?.company?.website}`}>{details?.company?.website}</a></span></div>:''}
                </div>
               
                {index === (details?.orderinspector.length -1 ) && <a href={`mailto:${insp?.inspector?.email}`}>
                  <Button className="get_app_mobile_btn" variant="none">
                send a message
                </Button>
                  </a>
                }
                <Divider style={{background: '#3B3B3B',width:'95%',margin:"29px auto 35px auto"}}/>
            </div>
           ))}
            
            {details?.orderagent?.map((agnt,agntindex)=>(
              <div className="ins_agn_section" style={{marginBottom:'10px'}}>
              <p className="ins_agn_title">Your agent</p>                
              <p className="ins_agn_name">{agnt?.agent?.first_name} {agnt?.agent?.last_name}</p>
              <div className="ins_agn_flex">
                  <div className="contact_icon"><CallIcon/><span><a href={`tel:${agnt?.agent?.phone}`}>{formatPhoneNumber(agnt?.agent?.phone)}</a></span></div>
                  <div className="contact_icon"><MailIcon/><span><a href={`mailto:${agnt?.agent?.email}`}>{agnt?.agent?.email}</a> </span></div>
              </div>
              {agntindex !== details?.orderagent.length-1 && <Divider style={{background: '#3B3B3B',width:'95%',margin:"29px auto 35px auto"}}/>} 
              </div>
            
            ))}
           
            
    </Paper>
  )
}

export default DeliveryInspAgntMobilev1