import CollapsibleAgentDetails from "../../common/CollapsibleDetails/CollapsibleAgentDetails";
import CollapsibleClientDetails from "../../common/CollapsibleDetails/CollapsibleClientDetails";

const ClientDetailsLayout = (props) => {
  const { clientDetails, agentDetails } = props;
  const agentLength = agentDetails?.length;
  const clientLength = clientDetails?.length;
  return (
    <>
      {clientDetails?.map((value, index) => {
        return <CollapsibleClientDetails data={value} index={index} length={clientLength} />;
      })}
      {agentDetails?.map((value, index) => {
        return <CollapsibleAgentDetails data={value} index={index} length={agentLength} />;
      })}
    </>
  );
};

export default ClientDetailsLayout;
