import React, { useState, useEffect } from "react";

/**
 * Displays a sequence of animated dots.
 * The dots are updated every 500 milliseconds.
 */
const AnimatedDots = () => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots : ""));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={{ display: "inline" }}>
      <p style={{ display: "inline" }}>{dots}</p>
    </div>
  );
};

export default AnimatedDots;
