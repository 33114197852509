import Html from "react-pdf-html";
import { Header } from "../Header";
import { Footer } from "../GeneratePdf";
import { contentStyles } from "../PDFStyles";
import { VideoIcon } from "../../../../assets/svg/Svg";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import { convertFontTagsToInlineStyles, stylesheet } from "../HtmlConverter";
import { NarrativeMediaStyles, NarrativePDFStyles, fullReportStyles } from "../PDFStyles";

export const NarrativeFullReportPDF = ({
    isISN,
    isNarrative,
    inspection_details,
    inspectiontemplates,
    company_html_report_setting,
}) => {
    let PhotoCount = 0;
    let VideoCount = 0;
    const addCaptionForMedia = (type, caption) => {
        if (type?.includes("video")) {
            VideoCount++;
            return <Text style={[NarrativeMediaStyles.text, { textAlign: caption?.length > 50 ? "justify" : "center" }]}>{`Video ${VideoCount}${caption ? ": " + caption : ""}`}</Text>
        } else if (type?.includes("image")) {
            PhotoCount++;
            return <Text style={[NarrativeMediaStyles.text, { textAlign: caption?.length > 50 ? "justify" : "center" }]}>{`Photo ${PhotoCount}${caption ? ": " + caption : ""}`}</Text>
        }
        return null; // Return null if the type is neither video nor image
    };
    return (
        <Page style={contentStyles.body}>
            <Header isNarrative={isNarrative} isISN={isISN} address={inspection_details?.address} clientDetails={inspection_details?.orderclient} companySettings={company_html_report_setting} />
            <View style={NarrativePDFStyles.pageContainer}>
                {inspectiontemplates !== undefined &&
                    inspectiontemplates
                        ?.filter((item) => item?.is_narrative_template === 1)?.filter((e) => e?.is_deleted !== 1)?.map((templateValue) => {
                            return <>
                                {templateValue?.categories?.filter((e) => e?.is_deleted !== 1 && e?.is_inspection_details === 0)?.map((catValue, catIndex) => {
                                    return <>
                                        {catValue?.lines?.filter((e) => e?.is_deleted !== 1)?.length > 0 && catValue?.introductory_text !== null && catValue?.lines?.some((line) => line?.comments?.filter((e) => e?.is_deleted !== 1)?.length > 0) ? <Text style={[NarrativePDFStyles.prefaceHeading, { textTransform: "uppercase" }]}>{`${catIndex + 1}. ${catValue?.prompt !== null ? catValue?.prompt : ""}`}</Text> : ""}
                                        {catValue?.introductory_text  && catValue?.lines?.some((line) => line?.comments?.filter((e) => e?.is_deleted !== 1)?.length > 0) ? <View style={[fullReportStyles.htmlContainer, { paddingRight: "15px" }]}>
                                            <Html stylesheet={stylesheet} style={{ fontSize: "10px", paddingRight: "15px" }}>
                                                {convertFontTagsToInlineStyles(catValue?.introductory_text?.replace(/background:\s*none;\s*/g, ''))}
                                            </Html>
                                        </View> : ""}
                                        {catValue?.lines?.filter((e) => e?.is_deleted !== 1)?.map((lineVal) => {
                                            return <>
                                                {lineVal?.comments?.filter((e) => e?.is_deleted !== 1)?.map((commentValue) => {
                                                    return <>
                                                        {commentValue.comment_text && <View style={[fullReportStyles.htmlContainer, { paddingRight: "15px" }]}>
                                                            <Html stylesheet={stylesheet} style={{ fontSize: "10px", paddingRight: "15px" }}>
                                                                {convertFontTagsToInlineStyles(commentValue?.comment_text?.replace(/background:\s*none;\s*/g, ''))}
                                                            </Html>
                                                        </View>}
                                                        {commentValue?.comment_input_controls?.filter((e) => e?.type === 'Media')?.filter((e) => e?.is_deleted !== 1)?.map((mediaValue) => {
                                                            return <View style={NarrativeMediaStyles.wrapper}>
                                                                {mediaValue?.data?.filter((e) => e?.online === "online")
                                                                    ?.map((mediaValue, mediaIndex) => {
                                                                        const { type, onlineUrl, description, thumbnail } = mediaValue;
                                                                        return type?.includes("image") ? (
                                                                            <View style={NarrativeMediaStyles.div}>
                                                                                {thumbnail ? <Image wrap={false} style={NarrativeMediaStyles.image} src={thumbnail}></Image> : <Image wrap={false} style={NarrativeMediaStyles.image} src={onlineUrl}></Image>}
                                                                                {addCaptionForMedia(mediaValue?.type, mediaValue?.description)}
                                                                            </View>
                                                                        ) : type?.includes("video") ? (
                                                                            <View style={NarrativeMediaStyles.div}>
                                                                                <View style={NarrativeMediaStyles.innerDiv}>
                                                                                    <Image style={NarrativeMediaStyles.image} src={thumbnail}></Image>
                                                                                    <View style={fullReportStyles.videoContainer}>
                                                                                        <View wrap={false} style={fullReportStyles.videoInnerContainer}>
                                                                                            <View style={fullReportStyles.videoIconContainer}>
                                                                                                <View style={fullReportStyles.videoIconInnerContainer}>
                                                                                                    <View style={fullReportStyles.videoIcon}>
                                                                                                        <VideoIcon />
                                                                                                    </View>
                                                                                                </View>
                                                                                                <View style={fullReportStyles.videoTitleContainer}>
                                                                                                    <Text style={fullReportStyles.videoTitle}>Video</Text>
                                                                                                </View>
                                                                                            </View>
                                                                                        </View>
                                                                                    </View>
                                                                                </View>
                                                                                {addCaptionForMedia(mediaValue?.type, mediaValue?.description)}
                                                                            </View>
                                                                        ) : (
                                                                            ""
                                                                        );
                                                                    })}
                                                            </View>
                                                        })}
                                                    </>
                                                })}
                                            </>
                                        })}
                                    </>
                                })}
                            </>
                        })}
            </View>
            <Footer isISN={isISN} />
        </Page>
    )
}