import moment from "moment/moment";
import { ArrowIcon } from "../../../assets/svg/Svg";
import { Page, Text, Image, View } from "@react-pdf/renderer";
import { styles, coverPageStyles, commonStyles, inspectionDetailsStyles } from "./PDFStyles";
//QR code file import
var QRCode = require("qrcode");
const DateFormat = "MMM Do, YYYY";
//Cover Page
let clientNameLength = 0;
export const CoverPage = ({ company_html_report_setting, inspection_details, report_title, color, cover_photo, isTexasReport, isISN }) => (
  <Page size={"A4"} wrap={false} style={styles.coverBody}>
    <View style={commonStyles.flexWrapper}>
      <View style={commonStyles.flexContainer}>{company_html_report_setting ? company_html_report_setting?.use_company_name_as_logo === 1 ? <Text style={inspectionDetailsStyles.title}>{company_html_report_setting?.company_name}</Text> : company_html_report_setting?.company_logo_url ? <Image style={styles.companyLogo} src={company_html_report_setting?.company_logo_url}></Image> : "" : ""}</View>
      <View style={[commonStyles.flexWrapper, coverPageStyles.qrContainer]}>
        <Text style={[styles.date, coverPageStyles.qrTitle]}>Scan or click QR code to view HTML report </Text>
        <ArrowIcon />
      </View>
      <View style={coverPageStyles.qrWrapper}>
        <Image
          style={coverPageStyles.qrImage}
          src={QRCode?.toDataURL(`${window.location.origin}/?guid=${sessionStorage.getItem("guid")}`, { type: "png" })?.then((img) => {
            return img;
          })}
        ></Image>
      </View>
    </View>
    <Text style={styles.date}>{isISN ? moment(inspection_details?.start_time).format(DateFormat) : moment(moment.utc(inspection_details?.start_time).local()).format(DateFormat)}</Text>
    {/* <Text style={styles.title}>{inspection_details?.address?.replace(/ ,/g, "")}</Text> */}
    <Text style={styles.title}>{inspection_details?.address}</Text>
    <Text style={styles.pageTitle}>{report_title ? report_title : isTexasReport ? "Property Inspection Report" : "Home Inspection Report"} </Text>
    <View style={commonStyles.flexWrapper}>
      <View style={{ flex: 1 }}>
        <Text style={[styles.insptitle, { color: color }]}>PREPARED FOR:</Text>
        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
          {inspection_details?.orderclient?.map((value) => {
            const { first_name, last_name } = value?.client;
            clientNameLength = first_name + last_name;
            return (
              <Text wrap style={styles.inspValue}>
                {first_name} {last_name}
              </Text>
            );
          })}
        </View>
      </View>
      <View style={{ flex: 1.2 }}>
        <Text style={[styles.insptitle, { color: color }]}>INSPECTED BY:</Text>
        {inspection_details?.orderinspector?.map((value, index) => {
          const { first_name, last_name } = value?.inspector;
          return (
            <Text style={styles.inspValue}>
              {first_name} {last_name} {index === 0 ? (inspection_details?.company?.name ? `/ ${inspection_details?.company?.name}` : "") : ""}
            </Text>
          );
        })}
      </View>
    </View>
    <View style={styles.emptyCoverPic}>{cover_photo?.thumbnail || cover_photo?.onlineUrl ? <View style={{ maxHeight: clientNameLength?.length > 10 || inspection_details?.orderclient?.length === 2 ? "465px" : "487px" }}>{cover_photo?.thumbnail ? <Image style={[styles.coverPic]} src={cover_photo?.thumbnail}></Image> : cover_photo?.onlineUrl ? <Image style={[styles.coverPic]} src={cover_photo?.onlineUrl}></Image> : ""}</View> : <View style={{ maxHeight: clientNameLength?.length > 10 || inspection_details?.orderclient?.length === 2 ? "465px" : "487px", backgroundColor: "white" }}></View>}</View>
  </Page>
);
