import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

const PDFView = ({ fileUrl }) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
  const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    Print: () => <></>,
    DownloadMenuItem: () => <></>,
    PrintMenuItem: () => <></>,
  });

  return (
    <div style={{ border: "1px solid rgba(0, 0, 0, 0.3)", display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ alignItems: "center", backgroundColor: "#eeeeee", borderBottom: "1px solid rgba(0, 0, 0, 0.1)", display: "flex", padding: "0.25rem" }}>
        <Toolbar theme="dark">{renderDefaultToolbar(transform)}</Toolbar>
      </div>
      <div style={{ flex: 1, overflow: "hidden" }}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
          <Viewer defaultScale={1} fileUrl={fileUrl} theme="dark" plugins={[toolbarPluginInstance]} />
        </Worker>
      </div>
    </div>
  );
};

export default PDFView;
