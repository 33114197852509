import { Box, Grid, Modal, TextField, Typography } from "@mui/material";
import "../../../assets/styles/containers/_contactUs.scss";
import CloseIcon from "@mui/icons-material/Close";
import { apiCall } from "../../utils/action";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import validator from "validator";

const ContactUs = (props) => {
  const [errors, setErrors] = useState({});
  const { open, handleClose, inspEmail, isISN } = props;
  const [isChanged, setIsChanged] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  const [values, setValues] = useState({ name: "", email: "", message: "" });
  // handle change function
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setIsChanged(true);
  };

  const handleCloseModal = () => {
    setErrors({});
    setIsChanged(false);
    setDataIsCorrect(false);
    setValues({ name: "", email: "", message: "" });
  };

  // handle submit function
  const handleformSubmit = (event) => {
    event.preventDefault();
    setErrors(validation(values));
    setDataIsCorrect(true);
  };

  // validation function
  const validation = (values) => {
    let errors = {};
    if (!values.name.trim()) {
      errors.name = "Your name is required";
    }
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!validator.isEmail(values.email)) {
      errors.email = "Please enter a valid email address";
    }
    if (!values.message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };

  //API call

  const contactUsCall = () => {
    setShowLoader(true);
    setIsChanged(false);
    setDataIsCorrect(false);
    if (Object.keys(errors).length !== 0 && !dataIsCorrect) {
      return;
    }
    const params = {
      sender_name: values.name,
      sender_email: values.email,
      email_body: values.message?.replace(/\n/g, "<br />"),
      recipient_email: inspEmail,
      inspection_from: isISN ? "ISN" : "Palmtech",
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          handleClose();
          handleCloseModal();
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
          console.log("else ", message);
        }
      },
      params,
      "CONTACT_US"
    );
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsCorrect && isChanged) {
      contactUsCall();
    }
    // eslint-disable-next-line
  }, [errors]);

  return (
    <Modal open={open} className="modal">
      <Box className="modal-layout">
        <Grid container columnSpacing={2} sx={{ pl: 2 }}>
          <Grid item xs={10}>
            <Typography className="modal-title">Contact Us</Typography>
          </Grid>
          <Grid item xs={2}>
            <CloseIcon
              className="close-icon"
              onClick={() => {
                handleClose();
                handleCloseModal();
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth id="standard-basic" name="name" label="Your name" variant="standard" value={values.name} onChange={handleChange} error={errors.name} helperText={errors.name} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth id="standard-basic" name="email" label="Your E-mail" variant="standard" value={values.email} onChange={handleChange} error={errors.email} helperText={errors.email} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth multiline minRows={10} name="message" className="contactus-messagebox" id="outlined-basic" placeholder="Message" variant="outlined" value={values.message} onChange={handleChange} error={errors.message} helperText={errors.message} />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton disabled={!isChanged} className="contactus-send-button" loading={showLoader} onClick={handleformSubmit}>
              Send
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ContactUs;
