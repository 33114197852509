import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal, Grid, Typography, Button } from "@mui/material";
const Popup = ({ openPopup, closePopup, content, heading }) => {
  return (
    <Modal open={openPopup} className="modal">
      <Box className="confimation-modal">
        <Grid container>
          <Grid item xs={12} zIndex={1}>
            <CloseIcon className="close-icon" onClick={() => closePopup(false)} />
          </Grid>
          <Grid item xs={12}>
            <Typography className="confirmation-title">{heading}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography>{content}</Typography>
          </Grid>
          <Grid item xs={12} className="center-done">
            <Button className="contactus-done-button" onClick={() => closePopup(false)}>
              Done
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Popup;
