import Html from "react-pdf-html";
import { Header } from "../Header";
import { Footer } from "../GeneratePdf";
import { contentStyles } from "../PDFStyles";
import { NarrativePDFStyles } from "../PDFStyles";
import { Page, Text, View } from "@react-pdf/renderer";
import { convertFontTagsToInlineStyles, stylesheet } from "../HtmlConverter";

export const NarrativeSummaryPDF = ({
    isISN,
    inspection_details,
    company_html_report_setting,
    isNarrative,
    summaryDataNarrativeTemplate
}) => {
    return (
        <Page style={contentStyles.body}>
            <Header isNarrative={isNarrative} isISN={isISN} address={inspection_details?.address} clientDetails={inspection_details?.orderclient} companySettings={company_html_report_setting} />
            <View style={NarrativePDFStyles.pageContainer}>
                <Text style={NarrativePDFStyles.prefaceHeading}>SUMMARY</Text>
                {summaryDataNarrativeTemplate?.map((templateValue) => {
                    return <>
                        <View style={{ padding: "0px 15px 0px 5px" }}>
                            <Html stylesheet={stylesheet} style={{ fontSize: "10px" }}>
                                {convertFontTagsToInlineStyles(templateValue?.summaryDisclaimer)}
                            </Html>
                        </View>
                        {templateValue?.summaryData?.map((commentValue) => {
                            return <View style={{ gap: "5px", marginTop: "10px" }}>
                                <Text style={[NarrativePDFStyles.prefaceHeading, { textTransform: "uppercase" }]}>{commentValue?.name}</Text>
                                {commentValue?.summary_comment_text?.map((value, index) => {
                                    return <View style={{ flexDirection: "row" }} >
                                        <View style={[NarrativePDFStyles.bulletText, { flex: 0.04 }]}><Text>{index + 1}.</Text></View>
                                        <View style={{ flex: 1.2, paddingRight: "-10px" }}>
                                            <Html stylesheet={stylesheet} style={{ fontSize: "10px" }}>
                                                {value ? convertFontTagsToInlineStyles(value?.replace(/background:\s*none;\s*/g, '')) : ""}
                                            </Html>
                                        </View>
                                    </View>
                                })}
                            </View>
                        })}
                    </>
                })}
            </View>
            <Footer isISN={isISN} />
        </Page>
    )
}