import moment from "moment";
import { Grid, Typography } from "@mui/material";
import DirectionsIcon from '@mui/icons-material/Directions';
import "../../../../assets/styles/containers/_narrativeInspDetails.scss";
import Narrativeservice from "./narrativeInspectionInfo/NarrativeService";
import NarrativeClientDetailsLayout from './NarrativeClientDetailsLayout ';
import NarrativeInspectorDetailsLayout from './NarrativeInspectorDetailsLayout ';
import NarrativePropertyDetails from "./narrativeInspectionInfo/NarrativePropertyDetails";

const NarrativeInspDetailsLayout = (props) => {
  const { inspectionDetailsData, logo, isISN } = props;
  const inspectionDate = isISN ? moment(inspectionDetailsData?.start_time).format("MMMM D, YYYY") : moment(moment.utc(inspectionDetailsData?.start_time).local()).format("MMMM D, YYYY");
  const address = inspectionDetailsData?.address;
  return <div id={"inspection_details"}>
    <br />
    <Typography className="inspection-details-title">Inspection Details</Typography>
    <Grid container sx={{ mt: 1 }}>
      <Grid item xs={12} sm={12} md={8} sx={{ pr: "26px" }} >
        {logo === null || logo === undefined ? "" : <img className="narrative-inspection-details-image" src={logo} alt="propertyimage"></img>}
        <Typography className="narrative-inspection-details-date">{inspectionDate}</Typography>
        <div style={{ gap: "12px", display: "flex" }}>
          <div>
            <Typography className="narrative-inspection-details-address">{address}</Typography>
          </div>
          <div>
            <DirectionsIcon className="narraative-map-icon" onClick={() => window.open("/mapview", "_blank")} />
          </div>
        </div>
        <NarrativePropertyDetails propertyData={inspectionDetailsData?.propertydetails} />
        {!logo && <NarrativeClientDetailsLayout clientDetails={inspectionDetailsData?.orderclient} logo={logo} />}
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <NarrativeInspectorDetailsLayout isISN={isISN} inspectorDetails={inspectionDetailsData?.orderinspector} company={inspectionDetailsData?.company} />
        {logo && <NarrativeClientDetailsLayout clientDetails={inspectionDetailsData?.orderclient} logo={logo} />}
      </Grid>
      <Grid item xs={12} sx={{ mt: "72px" }}>
        <Narrativeservice orderService={inspectionDetailsData?.orderservice} orderCharge={inspectionDetailsData?.ordercharge} orderDiscount={inspectionDetailsData?.orderdiscount} paymentDetails={inspectionDetailsData?.payment_details} />
      </Grid>
    </Grid>
  </div >
};

export default NarrativeInspDetailsLayout;