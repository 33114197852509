import Html from "react-pdf-html";
import { Header } from "./Header";
import { Footer } from "./GeneratePdf";
import { FlagIcon } from "../../../assets/svg/Svg";
import CommentSectionPDF from "./CommentSectionPDF";
import { Page, Text, View } from "@react-pdf/renderer";
import { convertFontTagsToInlineStyles, stylesheet } from "./HtmlConverter";
import { checkCategory, checkSummaryLine, checkSummaryComment } from "../../helpers/HelperFunctions";
import { contentStyles, commonStyles, inspectionDetailsStyles, definationStyles, SummaryStyles, fullReportStyles } from "./PDFStyles";

/**
 * Render a summary PDF report.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.Toc - The table of contents.
 * @param {Function} props.setToc - The function to update the table of contents.
 * @param {string} props.color - The color for styling.
 * @param {Array} props.inspectiontemplates - The inspection templates.
 * @returns {JSX.Element} - The rendered summary PDF report.
 */
export const SummaryPDF = ({
  // Toc, setToc,
  color,
  isISN,
  inspection_details,
  inspectiontemplates,
  company_html_report_setting,
}) => {
  // Update table of contents with the summary page number
  // const updateToc = (pageNumber) => {
  //   const newArray = Toc.pageNumberArray;
  //   newArray.push({ title: "Summary", number: pageNumber, type: "SUMMARY" });
  //   setToc((prevState) => ({ ...prevState, pageNumberArray: newArray }));
  // };

  return (
    <Page style={contentStyles.body}>
      <Header isISN={isISN} address={inspection_details?.address} clientDetails={inspection_details?.orderclient} companySettings={company_html_report_setting} />
      <View>
        {/* Update table of contents with the summary page number */}
        {/* <Text render={({ pageNumber }) => updateToc(pageNumber)}></Text> */}
        {/* Summary Heading */}
        <View wrap={false} style={commonStyles.headingContainer}>
          <Text id="Summary" style={[commonStyles.headingTitle, { backgroundColor: color }]}>
            &nbsp;Summary
          </Text>
        </View>
        {/* Render summary sections */}
        {inspectiontemplates
          ?.filter((item) => {
            // Check if the key exists in the object
            if (item.hasOwnProperty("is_pdf_template")) {
              // Filter based on a condition (if key exists)
              return item["is_pdf_template"] === 0; // Replace with your condition
            } else {
              // If the key doesn't exist, don't filter (include the item)
              return true;
            }
          })
          ?.map((inspectionValue) => {
            const { rating_data, categories } = inspectionValue;
            return (
              <>
                {/* Template Title */}
                <View style={SummaryStyles.templateTitleWrapper}>
                  <Text style={[SummaryStyles.templateTitle, { color: color }]}>{inspectionValue.report_title ? inspectionValue.report_title : inspectionValue.name}</Text>
                </View>
                {/* Summary Disclaimer */}
                <View style={SummaryStyles.disclaimerWrapper}>
                  <Html stylesheet={stylesheet} style={{ fontSize: "10px" }}>
                    {convertFontTagsToInlineStyles(inspectionValue?.summary_disclaimer)}
                  </Html>
                </View>
                {/* Render rating data */}
                {rating_data
                  ?.filter((e) => e?.include_in_summary === "1" || e?.include_in_summary === 1)
                  ?.map((value) => {
                    return (
                      <View wrap={false} style={{ flexDirection: "row", marginTop: "13px", paddingLeft: "10px", paddingRight: "10px" }}>
                        <View style={{ flex: 0.1 }}>
                          <View style={{ backgroundColor: value.Color, height: "24px", width: "24px", borderRadius: "3px", paddingTop: "6px", paddingLeft: "6px" }}>{value?.include_in_summary === 1 || value.include_in_summary === "1" ? <FlagIcon /> : ""}</View>
                        </View>
                        <View style={{ flex: 1, top: -2 }}>
                          <Text style={definationStyles.ratingName}>{value.Name}</Text>
                          <Text style={definationStyles.ratingDisclaimer}>{value.Definition}</Text>
                        </View>
                      </View>
                    );
                  })}
                {/* Render categories */}
                {categories
                  ?.filter((e) => e?.is_inspection_details !== 1 && e?.is_inspection_details !== 2)
                  ?.map((catVal, calIdx) => {
                    return checkCategory(catVal) ? (
                      <>
                        <View style={[commonStyles.headingContainer, { marginTop: "10px" }]} wrap={false}>
                          <Text style={[commonStyles.headingTitle, { backgroundColor: color }]}>
                            {" "}
                            {calIdx + 1}. {catVal.prompt}
                          </Text>
                        </View>
                        {catVal?.lines?.filter((e) => e?.is_deleted === 0)?.map((value, index) =>
                          checkSummaryLine(value) === true || checkSummaryLine(value) === "true" ? (
                            <>
                              {/* Render line */}
                              <Text style={fullReportStyles.lineTitle}>
                                {calIdx + 1}.{index + 1} {value.prompt}
                              </Text>
                              {/* Render comments */}
                              <View style={[inspectionDetailsStyles.divider, { marginLeft: "10px", marginRight: "10px", border: `1px solid ${color}` }]}></View>
                              <CommentSectionPDF color={color} comments={value?.comments?.filter((e) => checkSummaryComment(e))} />
                            </>
                          ) : (
                            ""
                          )
                        )}
                      </>
                    ) : (
                      ""
                    );
                  })}
              </>
            );
          })}
        {/* End of PDF */}
      </View>
      <Footer isISN={isISN} />
    </Page>
  );
};
