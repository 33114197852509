import Navbar from "./Navbar";
import { useState, useEffect } from "react";
// import SendIcon from "@mui/icons-material/Send";
import MenuIcon from "@mui/icons-material/Menu";
import { saveAs } from "file-saver";
import { useMediaQuery } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { menuItems, narrativeMenuItems } from "../../config/app-constants";
import ContactUs from "../../pages/contactUs/ContactUs";
// import DownloadIcon from "@mui/icons-material/Download";
import "../../../assets/styles/containers/_header.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import AssignmentIcon from "@mui/icons-material/Assignment";
import ShareReport from "../../pages/shareReport/ShareReport";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
// import palmTechLogo from "../../../assets/images/PT-Logo.png";
// import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import { mediaIconSvg, pdfIconSvg } from "../../../assets/svg/Svg";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Search, StyledInputBase, SearchIconWrapper } from "../../config/app-constants";
import SampleVideoPlay from "../../common/Carousel/SampleVideoPlay";
import { AppBar, Box, Grid, Toolbar, Divider, ListItemText, ListItem, ListItemButton, ListItemIcon, IconButton, Typography, Menu, Container, Button, MenuItem, Drawer, List } from "@mui/material";
import { apiCall } from "../../utils/action";
import { toast } from "react-hot-toast";
import Popup from "../../common/popup/Popup";
import HomeTeamLogo from "../../../assets/images/HomeTeamIcon.png";
// import { getCommentReqListId } from "../../helpers/HelperFunctions";

const Header = ({ categoriesData, isNarrative, isISN, inspectionStatus, isPDFTemplate, attachments, setSelected, selectedPDF, setSelectedPDF, openPdf, pdfURL, isPDFLoaded, companySettings, inspectorData, clientData, handlePrint, sampleReport, sampleVideoReport, sampleVideoLink, sampleComentReqListId, setSampleComentReqListId }) => {
  const matches = useMediaQuery("(min-width:900px)");
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [content, setContent] = useState("");
  const [drawerState, setDrawerState] = useState({ right: false });
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openShareReport, setOpenShareReport] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const handleOpenContactUs = () => setOpenContactUs(true);
  const handleCloseContactUs = () => setOpenContactUs(false);
  const handleOpenShareReport = () => setOpenShareReport(true);
  const handleCloseShareReport = () => setOpenShareReport(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const handleCloseNavMenu = () => setAnchorElNav(null);
  let InspEmail = [];
  inspectorData?.forEach((value) => {
    InspEmail.push(value?.inspector?.email);
  });
  let clientEmail = [];
  let clientName = [];
  clientData?.forEach((value) => {
    clientEmail.push(value?.client?.email);
    clientName.push(`${value?.client?.first_name} ${value?.client?.last_name ? value?.client?.last_name : ""}`);
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filteredMenuItems = isPDFTemplate === 0 ? menuItems.filter((item) => item !== "summary") : menuItems.slice();
  const playSampleVideo = () => {
    setOpen(true);
  };

  const downloadPDF = () => {
    if (inspectionStatus !== "Published") {
      setContent("This report has not been published yet and is not able to be downloaded.");
      setPopup(true);
    } else {
      if (isPDFLoaded) {
        saveAs(pdfURL, "Home Inspection Report");
      }
      if (!isPDFLoaded) {
        setLoading(true);
        openPdf(1).then(() => setLoading(false));
      }
    }
  };
  const clearSampleReportDatas = () => {
    // setShowLoader(true);
    let data = { guid: sessionStorage.getItem("guid"), comment_request_list_id: sampleComentReqListId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          toast.success(message);
          setSampleComentReqListId([]);
        } else {
          toast.error(message);
          // setShowLoader(false);
        }
      },
      data,
      "CLEAR_SAMPLE_REPORT_DATA"
    );
  };
  //PALM 9391
  const [drawerHeight, setDrawerHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setDrawerHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openBuildRequest = () => {
    if (sampleVideoReport) {
      playSampleVideo();
    } else if (sampleReport) {
      navigate("/repair-list");
    } else if (inspectionStatus !== "Published" && (!sampleReport || !sampleVideoReport)) {
      setContent("This report has not been published yet. You must publish before a Request List can be created.");
      setPopup(true);
    } else navigate("/repair-list");
  };
  const list = (anchor) => (
    <Box className="mob_menu_nav" sx={{ width: 250, paddingTop: 3, height: drawerHeight }} role="presentation" onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)}>
      <div className="isFlexJustified ">
        <div className="menubar__logo">
          {companySettings?.company_logo_url ? <img src={companySettings?.company_logo_url} width="80" height="37" alt="logo" /> : isNarrative ? <img src={HomeTeamLogo} width="80" height="37" alt="logo" /> : ""}
        </div>
        <CloseIcon className="close__icon" />
      </div>
      <Search className="header__search__bar__menu">
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase className="search" placeholder="Start typing..." inputProps={{ "aria-label": "search" }} />
      </Search>
      <List className="drawer__menu__items">
        <ListItem disablePadding onClick={() => navigate(`/`)}>
          <ListItemButton className={pathName === "/" ? "responsive_list" : "responsive_list_margin"}>
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Full report" />
          </ListItemButton>
        </ListItem>
        {isPDFTemplate === 0 ? (
          ""
        ) : (
          <ListItem disablePadding onClick={() => navigate(`summary`)}>
            <ListItemButton className={pathName === "/summary" ? "responsive_list" : "responsive_list_margin"}>
              <ListItemIcon>
                <DescriptionOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Summary" />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem disablePadding onClick={() => navigate(`media`)}>
          <ListItemButton className={pathName === "/media" ? "responsive_list" : "responsive_list_margin"}>
            {/* <ListItemIcon>{mediaIconSvg}</ListItemIcon> */}
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Media" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding onClick={() => navigate(`pdf`)}>
          <ListItemButton className={pathName === "/pdf" ? "responsive_list" : "responsive_list_margin"}>
            {/* <ListItemIcon>{pdfIconSvg}</ListItemIcon> */}
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="PDF" />
            {/* <DownloadIcon /> */}
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding onClick={() => navigate(`attachments`)}>
          <ListItemButton className={pathName === "/attachments" ? "responsive_list" : "responsive_list_margin"}>
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Attachments" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      {isPDFTemplate === 0 ? (
        ""
      ) : (
        <List className="drawer__menu__items download_pdf" onClick={downloadPDF}>
          <ListItemIcon>
            {pdfIconSvg} {loading ? <span>Generating PDF...</span> : <span>Download PDF</span>}
          </ListItemIcon>
          {/* <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <ListItemText primary="Share report" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <ForumOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Contact us" />
          </ListItemButton>
        </ListItem> */}
        </List>
      )}
      <div style={{ display: "block" }}>
        <div className="menu_btn_share">
          <Button className="border__button_mobile" onClick={handleOpenShareReport}>
            <span className="border__btn__text">Share report</span>
          </Button>
        </div>
        <div className="menu_btn_share">
          <Button className="border__button_mobile" onClick={handleOpenContactUs}>
            <span className="border__btn__text">contact us</span>
          </Button>
        </div>
        <div className="menu_btn">
          {isPDFTemplate === 0 || isNarrative ? (
            ""
          ) : (
            <Button className="filled__button_mobile" onClick={openBuildRequest}>
              <span className="filled__btn__text">Build request list</span>
            </Button>
          )}
        </div>
        {sampleReport && !sampleVideoReport && sampleComentReqListId?.length !== 0 && (
          <div className="menu_btn_clear">
            <Button variant="text" className="cancel-btn-mobile" onClick={clearSampleReportDatas}>
              <Typography>CLEAR ALL</Typography>{" "}
            </Button>
          </div>
        )}
      </div>
    </Box>
  );

  return (
    <>
      <SampleVideoPlay open={open} handleClose={handleClose} sampleVideoLink={sampleVideoLink} />
      <ContactUs isISN={isISN} open={openContactUs} handleClose={handleCloseContactUs} inspEmail={InspEmail} />
      <ShareReport isISN={isISN} inspectionStatus={inspectionStatus} sampleReport={sampleReport} open={openShareReport} handleClose={handleCloseShareReport} clientEmail={clientEmail} clientName={clientName[0]} />
      <Popup openPopup={popup} closePopup={setPopup} content={content} heading={"Info!"} />
      {/* right menu drawer for mobile view */}
      <Drawer anchor={"right"} open={drawerState["right"]} className="menu__drawer" onClose={toggleDrawer("right", false)}>
        {list("right")}
      </Drawer>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} className={`header ${pathName === "/repair-list" && "header_repair_list"}`} elevation={0}>
        <Container className={pathName !== "/repair-list" ? "top__header" : "hidden"}>
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            {companySettings ? (
              companySettings?.use_company_name_as_logo === 1 ? (
                <h1 style={{ marginRight: "5px", color: "#000", fontSize: "14px" }}>{companySettings?.company_name}</h1>
              ) : (
                <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }} className="main-logo" disableRipple onClick={() => navigate("/")}>
                  {!companySettings?.company_logo_url ? isNarrative ? <img src={HomeTeamLogo} alt="logo"></img> : "" : <img src={companySettings?.company_logo_url} alt="logo" />}
                </IconButton>
              )
            ) : (
              ""
            )}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {isNarrative ? "" : <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {isPDFTemplate !== 0
                  ? menuItems.map((menu, index) => (
                    <NavLink className="navlink" key={index} to={{ pathname: menu === "full-report" ? "/" : `/${menu}` }} style={{ textDecoration: "none" }}>
                      <Button key={menu} onClick={handleCloseNavMenu} disableRipple>
                        <Typography className="navlist">{menu.replace(/-/g, " ")}</Typography>
                      </Button>
                    </NavLink>
                  ))
                  : filteredMenuItems.map((menu, index) => (
                    <NavLink className="navlink" key={index} to={{ pathname: menu === "full-report" ? "/" : `/${menu}` }} style={{ textDecoration: "none" }}>
                      <Button key={menu} onClick={handleCloseNavMenu} disableRipple>
                        <Typography className="navlist">{menu.replace(/-/g, " ")}</Typography>
                      </Button>
                    </NavLink>
                  ))}
              </Box>}
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex", alignItems: "center" } }}>
              <Button className="border__button" sx={{ marginRight: "6px", }} disableFocusRipple onClick={handleOpenShareReport}>
                <span className="border__btn__text">Share report</span>
              </Button>
              <Button className="border__button" sx={{ marginRight: "6px" }} onClick={handleOpenContactUs}>
                <span className="border__btn__text">Contact us</span>
              </Button>
              {isPDFTemplate !== 0 && !isNarrative ? (
                <Button className="filled__button" sx={{ marginRight: "4px" }} onClick={openBuildRequest}>
                  <span className="filled__btn__text">Build request list</span>
                </Button>
              ) : (
                ""
              )}
              {sampleReport && !sampleVideoReport && sampleComentReqListId?.length !== 0 && (
                <Button variant="text" className="cancel-btn" onClick={clearSampleReportDatas}>
                  <Typography>CLEAR ALL</Typography>{" "}
                </Button>
              )}
            </Box>
            {/* humburger menu for mobile */}
            <Box className="responsive__menu" sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={toggleDrawer("right", true)} color="inherit">
                <MenuIcon />
              </IconButton>
              <Menu id="menu-appbar" anchorEl={anchorElNav} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} keepMounted transformOrigin={{ vertical: "top", horizontal: "left" }} open={Boolean(anchorElNav)} sx={{ display: { xs: "block", md: "none" } }}>
                {menuItems.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
        {pathName === "/repair-list" && !matches && (
          <Grid className="request_list_header">
            <span onClick={() => navigate("/")}>
              <ArrowBackIcon />
              <span className="request_list_header_text">Return to report</span>{" "}
            </span>
          </Grid>
        )}
        <Divider />
        <Navbar menuItems={menuItems} isNarrative={isNarrative} narrativeMenuItems={narrativeMenuItems} handleCloseNavMenu={handleCloseNavMenu} filteredMenuItems={filteredMenuItems} selectedPDF={selectedPDF} inspectionStatus={inspectionStatus} setSelectedPDF={setSelectedPDF} isPDFTemplate={isPDFTemplate} openPdf={openPdf} setSelected={setSelected} attachments={attachments} categoriesData={categoriesData} isPDFLoaded={isPDFLoaded} pdfURL={pdfURL} handlePrint={handlePrint} />
      </AppBar>
    </>
  );
};

export default Header;