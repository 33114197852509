import { Grid, Paper, Typography } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';

const Definitions = (props) => {
    const { data,id } = props;
    return (
        <div id={id}>
            <Typography className="inspection-details-title" sx={{ mb: '22px !important' }}>
                Definitions
            </Typography>
            {data?.map((value, index) => {
                return (
                    <Paper className="definitions-paper" key={index}>
                        <Grid container>
                            <Grid item xs={2} sm={1} md={1} lg={0.7} sx={{ pt: { xs: 1.1, sm: 1.2, md: 0.7, lg: 0.7 } }}>
                                <div className="definitions-icon" style={{ backgroundColor: value.Color }}>
                                    {value.include_in_summary === '1' || value.include_in_summary === 1 ? <FlagIcon className="definitions-flag" /> : ''}
                                </div>
                            </Grid>
                            <Grid item xs={10} sm={11} md={11} lg={11.3}>
                                <Typography className="definitions-title">{value.Name}</Typography>
                                <Typography className="definitions-desc">{value.Definition}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                );
            })}
            <Typography className="definitions-desc" sx={{ mt: '22px !important', pl: '16px !important' }}>
                NOTE: All definitions listed above refer to the property or item listed as inspected on this report at the time of inspection.
            </Typography>
            <Typography className="definitions-desc" sx={{ mt: '12px !important', pl: '16px !important' }}>
                Flagged items will show up in summary view
            </Typography>
        </div>
    );
};

export default Definitions;
