import { Page, Image } from "@react-pdf/renderer";
import CoverPhotoNarrative from "../../../../assets/images/Narrative_Cover.png";
import CoverReportPage from "../../../../assets/images/HomeTeam-Columbus-Sample-Home-Inspection-Report_Page_02.png";
export const CoverPageNarrative = () => (
    <>
        <Page size={"A4"} wrap={false}>
            <Image style={{ height: "100%", width: "100%" }} src={CoverPhotoNarrative}></Image>
        </Page>
        <Page size={"A4"} wrap={false}>
            <Image style={{ height: "100%", width: "100%" }} src={CoverReportPage}></Image>
        </Page>
    </>
);
