import React from "react";
import { Grid, Button } from "@mui/material";
const DeliveryTeam = (props) => {
  const { details, reportSettings } = props;
  return (
    <div className="delivery-content">
      <p className="delivery-content-title-heading">Your team</p>
      <Grid container sx={{ mt: "40px" }} spacing={1}>
        <Grid item xs={12} md={7} lg={6}>
          <Grid container>
            {reportSettings ? (
              <Grid item xs={2}>
                {reportSettings?.company_logo_url !== null? <img alt="logo" className="your-team-pic" src={reportSettings?.company_logo_url}></img>:""}
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={"auto"}>
              <div className="your-team-details-container">
                {details?.orderinspector?.map((value, index) => {
                  return (
                    <React.Fragment key={index}>
                      <p className="delivery-details-title">Inspector</p>
                      <p className="your-team-main-name">
                        {value?.inspector?.first_name} {value?.inspector?.last_name}
                      </p>
                      {value?.inspector?.location_address_1 !== null ? (
                        <p className="your-team-main-address">
                          {value?.inspector?.location_address_1}, {value?.inspector?.city}, {value?.inspector?.country} {value?.inspector?.zip}
                        </p>
                      ) : (
                        ""
                      )}

                      {value?.inspector?.phone ? (
                        <div style={{ display: "flex" }}>
                          <p className="your-team-main-address">Phone</p>
                          <p className="your-team-main-value">{value?.inspector?.phone}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div style={{ display: "flex" }}>
                        <p className="your-team-main-address">Email</p>
                        <p className="your-team-main-value">{value?.inspector?.email}</p>
                      </div>
                      {details?.company?.website !== null ? (
                        <div style={{ display: "flex" }}>
                          <p className="your-team-main-address">Website</p>
                          <p className="your-team-main-value">{details?.company?.website}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <br />
                    </React.Fragment>
                  );
                })}
                <Button className="team-send-us-message" variant="none">
                  SEND A MESSAGE
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} lg={6}>
          {details?.orderagent?.map((value, index) => {
            return (
              <div className="your-team-details-container" key={index} style={{ marginTop: index !== 0 ? "20px" : "0px" }}>
                <p className="delivery-details-title">{value?.agent_type === 1 ? "Buyers" : "Sellers"} AGENT</p>
                <p className="your-team-main-name">
                  {value?.agent?.first_name} {value?.agent?.last_name}
                </p>
                {value?.agent?.phone ? (
                  <>
                    <br />
                    <p className="your-team-main-address">Phone</p>
                    <p className="your-team-main-value">{value?.agent?.phone}</p>
                    <br />
                  </>
                ) : (
                  ""
                )}
                <>
                  <p className="your-team-main-address">Email</p>
                  <p className="your-team-main-value">{value?.agent?.email}</p>
                </>
                <br />
              </div>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default DeliveryTeam;
