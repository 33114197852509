import { useNavigate } from "react-router-dom";
import { Typography, Paper, Grid } from "@mui/material";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const PDFTemplateContent = ({ categoriesData }) => {
  const navigate = useNavigate();
  return categoriesData !== undefined &&
    categoriesData?.filter((item) => {
      // Check if the key exists in the object
      if (item.hasOwnProperty("is_pdf_template")) {
        // Filter based on a condition (if key exists)
        return item["is_pdf_template"] === 1; // Replace with your condition
      } else {
        // If the key doesn't exist, don't filter (include the item)
        return true;
      }
    })?.length > 0 ? (
    <>
      <Typography className="pdf_tempate_title" sx={{ mt: "82px !important", mb: "41px !important" }}>
        ADDITIONAL INSPECTIONS
      </Typography>
      <div>
        {categoriesData
          ?.filter((item) => {
            if (item.hasOwnProperty("is_pdf_template")) {
              return item["is_pdf_template"] === 1;
            } else {
              return true;
            }
          })
          ?.map((value, index) => (
            <Paper
              className="pdf-template-paper"
              sx={{ mb: 3, cursor:"pointer" }}
              id={value?.name}
              onClick={() =>
                navigate("/pdf", {
                  state: { name: value?.pdf_report },
                })
              }
            >
              <Grid container sx={{ p: "18px", gap: "5px", pt: "20px" }}>
                <Grid item xs={0.8} sx={{ pt: value?.description ? 1 : 0 }}>
                  <ContentPasteIcon className="content_paste_icon" />
                </Grid>
                <Grid item xs={11}>
                  <p style={{ fontSize: "16px", lineHeight: "25px", fontWeight: "700", letterSpacing: "0.25px", color: "#3b3b3b" }}>{value?.name}</p>
                  <p style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "400", letterSpacing: "0.25px", color: "#3b3b3b" }}>{value?.description}</p>
                </Grid>
              </Grid>
            </Paper>
          ))}
      </div>
    </>
  ) : (
    ""
  );
};

export default PDFTemplateContent;
