import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChatIcon from "@mui/icons-material/Chat";
import { Divider } from "@mui/material";
import { checkTotalItemNPrice,lineRepairDetails } from "../../helpers/HelperFunctions";
import { formatCost } from "../../utils/services";

const useStyles = makeStyles((theme) => ({
  
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const RepairTotalCostCard = ({inspData}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
const {totalCost} = checkTotalItemNPrice(inspData)
const {lineData} = lineRepairDetails(inspData)

  return (
    <Card className={`${classes.root} request__list__paper p_cost`}>
      <CardActions disableSpacing>
        <div
          className="rl__total__costtitle"
          onClick={handleExpandClick}
          aria-expanded={expanded}
        >
          <span>Cost</span>
          <span
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
          >
            <KeyboardArrowDownIcon />
          </span>
        </div>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
         {lineData.map(data=>(
          <Typography className="cost__collapsed">
          <span>{data.lineName} {data.commentPresent && <ChatIcon/>}</span><span>${formatCost(data.cost)}</span>
        </Typography>
         ))}
          
        </CardContent>
        <Divider/>
      </Collapse>
      <CardActions className="rl__cost__footer">
       <span>Total ({lineData.length})</span>
       <span>${formatCost(totalCost)}</span>
      </CardActions>
    </Card>
  );
};

export default RepairTotalCostCard;
