import useMediaQuery from "@mui/material/useMediaQuery";

export function GetCurrentResolution() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width:900px)");
  const isLaptop = useMediaQuery("(max-width:1200px)");
  const isDesktop = useMediaQuery("(max-width:1536px)");

  if (isMobile) {
    return "mobile";
  } else if (isTablet) {
    return "tablet";
  } else if (isLaptop) {
    return "laptop";
  } else if (isDesktop) {
    return "desktop";
  }
}

// for table component

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function formatCost(value) {
  let result;
  let parts = value.toString().split('.');
  let whole_num = parts[0].replace(/\D/g, ''),
      dec = parts[1];
  // use the below commented number for numeric calculations(num without commas)
  // let calc_num = Number(dec !== undefined ? whole_num + '.' + dec : whole_num);
  result = new Intl.NumberFormat('en-EN').format(whole_num);
  result = dec !== undefined ? result + '.' + dec : result;
  // return { formatted: result, calculation: calc_num };
  return result
}