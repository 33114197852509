import Divider from '@mui/material/Divider';
import DeliveryHeaderv1 from "./DeliveryHeaderv1";
import DeliveryDetailsv1 from "./DeliveryDetailsv1";
import DeliveryPorchApp from "./DeliveryPorchApp";
import DeliveryIRv1 from "./DeliveryIRv1";
import useMediaQuery from '@mui/material/useMediaQuery';
import DeliveryHeaderMobilev1 from './DeliveryHeaderMobilev1';
import DeliveryDetailsMobilev1 from './DeliveryDetailsMobilev1';
import DeliveryPorchAppMobilev1 from './DeliveryPorchAppMobilev1';
import DeliveryInspAgntMobilev1 from './DeliveryInspAgntMobilev1';
import DeliveryMobileFooter from './DeliveryMobileFooter';
import DeliveryTeamv1 from './DeliveryTeamv1';
import DeliveryFooterv1 from './DeliveryFooterv1';

const DeliveryPageLayoutV1 = (props) => {
  const { data, reportSettings,appLinks } = props;
  const isMobileView = useMediaQuery('(min-width:600px)');
  return (
    <>
    {!isMobileView?<>
      {reportSettings !== null ?<DeliveryHeaderMobilev1 reportSettings={reportSettings}/>:""}
      <DeliveryDetailsMobilev1 details={data?.data} coverPhoto={data?.coverPhoto}/>
      <DeliveryPorchAppMobilev1 details={data?.data} />
      <DeliveryInspAgntMobilev1 details={data?.data}/>
      <DeliveryMobileFooter/>
    </>
    :
    <>
    {reportSettings !== null ? <DeliveryHeaderv1 reportSettings={reportSettings} /> : ""}
      <DeliveryDetailsv1 details={data?.data} coverPhoto={data?.coverPhoto} />
      <DeliveryPorchApp appLinks={appLinks} details={data?.data} />
      <Divider style={{width:'100%',marginTop:'52px',marginBottom:'50px',marginLeft:'auto',marginRight:'auto'}}/>
      <DeliveryIRv1 coverPhoto={data?.coverPhoto}/>
      <DeliveryTeamv1 details={data?.data}/>
      <DeliveryFooterv1/>
    </>
    }
      
    </>
  );
};

export default DeliveryPageLayoutV1;