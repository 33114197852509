import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { apiCall } from "../../utils/action";
import Preloader from "../../helpers/Preloader";
import DeliveryPageLayout from "./DeliveryPageLayout";
import "../../../assets/styles/containers/_delivery.scss";
const DeliveryPage = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [reportSettings, setReportSettings] = useState();
  const [inspectionData, setInspectionData] = useState({ data: "", coverPhoto: "" });
  //api call
  const getInspectionData = () => {
    setShowLoader(true);
    const formData = { guid: sessionStorage.getItem("guid") };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setShowLoader(false);
          setReportSettings(data.company_html_report_settings);
          setInspectionData({ data: data.order_data, coverPhoto: data.cover_photo?.onlineUrl });
          console.log(data);
        } else {
          setShowLoader(false);
          toast.error(message);
        }
      },
      formData,
      "GET_INSPECTION_DATA"
    );
  };
  const updateRDPClickCount= () => {
    const formData = { guid: sessionStorage.getItem("guid") };
    apiCall(
      (response) => {
        const { success,message } = response.data;
        if (success) {
          console.log("UPDATE_RDP_CLICK_COUNT", message);
        }else{
          console.log("UPDATE_RDP_CLICK_COUNT error", message);
        }
      },
      formData,
      "UPDATE_RDP_CLICK_COUNT"
    );
  };
  //init
  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    // call update click count api for the first load
    if (sessionStorage.getItem("guid") === null || sessionStorage.getItem("guid") === "null"){
      sessionStorage.setItem("guid", params?.guid);
      updateRDPClickCount();
    } 
    getInspectionData();    
  }, []);
  //render
  return (
    <>
      <Preloader showPreloader={showLoader} />
      <DeliveryPageLayout data={inspectionData} reportSettings={reportSettings} />
    </>
  );
};
export default DeliveryPage;
