//file imports
import { TableOfContents } from "./TOC";
import { CoverPage } from "./CoverPage";
import { SummaryPDF } from "./SummaryPDF";
import { FullReportPDF } from "./FullReportPDF";
import { InspectionDetails } from "./InspectionDetails";
import { Text, Document, View } from "@react-pdf/renderer";
import { contentStyles, commonStyles } from "./PDFStyles";

const year = new Date();
const Producer = "PalmTech";
const Subject = "Home Inspection Report";
const CompanyTag = `Copyright © 1998-${year.getFullYear()}, Palmtech`;

export const Footer = ({ isISN }) => (
  <View style={contentStyles.footerContainer} fixed>
    <View style={commonStyles.flexContainer}>{isISN ? "" : <Text style={contentStyles.footerLeft}>{CompanyTag}</Text>}</View>
    <View style={commonStyles.flexContainer}>
      <Text style={contentStyles.footerRight} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
    </View>
  </View>
);

export const HomeInspectionReport = ({ data, colorCode, isISN }) => {
  const { addtional_information, attachments, report_title, inspection_details, cover_photo, company_html_report_setting, inspectiontemplates } = data;
  let color;
  if (colorCode) color = colorCode;
  if (company_html_report_setting?.color_code && company_html_report_setting?.color_code !== "null") color = company_html_report_setting?.color_code;
  return (
    <Document title={`${inspection_details?.address?.replace(/ ,/g, "")}`} subject={Subject} creator={CompanyTag} producer={Producer}>
      {/* Cover Page */}
      <CoverPage company_html_report_setting={company_html_report_setting} inspection_details={inspection_details} report_title={report_title} color={color} cover_photo={cover_photo} />
      {/* Table of Content Page  */}
      <TableOfContents isISN={isISN} inspectiontemplates={inspectiontemplates} company_html_report_setting={company_html_report_setting} color={color} inspection_details={inspection_details} attachments={attachments} />
      {/* Inspection Details Page */}
      <InspectionDetails isISN={isISN} inspection_details={inspection_details} color={color} company_html_report_setting={company_html_report_setting} addtional_information={addtional_information} colorCode={colorCode} />
      {/* Full Report Content  */}
      <FullReportPDF isISN={isISN} inspection_details={inspection_details} inspectiontemplates={inspectiontemplates} color={color} company_html_report_setting={company_html_report_setting} />
      {/* Summmary Page */}
      <SummaryPDF isISN={isISN} inspection_details={inspection_details} color={color} company_html_report_setting={company_html_report_setting} inspectiontemplates={inspectiontemplates} />
    </Document>
  );
};
