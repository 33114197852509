const fontSizeConverter = (val) => {
  if (val === "1") {
    return 10;
  } else if (val === "2") {
    return 13;
  } else if (val === "3") {
    return 16;
  } else if (val === "4") {
    return 18;
  } else if (val === "5") {
    return 24;
  } else if (val === "6") {
    return 32;
  } else if (val === "7") {
    return 48;
  } else return val;
};
function isValidHexColor(color) {
  return /^#(?:[0-9a-fA-F]{3}){1,2}(?:\s*!important)?$/?.test(color);
}

function replaceInvalidColor(htmlString) {
  const validHexCode = "#000000"; // Black color hex code
  return htmlString?.replace(/(?:var\(--default-primary-color\)|#[0-9a-fA-F]{3,6}(?:\s*!important)?)/g, (match) => {
    return isValidHexColor(match) ? match : validHexCode;
  });
}
//PDF Layout
export function convertFontTagsToInlineStyles(htmlString) {
  let regex = /style="([^"]*)font-family:([^"]*)"/g;
  const regexhtml = /<br\s*\/?>|&nbsp;/g;
  // Define the regular expression to match '1em', '2em', etc.
  const emRegex = /(\d*\.?\d+)em/g;
  // Replace <font> tags with inline styles
  var convertedString = htmlString
    ?.toString()
    ?.replace(/<font\s+color="([^"]*)"\s*face="([^"]*)">(.*?)<\/font>/g, "<span style=\"color: $1; font-family: 'Open Sans';\">$3</span>")
    ?.replace(regex, 'style="$1"')
    ?.replace(/<font([^>]*)>/gi, function (match, p1) {
      var attributes = p1?.split(/\s+/);
      var styles = "";
      // Convert font attributes to inline styles
      for (var i = 0; i < attributes?.length; i++) {
        var attribute = attributes[i]?.trim();
        var parts = attribute?.split("=");
        if (parts.length === 2) {
          var name = parts[0]?.toLowerCase();
          var value = parts[1]?.replace(/['"]/g, "");
          // eslint-disable-next-line
          switch (name) {
            case "color":
              styles += "color: " + value + "; ";
              break;
            case "size":
              styles += "font-size: " + fontSizeConverter(value) + "px; ";
              break;
          }
        }
      }
      return "<span style='" + styles + "'>";
    });
  convertedString = convertedString
    ?.replace(/<\/font>/gi, "</span>")
    ?.replace(/caret-color\s*:\s*[^;]+;/g, "")
    ?.replace(emRegex, (match, emValue) => {
      const pxValue = parseFloat(emValue) * 10; // Assuming 1em = 16px
      return `${pxValue}px`;
    })?.replace(/Â/g, '');

  return replaceInvalidColor(convertedString);
}

//PDF Layout
export function convertFontTagsToInlineStylesTexas(htmlString) {
  let regex = /style="([^"]*)font-family:([^"]*)"/g;
  const regexhtml = /<br\s*\/?>|&nbsp;/g;
  // Replace <font> tags with inline styles
  var convertedString = htmlString
    ?.replace(/<font\s+color="([^"]*)"\s*face="([^"]*)">(.*?)<\/font>/g, "<span style=\"color: $1; font-family: 'Faustina';\">$3</span>")
    ?.replace(regex, 'style="$1"')
    ?.replace(regexhtml, "\n")
    ?.replace(/<font([^>]*)>/gi, function (match, p1) {
      var attributes = p1?.split(/\s+/);
      var styles = "";
      // Convert font attributes to inline styles
      for (var i = 0; i < attributes?.length; i++) {
        var attribute = attributes[i]?.trim();
        var parts = attribute?.split("=");
        if (parts.length === 2) {
          var name = parts[0]?.toLowerCase();
          var value = parts[1]?.replace(/['"]/g, "");
          // eslint-disable-next-line
          switch (name) {
            case "color":
              styles += "color: " + value + "; ";
              break;
            case "size":
              styles += "font-size: " + fontSizeConverter(value) + "px; ";
              break;
          }
        }
      }
      return "<span style='" + styles + "'>";
    });
  convertedString = convertedString?.replace(/<\/font>/gi, "</span>")?.replace(/<\/p><p>/g, "</p><br/><p>")?.replace(/Â/g, '');

  return replaceInvalidColor(convertedString);
}

//PDF Layout
export function convertFontTagsToInlineStylesTexasRecommendation(htmlString) {
  let regex = /style="([^"]*)font-family:([^"]*)"/g;
  const regexhtml = /<br\s*\/?>|&nbsp;/g;
  // Replace <font> tags with inline styles
  var convertedString = htmlString
    ?.replace(/<font\s+color="([^"]*)"\s*face="([^"]*)">(.*?)<\/font>/g, "<span style=\"color: $1; font-family: 'Faustina';\">$3</span>")
    ?.replace(regex, 'style="$1"')
    ?.replace(regexhtml, "\n")
    ?.replace(/<font([^>]*)>/gi, function (match, p1) {
      var attributes = p1?.split(/\s+/);
      var styles = "";
      // Convert font attributes to inline styles
      for (var i = 0; i < attributes?.length; i++) {
        var attribute = attributes[i]?.trim();
        var parts = attribute?.split("=");
        if (parts.length === 2) {
          var name = parts[0]?.toLowerCase();
          var value = parts[1]?.replace(/['"]/g, "");
          // eslint-disable-next-line
          switch (name) {
            case "color":
              styles += "color: " + value + "; ";
              break;
            case "size":
              styles += "font-size: " + fontSizeConverter(value) + "px;";
              break;
          }
        }
      }
      return "<span style='" + styles + "'>";
    });
  convertedString = convertedString?.replace(/<\/font>/gi, "</span>")?.replace(/Â/g, '');

  return replaceInvalidColor(convertedString);
}

export const stylesheet = {
  // clear margins for all <p> tags
  p: {
    margin: "0px 0px 10px 0px",
  },
};
