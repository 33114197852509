import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import { apiCall } from "../../../utils/action";
import Preloader from "../../../helpers/Preloader";
import "../../../../assets/styles/containers/_delivery.scss";
import "../../../../assets/styles/containers/_deliveryv1.scss";

const DeliveryPageV2 = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [reportSettings, setReportSettings] = useState();
    const [inspectionData, setInspectionData] = useState({ data: "", coverPhoto: "" });
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (sessionStorage.getItem("guid") === null || sessionStorage.getItem("guid") === "null") sessionStorage.setItem("guid", params?.guid);
    //api call
    const getInspectionData = () => {
      setShowLoader(true);
      const formData = { guid: sessionStorage.getItem("guid") };
      apiCall(
        (response) => {
          const { data, message, success } = response.data;
          if (success) {
            setShowLoader(false);
            setReportSettings(data.company_html_report_settings);
            setInspectionData({ data: data.order_data, coverPhoto: data.cover_photo?.onlineUrl });
            console.log(data);
          } else {
            setShowLoader(false);
            toast.error(message);
          }
        },
        formData,
        "GET_INSPECTION_DATA"
      );
    };
// generate pdf api
    const rwPDFGenerate= () => {
      const formData = { guid: sessionStorage.getItem("guid") };
      apiCall(
        (response) => {
          const { success,message } = response.data;
          if (!success) {
            console.log("RW_PDF_GENERATE api", message);
          }
        },
        formData,
        "RW_PDF_GENERATE"
      );
    };
    //init
    useEffect(() => {
      rwPDFGenerate();
      getInspectionData();
    }, []);
    //render
  return (
    <div className="delivery_page_container">
    <div className="delivery__content">
    <Preloader showPreloader={showLoader} />
    <div style={{height:"100vh",display:'flex',alignItems:'center',justifyContent:'center'}}><span className="loading_text" style={{fontSize:'30px'}}>RDP 2 Coming soon</span></div>
    </div>
  </div>
  )
}

export default DeliveryPageV2