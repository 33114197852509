import { saveAs } from "file-saver";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import Popup from "../../common/popup/Popup";
import { Link as Scroll } from "react-scroll";
import { NavLink, useLocation } from "react-router-dom";
import SvgIcon from "@material-ui/core/SvgIcon";
import PrintIcon from "@mui/icons-material/Print";
import { useMediaQuery } from "@material-ui/core";
import { Search } from "../../config/app-constants";
import { pdfIconSvg } from "../../../assets/svg/Svg";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Grow, Typography } from "@mui/material";

const Navbar = ({ menuItems, filteredMenuItems, isNarrative, narrativeMenuItems, handleCloseNavMenu, inspectionStatus, categoriesData, setSelected, isPDFTemplate, attachments, selectedPDF, setSelectedPDF, openPdf, pdfURL, isPDFLoaded, handlePrint }) => {
  const [menuName, setMenuName] = useState("Inspection Details");
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rotation, setRotation] = useState(false);
  const [content, setContent] = useState("");
  const [popup, setPopup] = useState(false);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const pathName = location.pathname;
  const matches = useMediaQuery("(max-width:900px)");

  const handleClose = (selectedMenu) => {
    setRotation((prev) => !prev);
    if (selectedMenu) {
      setMenuName(selectedMenu);
    }
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setRotation((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  const downloadPDF = () => {
    if (inspectionStatus !== "Published") {
      setContent("This report has not been published yet and is not able to be downloaded.");
      setPopup(true);
    } else {
      if (isPDFLoaded) {
        saveAs(pdfURL, "Home Inspection Report");
      }
      if (!isPDFLoaded) {
        setLoading(true);
        openPdf(1).then(() => setLoading(false));
      }
    }
  };

  const PrintView = () => {
    if (inspectionStatus !== "Published") {
      setContent("This report has not been published yet and is not able to be printed.");
      setPopup(true);
    } else window.print();
  };
  useEffect(() => {
    if (pathName === "/pdf") {
      if (isPDFTemplate !== 0) {
        setMenuName("default");
      } else setMenuName("PDF");
    } else setMenuName("Inspection Details");
  }, [pathName]);

  return (
    <>
      <Popup openPopup={popup} closePopup={setPopup} heading={"Info"} content={content} />

      <div className={`navbar ${matches ? "nav__mobile" : ""}`}>
        {isNarrative ? <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {isNarrative ? narrativeMenuItems?.map((menu, index) => (
            <NavLink className="navlink" key={index} to={{ pathname: menu === "full-report" ? "/" : `/${menu}` }} style={{ textDecoration: "none" }}>
              <Button key={menu} onClick={handleCloseNavMenu} disableRipple>
                <Typography className="navlist">{menu.replace(/-/g, " ")}</Typography>
              </Button>
            </NavLink>
          )) :
            isPDFTemplate !== 0
              ? menuItems.map((menu, index) => (
                <NavLink className="navlink" key={index} to={{ pathname: menu === "full-report" ? "/" : `/${menu}` }} style={{ textDecoration: "none" }}>
                  <Button key={menu} onClick={handleCloseNavMenu} disableRipple>
                    <Typography className="navlist">{menu.replace(/-/g, " ")}</Typography>
                  </Button>
                </NavLink>
              ))
              : filteredMenuItems.map((menu, index) => (
                <NavLink className="navlink" key={index} to={{ pathname: menu === "full-report" ? "/" : `/${menu}` }} style={{ textDecoration: "none" }}>
                  <Button key={menu} onClick={handleCloseNavMenu} disableRipple>
                    <Typography className="navlist">{menu.replace(/-/g, " ")}</Typography>
                  </Button>
                </NavLink>
              ))}
        </Box> :""}
        {/* commented searchbar as per discussion */}
        <Search className="header__search__bar" sx={{ display: { xs: "none", md: "flex" } }}>
          {/* <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase className="search" placeholder="Start typing..." inputProps={{ "aria-label": "search" }} /> */}
        </Search>
        <Grid className="nav__actions" sx={{ display: { xs: "none", md: "flex" }, fontSize: "14px" }}>
          {isPDFTemplate !== 0 ? (
            <span>
              <span onClick={downloadPDF}>
                <SvgIcon>{pdfIconSvg}</SvgIcon>
                {loading ? "Generating PDF..." : "Download PDF"}
              </span>
            </span>
          ) : (
            ""
          )}
          <span onClick={PrintView}>
            <PrintIcon />
            Print this view
          </span>
        </Grid>
        {pathName !== "/pdf" ? (
          <Grid sx={{ display: { xs: "block", md: "none" } }}>
            <Grid sx={{ display: { xs: "flex", md: "none" } }}>
              <span className="nav_page_title">{pathName === "/" ? "Full Report" : pathName === "/summary" ? "Summary" : pathName === "/media" ? "Media" : pathName === "/mapview" ? "Mapview" : pathName === "/delivery" ? "Delivery" : pathName === "/attachments" ? "Attachments" : pathName === "/repair-list" ? "Request List" : pathName === "/pdf" ? "PDF" : "Full Report"}</span>
            </Grid>
            <Grid sx={{ display: { xs: "flex", md: "none" } }} className="responsive_menu">
              <span className="menu_title">Jump to:</span>
              {pathName !== "/attachments" || pathName !== "/pdf" ? (
                <div className="menu__categories__wrapper">
                  <div id="categories_menu" aria-controls="demo-positioned-menu" aria-haspopup="true" className="menu__box" onClick={handleClick}>
                    <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <span className="menu__name">{menuName}</span>{" "}
                      <motion.span animate={rotation ? { rotate: 180 } : { rotate: 0 }}>
                        <KeyboardArrowDownIcon />
                      </motion.span>
                    </span>
                  </div>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    className="list__menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={() => handleClose(menuName)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    TransitionComponent={Grow}
                  >
                    {pathName === "/" && (
                      <MenuItem className="menu__item">
                        <ListItemText>
                          <Scroll to={`inspection_details`} spy={true} smooth={true} style={{ color: "black" }} onClick={() => handleClose("Inspection details")} className="list_menu_scroll" offset={-150}>
                            <ListItemIcon>
                              <ContentPasteIcon fontSize="small" />
                            </ListItemIcon>
                            <span>Inspection details</span>
                          </Scroll>
                        </ListItemText>
                      </MenuItem>
                    )}
                    {categoriesData
                      ?.filter((item) => {
                        // Check if the key exists in the object
                        if (item.hasOwnProperty("is_pdf_template")) {
                          // Filter based on a condition (if key exists)
                          return item["is_pdf_template"] === 0; // Replace with your condition
                        } else {
                          // If the key doesn't exist, don't filter (include the item)
                          return true;
                        }
                      })
                      ?.map((template, templateIndex) => (
                        <div key={templateIndex}>
                          <Typography className="menu__template__title">{template.report_title ? template.report_title : template.name}</Typography>
                          <Divider />
                          {pathName === "/" && (
                            <MenuItem className="menu__item">
                              <ListItemIcon>
                                <MenuBookIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>
                                <Scroll to={`rating_data_${template?.template_id}`} spy={true} smooth={true} style={{ color: "black" }} onClick={() => handleClose("Definitions")} className="list-menu" offset={-150}>
                                  <span>Definitions</span>
                                </Scroll>
                              </ListItemText>
                            </MenuItem>
                          )}
                          {template?.categories
                            ?.filter((e) => e?.is_inspection_details !== 1 && e.is_deleted !== 1 && e?.is_inspection_details !== 2)
                            ?.map((category, categoryIndex) => {
                              return (
                                <div key={categoryIndex}>
                                  <MenuItem className="menu__item">
                                    <ListItemIcon>
                                      <BookmarkBorderIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                      <Scroll to={`${category.prompt}_${category.category_id}`} spy={true} smooth={true} style={{ color: "black" }} onClick={() => handleClose(`${categoryIndex + 1}. ${category.prompt}`)} className="list-menu" offset={-150}>
                                        <span>{`${categoryIndex + 1}. ${category.prompt}`}</span>
                                      </Scroll>
                                    </ListItemText>
                                  </MenuItem>
                                </div>
                              );
                            })}
                        </div>
                      ))}
                    {pathName === "/" || pathName === "/media" ? (
                      <>
                        {categoriesData?.filter((item) => {
                          // Check if the key exists in the object
                          if (item.hasOwnProperty("is_pdf_template")) {
                            return item["is_pdf_template"] === 1; // Replace with your condition
                          } else {
                            return true;
                          }
                        })?.length > 0 ? (
                          <>
                            <Typography className="menu__template__title">OTHER INSPECTION FORMS</Typography>
                            <Divider style={{ marginBottom: "3px" }} />
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {categoriesData
                      ?.filter((item) => {
                        // Check if the key exists in the object
                        if (item.hasOwnProperty("is_pdf_template")) {
                          // Filter based on a condition (if key exists)
                          return item["is_pdf_template"] === 1; // Replace with your condition
                        } else {
                          // If the key doesn't exist, don't filter (include the item)
                          return true;
                        }
                      })
                      ?.map((template, templateIndex) => (
                        <div key={templateIndex}>
                          {(pathName === "/" || pathName === "/media") && (
                            <MenuItem className="menu__item">
                              <ListItemIcon>
                                <PictureAsPdfIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>
                                <Scroll to={`${template?.name}`} spy={true} smooth={true} style={{ color: "black" }} onClick={() => handleClose(template?.name)} className="list-menu">
                                  <span>{template?.name}</span>
                                </Scroll>
                              </ListItemText>
                            </MenuItem>
                          )}
                        </div>
                      ))}
                  </Menu>
                </div>
              ) : (
                <div className="menu__categories__wrapper">
                  <div id="categories_menu" aria-controls="demo-positioned-menu" aria-haspopup="true" className="menu__box" onClick={handleClick}>
                    <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <span className="menu__name">{attachments?.some((e) => e?.type?.includes("image")) ? "Images" : "PDF"}</span>{" "}
                      <motion.span animate={rotation ? { rotate: 180 } : { rotate: 0 }}>
                        <KeyboardArrowDownIcon />
                      </motion.span>
                    </span>
                  </div>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    className="list__menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={() => handleClose(menuName)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    TransitionComponent={Grow}
                  >
                    {attachments
                      ?.filter((e) => e?.type === "application/pdf")
                      ?.map((value) => {
                        return (
                          <MenuItem className="menu__item" onClick={() => setSelected(value.onlineUrl)}>
                            <ListItemText>
                              {/* <Scroll to={`inspection_details`} spy={true} smooth={true} style={{ color: "black" }} onClick={() => handleClose("Inspection details")} className="list_menu_scroll" offset={-150}> */}
                              <ListItemIcon>
                                <ContentPasteIcon fontSize="small" />
                              </ListItemIcon>
                              <span>{value?.description}</span>
                              {/* </Scroll> */}
                            </ListItemText>
                          </MenuItem>
                        );
                      })}
                    {attachments?.some((e) => e?.type?.includes("image")) ? (
                      <MenuItem className="menu__item" onClick={() => setSelected("images")}>
                        <ListItemText>
                          {/* <Scroll to={`inspection_details`} spy={true} smooth={true} style={{ color: "black" }} onClick={() => handleClose("Inspection details")} className="list_menu_scroll" offset={-150}> */}
                          <ListItemIcon>
                            <ContentPasteIcon fontSize="small" />
                          </ListItemIcon>
                          <span>Images</span>
                          {/* </Scroll> */}
                        </ListItemText>
                      </MenuItem>
                    ) : (
                      ""
                    )}
                  </Menu>
                </div>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid sx={{ display: { xs: "block", md: "none" } }}>
            <Grid sx={{ display: { xs: "flex", md: "none" } }}>
              <span className="nav_page_title">{pathName === "/" ? "Full Report" : pathName === "/summary" ? "Summary" : pathName === "/media" ? "Media" : pathName === "/mapview" ? "Mapview" : pathName === "/delivery" ? "Delivery" : pathName === "/attachments" ? "Attachments" : pathName === "/repair-list" ? "Request List" : pathName === "/pdf" ? "PDF" : "Full Report"}</span>
            </Grid>
            <Grid sx={{ display: { xs: "flex", md: "none" } }} className="responsive_menu">
              <span className="menu_title">Jump to:</span>
              {pathName !== "/attachments" || pathName !== "/pdf" ? (
                <div className="menu__categories__wrapper">
                  <div id="categories_menu" aria-controls="demo-positioned-menu" aria-haspopup="true" className="menu__box" onClick={handleClick}>
                    <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <span className="menu__name">{menuName === "default" ? "Home Inspection Report" : menuName}</span>{" "}
                      <motion.span animate={rotation ? { rotate: 180 } : { rotate: 0 }}>
                        <KeyboardArrowDownIcon />
                      </motion.span>
                    </span>
                  </div>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    className="list__menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={() => handleClose()}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    TransitionComponent={Grow}
                  >
                    {isPDFTemplate !== 0 ? (
                      <MenuItem
                        className="menu__item"
                        onClick={() => {
                          setSelectedPDF("default");
                          handleClose("default");
                        }}
                      >
                        <ListItemText>
                          {/* <Scroll to={`inspection_details`} spy={true} smooth={true} style={{ color: "black" }} onClick={() => handleClose("Inspection details")} className="list_menu_scroll" offset={-150}> */}
                          <ListItemIcon>
                            <ContentPasteIcon fontSize="small" />
                          </ListItemIcon>
                          <span>Home Inspection Report</span>
                          {/* </Scroll> */}
                        </ListItemText>
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    {categoriesData
                      ?.filter((item) => {
                        if (item.hasOwnProperty("is_pdf_template")) {
                          return item["is_pdf_template"] === 1;
                        } else {
                          return true;
                        }
                      })
                      ?.map((value) => {
                        return (
                          <MenuItem
                            className="menu__item"
                            onClick={() => {
                              setSelectedPDF(value.pdf_report);
                              handleClose(value?.name);
                            }}
                          >
                            <ListItemText>
                              {/* <Scroll to={`inspection_details`} spy={true} smooth={true} style={{ color: "black" }} onClick={() => handleClose("Inspection details")} className="list_menu_scroll" offset={-150}> */}
                              <ListItemIcon>
                                <ContentPasteIcon fontSize="small" />
                              </ListItemIcon>
                              <span>{value.name}</span>
                              {/* </Scroll> */}
                            </ListItemText>
                          </MenuItem>
                        );
                      })}
                  </Menu>
                </div>
              ) : (
                <div className="menu__categories__wrapper">
                  <div id="categories_menu" aria-controls="demo-positioned-menu" aria-haspopup="true" className="menu__box" onClick={handleClick}>
                    <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <span className="menu__name">{attachments?.some((e) => e?.type?.includes("image")) ? "Images" : "PDF"}</span>{" "}
                      <motion.span animate={rotation ? { rotate: 180 } : { rotate: 0 }}>
                        <KeyboardArrowDownIcon />
                      </motion.span>
                    </span>
                  </div>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    className="list__menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={() => handleClose(menuName)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    TransitionComponent={Grow}
                  >
                    {categoriesData
                      ?.filter((item) => {
                        if (item.hasOwnProperty("is_pdf_template")) {
                          return item["is_pdf_template"] === 1;
                        } else {
                          return true;
                        }
                      })
                      ?.map((value) => {
                        return (
                          <MenuItem className="menu__item" onClick={() => setSelectedPDF(value.pdf_report)}>
                            <ListItemText>
                              {/* <Scroll 
                            to={`inspection_details`} 
                            spy={true} smooth={true} style={{ color: "black" }} 
                            onClick={() => handleClose("Inspection details")} 
                            className="list_menu_scroll" 
                            offset={-150}> */}
                              <ListItemIcon>
                                <ContentPasteIcon fontSize="small" />
                              </ListItemIcon>
                              <span>{value.name}</span>
                              {/* </Scroll> */}
                            </ListItemText>
                          </MenuItem>
                        );
                      })}
                    {attachments
                      ?.filter((e) => e?.type === "application/pdf")
                      ?.map((value) => {
                        return (
                          <MenuItem className="menu__item" onClick={() => setSelected(value.onlineUrl)}>
                            <ListItemText>
                              {/* <Scroll to={`inspection_details`} spy={true} smooth={true} style={{ color: "black" }} onClick={() => handleClose("Inspection details")} className="list_menu_scroll" offset={-150}> */}
                              <ListItemIcon>
                                <ContentPasteIcon fontSize="small" />
                              </ListItemIcon>
                              <span>{value?.description}</span>
                              {/* </Scroll> */}
                            </ListItemText>
                          </MenuItem>
                        );
                      })}
                    {attachments?.some((e) => e?.type?.includes("image")) ? (
                      <MenuItem className="menu__item" onClick={() => setSelected("images")}>
                        <ListItemText>
                          {/* <Scroll to={`inspection_details`} spy={true} smooth={true} style={{ color: "black" }} onClick={() => handleClose("Inspection details")} className="list_menu_scroll" offset={-150}> */}
                          <ListItemIcon>
                            <ContentPasteIcon fontSize="small" />
                          </ListItemIcon>
                          <span>Images</span>
                          {/* </Scroll> */}
                        </ListItemText>
                      </MenuItem>
                    ) : (
                      ""
                    )}
                  </Menu>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
};

export default Navbar;
