const NarrativePropertyDetails = ({ propertyData }) => {
    return <>
        <div className="property-details-narrative-container">
            {propertyData?.total_area && <div className="property-name-container">{propertyData?.total_area} sq ft
            </div>}
            {propertyData?.year_built && <div className="property-name-container">Built in: {propertyData?.year_built}
            </div>}
        </div>

    </>
}
export default NarrativePropertyDetails;