// import propertyImage from "../../../assets/images/inspection-details-image.png";
import InspectorDetailsLayout from "./InspectorDetailsLayout";
import PropertyDetailsLayout from "./PropertyDetailsLayout";
import { Button, Grid, Typography } from "@mui/material";
import ClientDetailsLayout from "./ClientDetailsLayout";
import AdditionalInfo from "./AdditionalInfo";
import ServicesLayout from "./ServicesLayout";
import moment from "moment";

const InspectionDetailsLayout = (props) => {
  const { inspectionDetailsData, isPDFTemplate, additionalInfo, logo, isISN, companySettings } = props;
  const inspDate = isISN ? moment(inspectionDetailsData?.start_time).format("MMM Do, YYYY") : moment(moment.utc(inspectionDetailsData?.start_time).local()).format("MMM Do, YYYY");
  // Check if all values are null in the array
  const allValuesNull = inspectionDetailsData?.propertydetails && Object.values(inspectionDetailsData?.propertydetails).every((value) => value === null);

  const addressLine1 = inspectionDetailsData?.location_address ?? '';
  const addressLine2 = inspectionDetailsData?.location_address_2 ?? '';
  const city = inspectionDetailsData?.location_city ?? '';
  const state = inspectionDetailsData?.location_state?.toUpperCase() ?? '';
  const zipCode = inspectionDetailsData?.location_zip ?? '';
  const address = inspectionDetailsData?.address;

  return (
    <div id={"inspection_details"}>
      <br />
      <Typography className="inspection-details-title">Inspection Details</Typography>
      {logo === null || logo === undefined ? "" : <img className="inspection-details-image" src={logo} alt="propertyimage"></img>}
      <Grid container sx={{ mt: "23px" }}>
        <Grid item xs={6}>
          <Typography className="inspection-details-date">{inspDate}</Typography>
          <Typography className="inspection-details-address">{address}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button className="inspection-details-viewmap" variant="none" onClick={() => window.open("/mapview", "_blank")}>
            VIEW MAP
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: "21px !important" }}>
        <Grid item md={allValuesNull ? 6 : 4.5} lg={allValuesNull ? 6 : 4.5}>
          <InspectorDetailsLayout isISN={isISN} inspectorDetails={inspectionDetailsData?.orderinspector} company={inspectionDetailsData?.company} />
        </Grid>
        <Grid item md={allValuesNull ? 6 : 4.5} lg={allValuesNull ? 6 : 4.5}>
          <ClientDetailsLayout clientDetails={inspectionDetailsData?.orderclient} agentDetails={inspectionDetailsData?.orderagent} />
        </Grid>
        {!allValuesNull ? (
          <Grid item md={3} lg={3}>
            <PropertyDetailsLayout propertyData={inspectionDetailsData?.propertydetails} />
          </Grid>
        ) : (
          ""
        )}
        {isPDFTemplate === 0 ? (
          ""
        ) : (
          <Grid item xs={12}>
            <AdditionalInfo inspDate={inspDate} data={additionalInfo} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ServicesLayout companySettings={companySettings} total={inspectionDetailsData?.fee} serviceDetails={inspectionDetailsData?.orderservice} paymentDetails={inspectionDetailsData?.payment_details} orderCharges={inspectionDetailsData?.ordercharge} orderDiscount={inspectionDetailsData?.orderdiscount} />
        </Grid>
      </Grid>
    </div>
  );
};
export default InspectionDetailsLayout;
