import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ChatIcon from '@mui/icons-material/Chat';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { motion } from "framer-motion";

const RequestIconsCard = ({isRepairSelected,localRepairSelection,setLocalRepairSelection}) => {
  return (
    <motion.div animate={!localRepairSelection?
      { y: [0, 0, 0], opacity: 1}:{ y: [0,0, 0], opacity: 0}
      // { opacity: 1}:{  opacity: 0}
  }
              transition={{
                  duration: 0.2,
                  delay: 0,
                  ease: [0.2, 0.3, 0.3, 0.4],
                  default: { ease: "linear" }
              }}
              initial={{ opacity: 0.2 }}>
      <Card className={`repair__icons__card ${isRepairSelected?'active_icons':''} ${localRepairSelection?'height':''}`} onClick={()=>setLocalRepairSelection(true)}>
        <CardContent className={`icon__content `}>
          <div className="horizontal__toggle__arrow"><ExpandCircleDownOutlinedIcon  /></div>
        <ChatIcon className="selected_icon"/>
        <AttachMoneyIcon className="selected_icon"/>
        </CardContent>
      </Card>
    </motion.div>
  )
}

export default RequestIconsCard