import { Header } from "./Header";
import moment from "moment/moment";
import { Footer } from "./GeneratePdf";
import { Page, Text, View } from "@react-pdf/renderer";
import { InspectionIcon, DotIcon } from "../../../assets/svg/Svg";
import { contentStyles, commonStyles, inspectionDetailsStyles } from "./PDFStyles";

const DateFormat = "MMM Do, YYYY";

export const InspectionDetails = ({
  // Toc, setToc,
  isISN,
  inspection_details,
  color,
  company_html_report_setting,
  addtional_information,
  colorCode,
  isNarrative,
}) => {
  // Check if all values are null in the array
  const allValuesNull = inspection_details?.propertydetails && Object.values(inspection_details?.propertydetails).every((value) => value === null);
  return (
    <Page style={contentStyles.body} >
      {/* <Text
      render={({ pageNumber }) => {
        const newArray = Toc.pageNumberArray;
        newArray.push({ title: "Inspection Details", number: pageNumber, type: "INSPECTION_DETAILS" });
        setToc((prevState) => ({ ...prevState, pageNumberArray: newArray }));
      }}
    ></Text> */}
      <Header isNarrative={isNarrative} isISN={isISN} address={inspection_details?.address} clientDetails={inspection_details?.orderclient} companySettings={company_html_report_setting} />
      <View style={commonStyles.headingContainer}>
        <Text id="Inspection Details" style={[commonStyles.headingTitle, { backgroundColor: color }]}>
          {" "}
          Inspection Details
        </Text>
      </View>
      <View style={inspectionDetailsStyles.wrapper}>
        <View style={commonStyles.flexContainer}>
          {inspection_details?.orderinspector?.map((value) => {
            const { first_name, last_name, phone, email, location_address_1, city, state, zip } = value?.inspector;
            const address = `${location_address_1 ? location_address_1 + ", " : ""} ${city}, ${state} ${zip}`;
            return (
              <>
                <Text style={[inspectionDetailsStyles.blockTitle, { color: color }]}>inspector</Text>
                <View style={{ flexDirection: "row", marginBottom: "7px" }}>
                  <View style={commonStyles.flexContainer}>
                    <Text style={inspectionDetailsStyles.title}>
                      {first_name} {last_name}
                    </Text>
                    {inspection_details?.company?.name ? <Text style={inspectionDetailsStyles.titleLeft}>{inspection_details?.company?.name}</Text> : ""}
                  </View>
                  <View style={commonStyles.flexContainerRight}>
                    <Text style={inspectionDetailsStyles.titleRight}>{address?.replace(/null/g, "")?.replace(/ ,/g, "")}</Text>
                  </View>
                </View>
                <View style={inspectionDetailsStyles.dividerGrey}></View>
                <View style={commonStyles.flexWrapper}>
                  <View style={commonStyles.flexContainer}>
                    <Text style={inspectionDetailsStyles.valueTitle}>Phone</Text>
                  </View>
                  <View style={commonStyles.flexContainerRight}>
                    <Text style={inspectionDetailsStyles.value}>{phone}</Text>
                  </View>
                </View>
                <View style={inspectionDetailsStyles.dividerGrey}></View>
                <View style={commonStyles.flexWrapper}>
                  <View style={{ flex: 0 }}>
                    <Text style={inspectionDetailsStyles.valueTitle}>Email</Text>
                  </View>
                  <View style={commonStyles.flexContainerRight}>
                    <Text style={inspectionDetailsStyles.value}>{email}</Text>
                  </View>
                </View>
                <View style={inspectionDetailsStyles.dividerGrey}></View>
                {inspection_details?.company?.website ? (
                  <View style={{ flexDirection: "row", marginBottom: "7px" }}>
                    <View style={{ flex: 0.5 }}>
                      <Text style={inspectionDetailsStyles.valueTitle}>Website</Text>
                    </View>
                    <View style={commonStyles.flexContainerRight}>
                      <Text style={inspectionDetailsStyles.value}>{inspection_details?.company?.website}</Text>
                    </View>
                  </View>
                ) : (
                  ""
                )}
                <View style={[inspectionDetailsStyles.divider, { border: `1px solid ${color}` }]}></View>
              </>
            );
          })}
          {inspection_details?.orderclient?.map((value) => {
            const { first_name, last_name, phone, email } = value?.client;
            return (
              <>
                <Text style={[inspectionDetailsStyles.blockTitle, { color: color }]}>client</Text>
                <Text style={[inspectionDetailsStyles.title, { marginBottom: "7px" }]}>
                  {first_name} {last_name}
                </Text>
                <View style={inspectionDetailsStyles.dividerGrey}></View>
                <View style={commonStyles.flexWrapper}>
                  <View style={commonStyles.flexContainer}>
                    <Text style={inspectionDetailsStyles.valueTitle}>Phone</Text>
                  </View>
                  <View style={commonStyles.flexContainerRight}>
                    <Text style={inspectionDetailsStyles.value}>{phone}</Text>
                  </View>
                </View>
                <View style={inspectionDetailsStyles.dividerGrey}></View>
                <View style={{ flexDirection: "row", marginBottom: "7px" }}>
                  <View style={{ flex: "0" }}>
                    <Text style={inspectionDetailsStyles.valueTitle}>Email</Text>
                  </View>
                  <View style={commonStyles.flexContainerRight}>
                    <Text style={inspectionDetailsStyles.value}>{email}</Text>
                  </View>
                </View>
                <View style={[inspectionDetailsStyles.divider, { border: `1px solid ${color}` }]}></View>
              </>
            );
          })}

          {(inspection_details?.orderinspector?.length ?? 0) + (inspection_details?.orderclient?.length ?? 0) <= 5 && !inspection_details?.company?.website ? inspection_details?.orderagent?.filter((element, index) => index === 0)?.map((value) => {
            const { first_name, last_name, phone, email } = value?.agent;
            return (<>
              <Text style={[inspectionDetailsStyles.blockTitle, { color: color }]}>{value.agent_type !== 2 ? "BUYERS" : "SELLERS"} Agent</Text>
              <Text style={[inspectionDetailsStyles.title, { marginBottom: "7px" }]}>
                {first_name} {last_name}
              </Text>
              <View style={inspectionDetailsStyles.dividerGrey}></View>
              <View style={commonStyles.flexWrapper}>
                <View style={commonStyles.flexContainer}>
                  <Text style={inspectionDetailsStyles.valueTitle}>Phone</Text>
                </View>
                <View style={commonStyles.flexContainerRight}>
                  <Text style={inspectionDetailsStyles.value}>{phone}</Text>
                </View>
              </View>
              <View style={inspectionDetailsStyles.dividerGrey}></View>
              <View style={{ flexDirection: "row", marginBottom: "5px" }}>
                <View style={{ flex: 0 }}>
                  <Text style={inspectionDetailsStyles.valueTitle}>Email</Text>
                </View>
                <View style={commonStyles.flexContainerRight}>
                  <Text style={inspectionDetailsStyles.value}>{email}</Text>
                </View>
              </View>
            </>)
          }) : ""}
        </View>
        <View style={{ flex: 1, marginLeft: "27px" }}>
          {(inspection_details?.orderinspector?.length ?? 0) + (inspection_details?.orderclient?.length ?? 0) <= 5 && !inspection_details?.company?.website ? inspection_details?.orderagent?.filter((element, index) => index === 1)?.map((value) => {
            const { first_name, last_name, phone, email } = value?.agent;
            return (<>
              <Text style={[inspectionDetailsStyles.blockTitle, { color: color }]}>{value.agent_type !== 2 ? "BUYERS" : "SELLERS"} Agent</Text>
              <Text style={[inspectionDetailsStyles.title, { marginBottom: "7px" }]}>
                {first_name} {last_name}
              </Text>
              <View style={inspectionDetailsStyles.dividerGrey}></View>
              <View style={commonStyles.flexWrapper}>
                <View style={commonStyles.flexContainer}>
                  <Text style={inspectionDetailsStyles.valueTitle}>Phone</Text>
                </View>
                <View style={commonStyles.flexContainerRight}>
                  <Text style={inspectionDetailsStyles.value}>{phone}</Text>
                </View>
              </View>
              <View style={inspectionDetailsStyles.dividerGrey}></View>
              <View style={{ flexDirection: "row", marginBottom: "5px" }}>
                <View style={{ flex: 0 }}>
                  <Text style={inspectionDetailsStyles.valueTitle}>Email</Text>
                </View>
                <View style={commonStyles.flexContainerRight}>
                  <Text style={inspectionDetailsStyles.value}>{email}</Text>
                </View>
              </View>
              <View style={[inspectionDetailsStyles.divider, { border: `1px solid ${color}` }]}></View>
            </>)
          }) : inspection_details?.orderagent?.map((value, index) => {
            const { first_name, last_name, phone, email } = value?.agent;
            return (
              <>
                <Text style={[inspectionDetailsStyles.blockTitle, { color: colorCode }]}>{value.agent_type !== 2 ? "BUYERS" : "SELLERS"} Agent</Text>
                <Text style={[inspectionDetailsStyles.title, { marginBottom: "7px" }]}>
                  {first_name} {last_name}
                </Text>
                <View style={inspectionDetailsStyles.dividerGrey}></View>
                <View style={commonStyles.flexWrapper}>
                  <View style={commonStyles.flexContainer}>
                    <Text style={inspectionDetailsStyles.valueTitle}>Phone</Text>
                  </View>
                  <View style={commonStyles.flexContainerRight}>
                    <Text style={inspectionDetailsStyles.value}>{phone}</Text>
                  </View>
                </View>
                <View style={inspectionDetailsStyles.dividerGrey}></View>
                <View style={{ flexDirection: "row", marginBottom: "10px" }}>
                  <View style={{ flex: 0 }}>
                    <Text style={inspectionDetailsStyles.valueTitle}>Email</Text>
                  </View>
                  <View style={commonStyles.flexContainerRight}>
                    <Text style={inspectionDetailsStyles.value}>{email}</Text>
                  </View>
                </View>
                <View style={[inspectionDetailsStyles.divider, { border: `1px solid ${color}` }]}></View>
              </>
            )
          })}
          {!allValuesNull ? <><Text style={[inspectionDetailsStyles.blockTitle, { color: color }]}>Property</Text>
            {inspection_details?.propertydetails?.total_area ? <> <View style={commonStyles.flexWrapper}>
              <View style={commonStyles.flexContainer}>
                <Text style={inspectionDetailsStyles.valueTitle}>Square feet</Text>
              </View>
              <View style={commonStyles.flexContainerRight}>
                <Text style={inspectionDetailsStyles.value}>{inspection_details?.propertydetails?.total_area}</Text>
              </View>
            </View>
              <View style={inspectionDetailsStyles.dividerGrey}></View></> : ""}
            {inspection_details?.propertydetails?.year_built ? <><View style={commonStyles.flexWrapper}>
              <View style={commonStyles.flexContainer}>
                <Text style={inspectionDetailsStyles.valueTitle}>Year built</Text>
              </View>
              <View style={commonStyles.flexContainerRight}>
                <Text style={inspectionDetailsStyles.value}>{inspection_details?.propertydetails?.year_built}</Text>
              </View>
            </View>
              <View style={inspectionDetailsStyles.dividerGrey}></View></> : ""}
            {inspection_details?.propertydetails?.total_bedrooms ? <><View style={commonStyles.flexWrapper}>
              <View style={commonStyles.flexContainer}>
                <Text style={inspectionDetailsStyles.valueTitle}>Bedrooms</Text>
              </View>
              <View style={commonStyles.flexContainerRight}>
                <Text style={inspectionDetailsStyles.value}>{inspection_details?.propertydetails?.total_bedrooms}</Text>
              </View>
            </View>
              <View style={inspectionDetailsStyles.dividerGrey}></View></> : ""}
            {inspection_details?.propertydetails?.type ? <><View style={commonStyles.flexWrapper}>
              <View style={commonStyles.flexContainer}>
                <Text style={inspectionDetailsStyles.valueTitle}>Type</Text>
              </View>
              <View style={commonStyles.flexContainerRight}>
                <Text style={inspectionDetailsStyles.value}>{inspection_details?.propertydetails?.type}</Text>
              </View>
            </View>
              <View style={inspectionDetailsStyles.dividerGrey}></View></> : ""}
            {inspection_details?.propertydetails?.total_garage ? <><View style={commonStyles.flexWrapper}>
              <View style={commonStyles.flexContainer}>
                <Text style={inspectionDetailsStyles.valueTitle}>Garage</Text>
              </View>
              <View style={commonStyles.flexContainerRight}>
                <Text style={inspectionDetailsStyles.value}>{inspection_details?.propertydetails?.total_garage}</Text>
              </View>
            </View>
              <View style={inspectionDetailsStyles.dividerGrey}></View></> : ""}
            {inspection_details?.propertydetails?.total_bathrooms ? <View style={commonStyles.flexWrapper}>
              <View style={commonStyles.flexContainer}>
                <Text style={inspectionDetailsStyles.valueTitle}>Bathrooms</Text>
              </View>
              <View style={commonStyles.flexContainerRight}>
                <Text style={inspectionDetailsStyles.value}>{inspection_details?.propertydetails?.total_bathrooms}</Text>
              </View>
            </View> : ""}
            <View style={[inspectionDetailsStyles.divider, { border: `1px solid ${color}` }]}></View></> : ""}

          <Text style={[inspectionDetailsStyles.blockTitle, { color: color }]}>Additional info</Text>
          <View style={{ flexDirection: "row", marginTop: "5px" }}>
            <InspectionIcon />
            <View style={commonStyles.flexContainer}>
              <Text style={inspectionDetailsStyles.valueTitle}>Inspection date</Text>
            </View>
            <View style={commonStyles.flexContainerRight}>
              <Text style={inspectionDetailsStyles.value}>{moment(inspection_details?.start_time).format(DateFormat)}</Text>
            </View>
          </View>
          <View style={inspectionDetailsStyles.dividerGrey}></View>
          {addtional_information?.filter((e) => e.is_deleted === 0)?.map((value, index) => {
            const { prompt, line_input_controls, type } = value;
            const dataValue = line_input_controls?.filter((val) => Object?.keys(val)?.includes("data"));
            return (
              <>
                <View style={{ flexDirection: "row", marginTop: "2px" }}>
                  <View style={{ marginTop: "3px", marginRight: "3px" }}>
                    <DotIcon />
                  </View>
                  <View style={commonStyles.flexContainer}>
                    <Text style={inspectionDetailsStyles.valueTitle}>{prompt}</Text>
                  </View>
                  <View style={commonStyles.flexContainerRight}>
                    <Text style={inspectionDetailsStyles.value}>{type === "Temperature" ? `${dataValue[0]?.data?.value ? dataValue[0]?.data?.value : ""} ${dataValue[0]?.data?.type === "Far" ? "°F" : dataValue[0]?.data?.type === "Cel" ? "°C" : dataValue[0]?.data?.type}` : `${dataValue[0]?.data?.toString()}`} </Text>
                  </View>
                </View>
                {addtional_information?.length !== index + 1 ? <View style={inspectionDetailsStyles.dividerGrey}></View> : ""}
              </>
            );
          })}
        </View>
      </View>
      <Footer isISN={isISN} />
    </Page>
  )
}