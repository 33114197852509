import { Button, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
const DeliveryContent = (props) => {
  const { coverPhoto } = props;
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <div className="delivery-content">
      <Grid container spacing={1}>
        <Grid item xs={12} md={7.2} sx={{ display: "flex" }}>
          <div className="delivery-content-logo">
            <svg width="39" height="35" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 34.375V30.625H15.75V26.875H4.5C3.46875 26.875 2.58625 26.5081 1.8525 25.7744C1.1175 25.0394 0.75 24.1563 0.75 23.125V4.375C0.75 3.34375 1.1175 2.46062 1.8525 1.72562C2.58625 0.991875 3.46875 0.625 4.5 0.625H34.5C35.5313 0.625 36.4144 0.991875 37.1494 1.72562C37.8831 2.46062 38.25 3.34375 38.25 4.375V23.125C38.25 24.1563 37.8831 25.0394 37.1494 25.7744C36.4144 26.5081 35.5313 26.875 34.5 26.875H23.25V30.625H27V34.375H12ZM4.5 23.125H34.5V4.375H4.5V23.125Z" fill="white" />
            </svg>
          </div>
          <div className="delivery-content-title">
            <p className="delivery-content-title-heading">Your interactive report is ready!</p>
            <p className="delivery-content-title-subheading">{!matches ? "Click the button below to open your interactive report! Use the menu in the upper right to navigate to different views and use the Request List Builder to easily create your inspection response." : "Click the button below to open your interactive report! Once open, you can choose to see the entire report, the summary items, media, download your PDF for long term storage and use the Request List Builder to easily create your inspection response."}</p>
            <Button className="view-html-report" variant="none" onClick={() => window.open(`${window.location.origin}/?guid=${sessionStorage.getItem("guid")}`, "_blank")}>
              view report
            </Button>
            <br />
            <p className="delivery-content-title-subheading">Or copy the link below.</p>
            <p className="delivery-content-link" onClick={() => window.open(`${window.location.origin}/?guid=${sessionStorage.getItem("guid")}`, "_blank")}>
              {window.location.origin}/?guid={sessionStorage.getItem("guid")}
            </p>
          </div>
        </Grid>
        <Grid item xs={12} md={4.8}>
          {coverPhoto ? <img alt="cover" className="delivery-cover-image" src={coverPhoto}></img> : ""}
        </Grid>
      </Grid>
    </div>
  );
};
export default DeliveryContent;
