import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageCarousel from "../../common/Carousel/ImageCarousel";
import { Grid, Paper, Typography, Modal, Box, Collapse, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import Carousel from '../../common/Carousel/Carousel';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import InsertPhotoRoundedIcon from "@mui/icons-material/InsertPhotoRounded";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CircleIcon from "@mui/icons-material/Circle";
import FlagIcon from "@mui/icons-material/Flag";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import PrintIcon from "@mui/icons-material/Print";
import "../../../assets/styles/containers/_preview.scss";
import ChatIcon from "@mui/icons-material/Chat";
import { Divider } from "@material-ui/core";
import ScrollToTop from "react-scroll-to-top";
import { checkRepairSelectionCategory, checkRepairSelectionLine, checkRepairSelectionComment, checkTotalItemNPrice } from "../../helpers/HelperFunctions";
import { apiCall } from "../../utils/action";
import toast from "react-hot-toast";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import parse from "html-react-parser";
import Preloader from "../../helpers/Preloader";
import { useMediaQuery } from "@material-ui/core";
import { formatCost } from "../../utils/services";
// const data =[
//     {
//         "id": 1663932076229,
//         "Ids": null,
//         "url": "file:///data/user/0/com.palmtech.year2022/cache/mrousavy4173206803982464496.jpg",
//         "name": "5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "type": "image/png",
//         "edited": false,
//         "newUrl": "file:///data/user/0/com.palmtech.year2022/cache/5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "online": "online",
//         "filetype": "image",
//         "OriginUrl": null,
//         "onlineUrl": "https://reportwriters.s3.amazonaws.com/video_update%2FPalmTech%2Finspection_data%2Fbbe89236-280e-41f2-95df-62e34a03baa1%2F5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "orginName": "5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "thumbnail": null,
//         "description": null
//     },
//     {
//         "id": 1663932121765,
//         "Ids": null,
//         "url": "file:///data/user/0/com.palmtech.year2022/cache/VisionCamera-20220923_165145285184080937780203.mp4",
//         "name": "VisionCamera-20220923_165145285184080937780203.mp4",
//         "type": "video/mp4",
//         "edited": false,
//         "newUrl": "file:///data/user/0/com.palmtech.year2022/cache/VisionCamera-20220923_165145285184080937780203.mp4",
//         "online": "online",
//         "filetype": "video",
//         "OriginUrl": null,
//         "onlineUrl": "https://reportwriters.s3.amazonaws.com/video_update%2FPalmTech%2Finspection_data%2Fbbe89236-280e-41f2-95df-62e34a03baa1%2FVisionCamera-20220923_165145285184080937780203.mp4",
//         "orginName": "VisionCamera-20220923_165145285184080937780203.mp4",
//         "thumbnail": "https://reportwriters.s3.amazonaws.com/video_update%2FPalmTech%2Finspection_data%2Fbbe89236-280e-41f2-95df-62e34a03baa1%2F1663932122363.png",
//         "description": null,
//         "thumbnailName": "1663932122363.png"
//     },
//     {
//         "id": 1663932767876229,
//         "Ids": null,
//         "url": "file:///data/user/0/com.palmtech.year2022/cache/mrousavy4173206803982464496.jpg",
//         "name": "5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "type": "image/png",
//         "edited": false,
//         "newUrl": "file:///data/user/0/com.palmtech.year2022/cache/5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "online": "online",
//         "filetype": "image",
//         "OriginUrl": null,
//         "onlineUrl": "https://reportwriters.s3.amazonaws.com/video_update%2FPalmTech%2Finspection_data%2Fbbe89236-280e-41f2-95df-62e34a03baa1%2F5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "orginName": "5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "thumbnail": null,
//         "description": null
//     },
//     {
//         "id": 166397632076229,
//         "Ids": null,
//         "url": "file:///data/user/0/com.palmtech.year2022/cache/mrousavy4173206803982464496.jpg",
//         "name": "5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "type": "image/png",
//         "edited": false,
//         "newUrl": "file:///data/user/0/com.palmtech.year2022/cache/5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "online": "online",
//         "filetype": "image",
//         "OriginUrl": null,
//         "onlineUrl": "https://reportwriters.s3.amazonaws.com/video_update%2FPalmTech%2Finspection_data%2Fbbe89236-280e-41f2-95df-62e34a03baa1%2F5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "orginName": "5d91d017-2cab-4987-8918-7426bc6ff6f7.jpg",
//         "thumbnail": null,
//         "description": null
//     },
// ]

const Preview = () => {
  const [open, setOpen] = useState(false);
  // const handleClose = () => setOpen(false);
  const [index, setIndex] = useState(0);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [inspectionDetails, setInspectionDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const matches = useMediaQuery("(max-width:900px)");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let guidParam = searchParams.get("guid");
  let PhotosCount = 0;
  let VideosCount = 0;
  function convertFontTagsToInlineStyles(htmlString) {
    // Replace <font> tags with inline styles
    var convertedString = htmlString?.replace(/<font([^>]*)>/gi, function (match, p1) {
      var attributes = p1?.split(/\s+/);
      var styles = "";
      const fontSizeConverter = (val) => {
        if (val === "1") {
          return 10;
        } else if (val === "2") {
          return 13;
        } else if (val === "3") {
          return 16;
        } else if (val === "4") {
          return 18;
        } else if (val === "5") {
          return 24;
        } else if (val === "6") {
          return 32;
        } else if (val === "7") {
          return 48;
        } else return val;
      };
      // Convert font attributes to inline styles
      for (var i = 0; i < attributes?.length; i++) {
        var attribute = attributes[i]?.trim();
        var parts = attribute?.split("=");

        if (parts.length === 2) {
          var name = parts[0]?.toLowerCase();
          var value = parts[1]?.replace(/['"]/g, "");
          // eslint-disable-next-line
          switch (name) {
            case "color":
              styles += "color: " + value + "; ";
              break;
            case "size":
              styles += "font-size: " + fontSizeConverter(value) + "px; ";
              break;
          }
        }
      }

      return "<span style='" + styles + "'>";
    });
    convertedString = convertedString?.replace(/<\/font>/gi, "</span>")?.replace(/style="([^"]*)font-size:[^;"]*;?([^"]*)"/g, 'style="$1$2"');

    return convertedString;
  }

  const navigate = useNavigate();
  // let media = [];
  const temp = [];
  let count = 1;
  const componentRef = useRef();
  const handleClose = () => {
    // media = [];
    setMediaFiles([])
    setOpen(false);
  };
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  const handlePrint = () => window.print();
  const { selectedCount, totalCost } = checkTotalItemNPrice(reportData);
  const getReportData = (type) => {
    let data;
    type !== "noLoader" && setShowLoader(true);
    if (sessionStorage.getItem("guid") !== null) {
      data = { guid: sessionStorage.getItem("guid") };
      apiCall(
        (response) => {
          const { data, message, success } = response.data;
          if (success) {
            setReportData(data?.inspectiontemplates);
            setInspectionDetails(data.inspection_details);
            setShowLoader(false);
          } else {
            toast.error(message);
            setShowLoader(false);
          }
        },
        data,
        "GET_REPORT_DATA"
      );
    } else {
      if (guidParam !== null) {
        data = { guid: guidParam };
        apiCall(
          (response) => {
            const { data, message, success } = response.data;
            if (success) {
              setReportData(data?.inspectiontemplates);
              setInspectionDetails(data.inspection_details);
              setShowLoader(false);
            } else {
              toast.error(message);
              setShowLoader(false);
            }
          },
          data,
          "GET_REPORT_DATA"
        );
      } else {
        navigate("*");
      }
    }
  };
  useEffect(() => {
    getReportData("normalLoad");
  }, []);
  const openCarousel = (url, data, catIndex, lineIndex, line) => {
    console.log(data);
    let arr = [];
    if (data) {
      data?.data?.forEach((item) => {
        console.log("here");
        if (item?.type?.includes("image") && item?.online === "online") {
          arr.push({ url: item.onlineUrl, type: item.type, desc: item.description, cat: `${catIndex + 1}.${lineIndex + 1} ${line.prompt}` });
        } else if (item?.type?.includes("video")) {
          arr.push({ url: item.onlineUrl, type: item.type, thumb: item.thumbnail, desc: item.description, cat: `${catIndex + 1}.${lineIndex + 1} ${line.prompt}` });
        }
      });
    }
    setMediaFiles(arr);
    setOpen(true);
    setIndex(url);
  };
  return (
    <>
      <Preloader showPreloader={showLoader} />
      <Grid className="preview__container">
        <Grid className="preview__header">
          <div className="header__l" onClick={guidParam === null ? () => navigate("/repair-list") : undefined}>
            {guidParam === null && (
              <>
                <ArrowBackIcon />
                <span>Return to Build</span>
              </>
            )}
          </div>
          <div className="header__r" onClick={handlePrint}>
            <PrintIcon />
            <span>print this view</span>
          </div>
        </Grid>
        <Divider />
        <Grid className="preview__inner__container" ref={componentRef} id="printableArea">
          <Grid container className="request__details">
            <Grid item xs={12} sm={10} className="preview__property__details">
              <Typography>{moment(inspectionDetails?.start_time).format("MMM Do, YYYY")}</Typography>
              <Typography>Repair Request List</Typography>
              {/* <Typography>{inspectionDetails?.location_address}, {inspectionDetails?.location_city}, {inspectionDetails?.location_state} {inspectionDetails?.location_zip}</Typography> */}
              <Typography>{inspectionDetails?.address?.replace(/ ,/g, "")}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Paper className="preview__total">
                <div className="total__line">
                  <span>Items</span>
                  <span>{selectedCount}</span>
                </div>
                <Divider style={{ margin: "12px 0px" }} />
                <div className="total__line">
                  <span>Cost</span>
                  <span>${formatCost(totalCost)}</span>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Grid>
            {reportData
              ?.filter((temp) => temp.is_pdf_template !== 1)
              .map(
                (template, tempIndex) =>
                  checkRepairSelectionCategory(template?.categories) && (
                    <>
                      <Typography className="preview__template_title" sx={{ mt: "50px !important" }}>
                        {template.report_title ? template.report_title : template.name}
                      </Typography>
                      {template?.categories
                        ?.filter((item) => {
                          // Check if the key exists in the object
                          if (item.hasOwnProperty("is_pdf_template")) {
                            // Filter based on a condition (if key exists)
                            return item["is_pdf_template"] === 0; // Replace with your condition
                          } else {
                            // If the key doesn't exist, don't filter (include the item)
                            return true;
                          }
                        })
                        ?.filter((e) => e.is_inspection_details !== 1 && e?.is_inspection_details !== 2)
                        ?.map((category, catIndex) =>
                          category.is_deleted !== 1 ? (
                            checkRepairSelectionLine(category?.lines) ? (
                              <>
                                <Typography className="categories__title_preview">
                                  {count}. {category?.prompt !== null ? category?.prompt : "Untitled"}
                                </Typography>

                                {category?.lines?.filter((e) => e?.is_deleted === 0)?.map(
                                  (line, lineIndex) =>
                                    checkRepairSelectionComment(line?.comments) && (
                                      <>
                                        <Paper className="line-paper">
                                          <Grid container>
                                            <Grid item xs={12}>
                                              <Typography className="line-title__preview">
                                                {count}.{lineIndex + 1} {line.prompt}
                                              </Typography>
                                            </Grid>
                                            {line?.comments.map(
                                              (comment, commentIndex) =>
                                                comment.is_selected !== 0 &&
                                                comment?.comment_request_list[0]?.is_selected === 1 && (
                                                  <>
                                                    <Grid item sm={12} md={2} style={{ marginBottom: "15px", width: "100%" }}>
                                                      <Paper className="preview__line__detail__card">
                                                        <div className="preview__detail__head">
                                                          <span className="outer">
                                                            {" "}
                                                            <ChatIcon />
                                                            <span className="inner">Request Info</span>
                                                          </span>
                                                          <span>{comment?.comment_request_list[0]?.request_list_data.length !== 0 && comment?.comment_request_list[0]?.request_list_data[0].comment_request_list_pricing !== null ? `$ ${formatCost(comment?.comment_request_list[0]?.request_list_data[0].comment_request_list_pricing)}` : "$ 0"}</span>
                                                        </div>
                                                        <Typography>{comment?.comment_request_list[0]?.request_list_data.length !== 0 && comment?.comment_request_list[0]?.request_list_data[0].comment_request_list_info !== null ? `${comment?.comment_request_list[0]?.request_list_data[0].comment_request_list_info}` : ""}</Typography>
                                                      </Paper>
                                                    </Grid>
                                                    <Grid item sm={12} md={10} className="preview__right">
                                                      <Grid container sx={{ mb: "17px" }} className="comment__content__preview">
                                                        <Grid item xs={12} md={6} lg={12}>
                                                          <Typography style={{ position: "relative", textAlign: "justify" }} className={`comment__title ${matches ? "comment__mobile" : ""}`}>
                                                            <span>
                                                              {/* <span className="circle_icon" style={{ marginRight: "5px" }}>
                                                              <CircleIcon sx={{ position: "absolute", top: 10, marginRight: "5px" }} />
                                                            </span> */}
                                                              {comment?.is_mannual_prompt === 0 || comment?.prompt === "null" || comment?.prompt === null || comment?.prompt === "" ? comment?.recommendation ? <div className="html_render" dangerouslySetInnerHTML={{ __html: convertFontTagsToInlineStyles(`${comment?.recommendation}`) }}></div> : "" : comment?.is_mannual_prompt === 1 && comment?.prompt ? <p>{comment?.prompt}</p> : comment?.prompt ? <p>{comment?.prompt}</p> : ""}
                                                            </span>
                                                            {/* <span>{comment?.prompt}</span> */}
                                                          </Typography>
                                                        </Grid>
                                                        {/* <Grid item xs={12} md={6} lg={3} className="comment__rating__content__preview">
                                                        {comment?.comment_input_controls
                                                          ?.find((comment) => comment.type === "Rating")
                                                          .data?.map((rating) => (
                                                            <Typography className="comment__rating__preview" key={rating.Id}>
                                                              <span className="coment_rating_name" style={{ border: `1px solid ${rating.Color}` }}>
                                                                <span style={{ background: `${rating.Color}` }} className="comment_rating_color">
                                                                  {rating.include_in_summary === "1" || rating?.include_in_summary === 1 ? <FlagIcon /> : ""}
                                                                </span>
                                                                {rating.Name}
                                                              </span>
                                                            </Typography>
                                                          ))}
                                                      </Grid> */}
                                                        <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap" }}>
                                                          {/* <div className="comment_rating__inner"> */}
                                                          {comment?.comment_input_controls
                                                            ?.find((comment) => comment.type === "Rating")
                                                            .data.map((rating) => (
                                                              <Typography className="comment__rating" key={rating.Id}>
                                                                <span className="coment_rating_name" style={{ border: `1px solid ${rating.Color}` }}>
                                                                  <span style={{ background: `${rating.Color}` }} className="comment_rating_color">
                                                                    {rating.include_in_summary === "1" || rating?.include_in_summary === 1 ? <FlagIcon /> : ""}
                                                                  </span>
                                                                  {rating.Name}
                                                                </span>
                                                              </Typography>
                                                            ))}
                                                          {VideosCount > 0 || PhotosCount > 0 ? (
                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                              <div style={{ width: "5px", borderLeft: "1px solid #CCCCCC", height: "23px" }}></div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {/* </div> */}
                                                          {PhotosCount !== 0 ? (
                                                            <Button variant="text" startIcon={<InsertPhotoRoundedIcon />} disableRipple className="count_button">
                                                              {PhotosCount} {PhotosCount > 1 ? "photos" : "photo"}
                                                            </Button>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {VideosCount !== 0 ? (
                                                            <Button variant="none" startIcon={<VideocamRoundedIcon />} disableRipple className="count_button">
                                                              {VideosCount} {VideosCount > 1 ? "videos" : "video"}
                                                            </Button>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                          <Collapse in={true}>
                                                            <Grid container>{comment?.is_mannual_prompt === 0 || comment?.prompt === "null" || comment?.prompt === null || comment?.prompt === "" ? "" : <>{comment?.recommendation ? <p dangerouslySetInnerHTML={{ __html: convertFontTagsToInlineStyles(`${comment?.recommendation}`) }}></p> : ""}</>}</Grid>
                                                            {/* {comment?.comment_input_controls?.find((comment) => comment.type === "Location") !== null && comment?.comment_input_controls?.find((comment) => comment.type === "Location") !== undefined && comment?.comment_input_controls?.find((comment) => comment.type === "Location")?.data?.length !== 0 ? (
                                                            <Typography className="comment__location">
                                                              <MyLocationIcon />
                                                              <span>{comment?.comment_input_controls?.find((comment) => comment.type === "Location")?.data}</span>
                                                            </Typography>
                                                          ) : (
                                                            ""
                                                          )} */}
                                                            <Grid container sx={{ mb: 1, display: "inline", mt: -1 }}>
                                                              {comment?.comment_input_controls?.map((lineValue) => {
                                                                return lineValue.type === "Location" && lineValue.data !== null && lineValue.data.length !== 0 ? (
                                                                  <Typography className="line-desc">
                                                                    <span className="capitalise">{lineValue?.prompt} - </span>
                                                                    {typeof lineValue.data === "string" ? (
                                                                      <span className="line-value">{lineValue.data}</span>
                                                                    ) : typeof lineValue.data === "object" ? (
                                                                      lineValue?.data?.map((item, index) => {
                                                                        return (
                                                                          <>
                                                                            <span className="line-value">
                                                                              {item}
                                                                              {index < lineValue?.data?.length - 1 && ", "}
                                                                            </span>
                                                                          </>
                                                                        );
                                                                      })
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </Typography>
                                                                ) : (
                                                                  ""
                                                                );
                                                              })}
                                                            </Grid>
                                                            <Grid container sx={{ p: "11px 20px 8px 20px" }} spacing={1}>
                                                              {/* <MediaImage /> */}
                                                              {/* {comment_input_controls.map((value) => { */}
                                                              {/* return value.type === 'Media' */}
                                                              {/* ? value. */}
                                                              {/* {data.map((value) => {
                                                                return (
                                                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                                                        <div style={{ display: 'none' }}>{value.filetype === 'image' ? media.push({ type: 'image', fileUrl: value.onlineUrl }) : media.push({ type: 'video', fileUrl: value.onlineUrl })}</div>
                                                                        <img
                                                                            onClick={() => {
                                                                                setOpen(true);
                                                                                setIndex(index);
                                                                            }}
                                                                            style={{ width: '100%', height: '180px', cursor: 'pointer', objectFit: 'scale-down', backgroundColor: '#FAFAFA' }}
                                                                            src={value.filetype === 'image' ? value.onlineUrl : value.thumbnail}
                                                                            alt="image"></img>
                                                                    </Grid>
                                                                );
                                                            })} */}
                                                              {/* : ''; */}
                                                              {/* })} */}

                                                              {/* ================================== */}
                                                              {/* {comment.comment_input_controls.map((value) => {
                                                       return value.type === 'Media' 
                                                             ? value.data?.map((value) => {
                                                                return (
                                                                    <Grid item xs={12} sm={6} md={3} lg={3}>
                                                                        <div style={{ display: 'none' }}>{value.type === "image/png" ? media.push({ type: 'image', fileUrl: value.onlineUrl }) : media.push({ type: 'video', fileUrl: value.onlineUrl })}</div>
                                                                        <img
                                                                            onClick={() => {
                                                                                setOpen(true);
                                                                                setIndex(index);
                                                                            }}
                                                                            style={{ width: '100%', height: '180px', cursor: 'pointer', objectFit: 'scale-down', backgroundColor: '#FAFAFA' }}
                                                                            src={value.type === "image/png" ? value.onlineUrl : value.thumbnail}
                                                                            alt="image"></img>
                                                                    </Grid>
                                                                );
                                                            })
                                                             : ''
                                                     })}  */}
                                                              {comment?.comment_input_controls
                                                                ?.filter((value) => value?.type === "Media")
                                                                ?.map((mediaValue) => {
                                                                  return mediaValue?.data.length > 0
                                                                    ? mediaValue?.data?.map((mediaVal) => {
                                                                        return mediaVal.type?.includes("image") && mediaVal.online === "online" ? (
                                                                          <Grid item xs={12} sm={6} md={3} lg={3} className="image-container">
                                                                            {/* <div className="lib-div">{media.push({ url: mediaVal.onlineUrl, type: mediaVal.type, desc: mediaVal.description, cat: `${catIndex + 1}.${lineIndex + 1} ${line.prompt}` })}</div> */}
                                                                            <img onClick={() => openCarousel(mediaVal.onlineUrl, mediaValue, catIndex, lineIndex, line)} className="media-image-container" src={mediaVal?.thumbnail ? mediaVal?.thumbnail : mediaVal.onlineUrl} alt="in"></img>
                                                                            <p className="media-description">{mediaVal?.description}</p>
                                                                          </Grid>
                                                                        ) : mediaVal.type?.includes("video") ? (
                                                                          <Grid item xs={12} sm={6} md={3} lg={3} className="video-container">
                                                                            <Grid container>
                                                                              <Grid item xs={12} sx={{ position: "relative" }}>
                                                                                {/* <div className="lib-div">{media.push({ url: mediaVal.onlineUrl, type: mediaVal.type, thumb: mediaVal.thumbnail, desc: mediaVal.description, cat: `${catIndex + 1}.${lineIndex + 1} ${line.prompt}` })}</div> */}
                                                                                <img onClick={() => openCarousel(mediaVal.onlineUrl, mediaValue)} className="media-image-container" src={mediaVal.thumbnail} alt="in"></img>
                                                                                <PlayCircleIcon className="media-play-icon" onClick={() => openCarousel(mediaVal.onlineUrl)} />
                                                                              </Grid>
                                                                              <Grid item xs={12}>
                                                                                <p className="media-description" style={{ textAlign: mediaVal?.description?.length > 100 ? "justify" : "center" }}>
                                                                                  {mediaVal?.description}
                                                                                </p>
                                                                              </Grid>
                                                                            </Grid>
                                                                          </Grid>
                                                                        ) : (
                                                                          ""
                                                                        );
                                                                      })
                                                                    : "";
                                                                })}

                                                              {/* ================================== */}
                                                            </Grid>
                                                          </Collapse>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </>
                                                )
                                            )}
                                          </Grid>
                                        </Paper>
                                      </>
                                    )
                                )}
                                <div className="lib-div">{(temp.length = 0)}</div>
                                <span style={{ display: "none" }}>
                                  {category?.prompt} {(count = count + 1)}
                                </span>
                              </>
                            ) : (
                              <span style={{ display: "none" }}>
                                {category?.prompt}-{(count = count + 1)}
                              </span>
                            )
                          ) : (
                            ""
                          )
                        )}
                    </>
                  )
              )}
            <span style={{ display: "none" }}>{(count = 1)}</span>
          </Grid>
        </Grid>
      </Grid>
      {/* <Modal open={open} className="carousel-modal">
        <Box className="carousel-modal-layout" sx={{ p: '0px !important' }}>
            <Grid container>
                <Grid item xs={12}>
                    <CloseIcon className="carousel-close-icon" onClick={handleClose} />
                </Grid>
                <Grid item xs={12}>
                    <Carousel data={media} index={index} />
                </Grid>
            </Grid>
        </Box>
    </Modal> */}

      <ScrollToTop smooth className="scroll__to__top" component={<ArrowUpwardIcon />} />
      <ImageCarousel images={mediaFiles} index={index} open={open} handleClose={handleClose} />
    </>
  );
};

export default Preview;
