import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

export const ROUTES = {
  HOME: "/",
  PDF: "/pdf",
  FALLBACK: "*",
  MEDIA: "/media",
  MAPVIEW: "/mapview",
  SUMMARY: "/summary",
  DELIVERY: "/delivery",
  DELIVERYV1: "/delivery_v1",
  DELIVERYV2: "/delivery_v2",
  ATTACHMENTS: "/attachments",
  REQUEST_LIST: "/repair-list",
  PREVIEW: "/preview",
  SHARED_PREVIEW: "/preview-report",
  // GENPDF:"/genpdf",
};

export const colors = {
  SCHEDULED: "#1F2F9C",
  SCHEDULED_BG: "#C7DBF6",
  IN_PROGRESS: "#CB8530",
  IN_PROGRESS_BG: "#FFF9C8",
  COMPLETE: "#5A1F9C",
  COMPLETE_BG: "#F1DDFF",
  PUBLISHED: "#1F9C7B",
  PUBLISHED_BG: "#C7F6E0",
  DRAFT: "#F35F01",
  DRAFT_BG: "#FFECD2",
  CANCLED: "#E30303",
  CANCLED_BG: "#FFD7D2",
  ERROR: "#611A15",
  ERROR_BG: "#fdeceb",
  ERROR_ICON: "#F44336",
};

export const menuItems = ["full-report", "summary", "media", "pdf", "attachments"];
export const narrativeMenuItems = ["summary", "full-report", "media", "pdf", "attachments"];

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
