import { Grid, Paper, Typography, Divider } from "@mui/material";

const PropertyDetailsLayout = (data) => {
  const { propertyData } = data;
  return (
    <Paper className="inspection-details-paper">
      <Grid container sx={{ p: "24px" }}>
        <Grid item xs={12}>
          <Typography className="details-heading">PROPERTY</Typography>
        </Grid>
        {propertyData?.total_area ? (
          <>
            <Grid item xs={6} sx={{ mt: "24px !important" }} className="details-phone-title">
              Square feet
            </Grid>
            <Grid item xs={6} sx={{ mt: "24px !important" }} className="details-phone-value">
              {propertyData?.total_area}
            </Grid>
          </>
        ) : (
          ""
        )}
        {propertyData?.year_built ? (
          <>
            <Grid item xs={12}>
              <Divider className="inspection-details-divider" />
            </Grid>
            <Grid item xs={6} className="details-phone-title">
              Year built
            </Grid>
            <Grid item xs={6} className="details-phone-value">
              {propertyData?.year_built}
            </Grid>
          </>
        ) : (
          ""
        )}
        {propertyData?.total_bedrooms ? (
          <>
            <Grid item xs={12}>
              <Divider className="inspection-details-divider" />
            </Grid>
            <Grid item xs={6} className="details-phone-title">
              Bedrooms
            </Grid>
            <Grid item xs={6} className="details-phone-value">
              {propertyData?.total_bedrooms}
            </Grid>
          </>
        ) : (
          ""
        )}
        {propertyData?.total_bathrooms ? (
          <>
            <Grid item xs={12}>
              <Divider className="inspection-details-divider" />
            </Grid>
            <Grid item xs={6} className="details-phone-title">
              Bathrooms
            </Grid>
            <Grid item xs={6} className="details-phone-value">
              {propertyData?.total_bathrooms}
            </Grid>
            <Grid item xs={12}>
              <Divider className="inspection-details-divider" />
            </Grid>
          </>
        ) : (
          ""
        )}
        {propertyData?.type ? (
          <>
            <Grid item xs={6} className="details-phone-title">
              Type
            </Grid>
            <Grid item xs={6} className="details-phone-value">
              {propertyData?.type?.replace(/_/g, " ")}
            </Grid>
            <Grid item xs={12}>
              <Divider className="inspection-details-divider" />
            </Grid>
          </>
        ) : (
          ""
        )}
        {propertyData?.total_garage ? (
          <>
            <Grid item xs={6} className="details-phone-title">
              Garage
            </Grid>
            <Grid item xs={6} className="details-phone-value">
              {propertyData?.total_garage}
            </Grid>
          </>
        ) : (
          ""
        )}
      </Grid>
    </Paper>
  );
};
export default PropertyDetailsLayout;
