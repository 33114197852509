import { FormControl, Grid, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ChatIcon from "@mui/icons-material/Chat";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import OutlinedInput from "@mui/material/OutlinedInput";
import { motion } from "framer-motion";
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { onKeyPressed } from "../../helpers/HelperFunctions";
import { useMediaQuery } from "@material-ui/core";

const RequestInfoInputCard = ({ comment,commentId,setComment,cost,setCost,localRepairSelection,setLocalRepairSelection,handleClickAway }) => {
  const matches = useMediaQuery("(min-width:900px)");
  return (
    <motion.div  animate={matches?
      localRepairSelection?
        { x: [30, 0, 0],y:[-10,0,0], opacity: 1}:{ x: [0, 30, 50],y:[-10,0,0], opacity: 0,}
        :
        localRepairSelection?{display:'block'}:{ display:'none'}
        // { opacity: 1}:{  opacity: 0}
    }
                transition={{
                    duration: 0.5,
                    delay  : 0,
                    ease: [0.2, 0.3, 0.3, 0.4],
                    // default: { ease: "linear" }
                }}
                initial={matches?{ opacity: 0.2 }:{ opacity: 1 }} className="repair__comment__info">
      <Card sx={{ minWidth: 380 }} >
        <div className="horizontal__toggle__arrow__left" onClick={()=>{setLocalRepairSelection(false);handleClickAway(commentId)}}><ExpandCircleDownOutlinedIcon /></div>
        <CardContent className="p_comment">
          <Typography gutterBottom className="repair__title">
            Request Info
          </Typography>
          <Box className="repair__comment__box">
            <Grid container className="rl__input">
              <Grid item xs={11} className="rl__input_textarea">
                <TextareaAutosize
                  aria-label="comment-text"
                  minRows={3}
                  placeholder="Add a comment or request here in response to this inspection point. Use the $ to request a credit."
                  style={{ width: "100%",resize:'none' }}
                  value={comment}
                  onChange={e=>setComment(e.target.value)}
                  autoComplete='off'
                />
              </Grid>
              <Grid item xs={1} className="rl__input__icon" onClick={()=>{setLocalRepairSelection(false);handleClickAway(commentId)}}>
                <ChatIcon />
              </Grid>
            </Grid>
            <Grid container className="rl__input" style={{ marginTop: "5px" }}>
              <Grid item xs={11} className="rl__input_textarea">
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <OutlinedInput
                  className="cost__input"
                    id="repair_amount"
                    size="small"
                    value={cost}
                    type='number'
                    onKeyDown={onKeyPressed}
                    onChange={e=>setCost(e.target.value)}
                    autoComplete='off'
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "21",
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                xs={1}
                className="rl__input__icon"
                style={{ paddingTop: "5px" }}
                onClick={()=>{setLocalRepairSelection(false);handleClickAway(commentId)}}
              >
                <AttachMoneyIcon />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default RequestInfoInputCard;
