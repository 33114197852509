import { Grid } from '@material-ui/core'
import PTLogo from '../../../../assets/images/pt_horizontal.png'

const DeliveryFooterv1 = () => {
  return (
    <Grid container className="rdp_footer">
        <div className="delivery-footer-container_v1">
            <p className="powered-by_v1">Powered by</p>
            <img src={PTLogo}/>
        </div>
    </Grid>
  )
}

export default DeliveryFooterv1