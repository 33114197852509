import { Grid } from "@material-ui/core";

const DeliveryDetailsMobilev1 = (props) => {
  const { details,coverPhoto } = props;
  return (
    <Grid container className="mobile_details">
      <Grid
        item
        xs={12}
        style={{ display: "flex", marginLeft: "20px", alignItems: "center" }}
      >
         {coverPhoto ? <img src={coverPhoto} alt="property" className="delivery_property_image" /> : ""}  
        <div className="delivery-details-container">
          <p className="delivery-details-addressv1_mobile">
            {details?.location_address}{details?.location_address_2 !== null && details?.location_address_2 !== ''?`, ${details?.location_address_2},`:''} <br /> {details?.location_city},{" "}
            {details?.location_state} {details?.location_zip}
          </p>
        </div>
      </Grid>
    </Grid>
  );
};

export default DeliveryDetailsMobilev1;
