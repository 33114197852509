import React from "react";
import moment from "moment";
import { Grid } from "@mui/material";
const DeliveryDetails = (props) => {
  const { details } = props;
  return (
    <div className="delivery-header-details-container">
      <Grid container>
        <Grid item xs={12} md={6} style={{ display: "flex" }}>
          <div className="delivery-details-container">
            <p className="delivery-details-title" style={{ fontSize: "11px" }}>
              Inspection address
            </p>
            <p className="delivery-details-address">
              {details?.location_address}, {details?.location_city}, {details?.location_state} {details?.location_zip}
            </p>
            <p className="delivery-details-date">{moment(details?.start_time).format("MMM D, YYYY")}</p>
          </div>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Grid container direction="row" spacing={1}>
            {details?.orderinspector?.map((value, index) => {
              return (
                <Grid item xs={6} lg={4}>
                  {value?.inspector?.profile_pic !== null ? <img key={index} src={value?.inspector?.profile_pic} alt="insp" className="delivery-agent-pic" /> : ""}
                  <div className="delivery-details-container" key={index}>
                    <p className="delivery-details-title" style={{ fontSize: "11px" }}>
                      Inspector
                    </p>
                    <p className="delivery-details-address">
                      {value?.inspector?.first_name} {value?.inspector?.last_name}
                    </p>
                    <p className="delivery-details-date" style={{ fontSize: "12px" }}>{value?.inspector?.phone}</p>
                  </div>
                </Grid>
              );
            })}
            {details?.orderagent?.map((value, index) => {
              return (
                <Grid item xs={6} lg={4}>
                  {value?.agent?.profile_pic !== null ? <img alt="agent" src={value?.agent?.profile_pic} className="delivery-agent-pic" /> : ""}
                  <div className="delivery-details-container">
                    <p className="delivery-details-title" style={{ fontSize: "11px" }}>
                      {value?.agent_type === 1 ? "Buyer" : "Seller"} agent
                    </p>
                    <p className="delivery-details-address">
                      {value?.agent?.first_name} {value?.agent?.last_name}
                    </p>
                    <p className="delivery-details-date" style={{ fontSize: "12px" }}>{value?.agent?.phone}</p>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default DeliveryDetails;
