import { useState } from "react";
import ContactUs from "../contactUs/ContactUs";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";

const InspectorDetailsLayout = (props) => {
  const { inspectorDetails, company, isISN } = props;
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [inspEmail, setInspEmail] = useState("");
  const handleOpen = (email) => {
    setInspEmail(email);
    setOpen(true);
  };
  return (
    <>
      <ContactUs isISN={isISN} open={open} handleClose={handleClose} inspEmail={inspEmail} />
      {inspectorDetails?.map((value, index) => {
        const address = `${value?.inspector?.location_address_1}, ${value?.inspector?.location_address_2 ? value?.inspector?.location_address_2 : ""}, ${value?.inspector?.city}, ${value?.inspector?.state} ${value?.inspector?.zip}`;
        return (
          <Paper className="inspection-details-paper" sx={{ mb: 2 }}>
            <Grid container sx={{ p: "24px" }} key={index}>
              <Grid item xs={12}>
                <Typography className="details-heading">INSPECTOR {inspectorDetails?.length > 1 ? `${index + 1}` : ""}</Typography>
              </Grid>
              <Grid item xs={12} className="details-inpector-name">
                {value?.inspector?.first_name} {value?.inspector?.last_name}
              </Grid>
              {!company?.name ? (
                ""
              ) : (
                <Grid item xs={12} className="details-inspection-name">
                  {company?.name}
                </Grid>
              )}
              <Grid item xs={8} className="details-address">
                {address?.replace(/null,/g, "")?.replace(/null/g, "")?.replace(/ ,/g, "")}
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={12}>
                <Divider className="inspection-details-divider" />
              </Grid>
              {!value?.inspector?.phone ? (
                ""
              ) : (
                <>
                  <Grid item xs={3} className="details-phone-title">
                    Phone
                  </Grid>
                  <Grid item xs={9} className="details-phone-value">
                    {value?.inspector?.phone}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className="inspection-details-divider" />
                  </Grid>
                </>
              )}
              <Grid item xs={4} className="details-phone-title">
                Email
              </Grid>
              <Grid item xs={8} className="details-email-value">
                {value?.inspector?.email}
              </Grid>
              <Grid item xs={12}>
                <Divider className="inspection-details-divider" />
              </Grid>
              {!company?.website ? (
                ""
              ) : (
                <>
                  <Grid item xs={4} className="details-phone-title">
                    Website
                  </Grid>
                  <Grid item xs={8} className="details-email-value">
                    <a href={company?.website} target="_blank" rel="noopener noreferrer">
                      {company?.website}
                    </a>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Button className="details-sendusamessage" variant="none" onClick={() => handleOpen(value?.inspector?.email)}>
                  SEND US A MESSAGE
                </Button>
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </>
  );
};
export default InspectorDetailsLayout;
