import { Button, Grid } from "@material-ui/core"

const DeliveryIRv1 = ({coverPhoto}) => {
  return (
    <Grid container className="delivery__grid">    
    <Grid item xs={2} sm={1} md={1} > 
        <div className="oval">
            <svg width="39" height="35" viewBox="0 0 39 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 34.375V30.625H15.75V26.875H4.5C3.46875 26.875 2.58625 26.5081 1.8525 25.7744C1.1175 25.0394 0.75 24.1563 0.75 23.125V4.375C0.75 3.34375 1.1175 2.46062 1.8525 1.72562C2.58625 0.991875 3.46875 0.625 4.5 0.625H34.5C35.5313 0.625 36.4144 0.991875 37.1494 1.72562C37.8831 2.46062 38.25 3.34375 38.25 4.375V23.125C38.25 24.1563 37.8831 25.0394 37.1494 25.7744C36.4144 26.5081 35.5313 26.875 34.5 26.875H23.25V30.625H27V34.375H12ZM4.5 23.125H34.5V4.375H4.5V23.125Z" fill="white" />
            </svg>
        </div>
    </Grid>
    <Grid item xs={9} sm={6} md={6} className="pl-20">
    <div className="pa_title" style={{marginTop:'10px'}}><span>View your interactive report</span></div>
            <div className="pir_subtitle">
                <span>Your interactive report shows you what's important with a clean layout, clearly separated ratings and comments, and easy navigation. Check out the repair request tool, which allows you to easily create an itemized list of requested fixes with a cost estimate.</span>
            </div>
            <Button className="view_report_btn" variant="none" onClick={() => window.open(`${window.location.origin}/?guid=${sessionStorage.getItem("guid")}`, "_blank")} >
            View interactive Report
            </Button>
    </Grid>
    <Grid item xs={1} sm={5} md={5} style={{display:'flex',justifyContent:'end',alignItems:'center'}} className="dashboard_image" >   
    {coverPhoto ? <img alt="cover" className="delivery-cover-image" src={coverPhoto}></img> : ""}
    </Grid>
    </Grid>
  )
}

export default DeliveryIRv1