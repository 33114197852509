import { Button, Typography } from "@mui/material";
import { Divider } from "@material-ui/core";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const Narrativeservice = ({ orderCharge, orderDiscount, orderService, paymentDetails }) => {
    const Recipts = [];
    const AmountPaid = [];
    const AmountDiscount = [];

    paymentDetails?.forEach((e) => {
        Recipts.push(e.receipt_url);
        if (e.is_paid === 1) AmountPaid.push(e.amount_paid);
    });

    orderDiscount?.forEach((e) => {
        AmountDiscount.push(e.discount);
    });

    const ServiceSum = orderService?.reduce((accumulator, currentObject) => {
        return accumulator + currentObject?.service?.amount;
    }, 0);
    const ChargeSum = orderCharge?.reduce((accumulator, currentObject) => {
        return accumulator + currentObject?.price;
    }, 0);
    const DiscountSum = orderDiscount?.reduce((accumulator, currentObject) => {
        return accumulator + currentObject?.discount;
    }, 0);
    const AmountPaidSum = paymentDetails?.reduce((accumulator, currentItem) => {
        if (currentItem?.is_paid === 1) {
            return accumulator + currentItem.amount_paid;
        }
        return accumulator;
    }, 0);

    let TotalAmount = ServiceSum + ChargeSum - DiscountSum;
    let Due = TotalAmount - AmountPaidSum;

    return <>
        <Typography className="narrative-details-heading-small">SERVICES</Typography>
        <Divider className="narrative-add-info-divider" />
        <div>
            {orderService?.map((value, index) => {
                return <div className="service_container" key={index}>
                    <Typography className="narrative-service-text">{value.service.name}</Typography><Typography className="service_amount_text">${value.service.amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                </div>
            })}
            {orderCharge?.map((value, index) => {
                return <div className="service_container" key={index}>
                    <Typography className="narrative-service-text">{value.description}</Typography><Typography className="service_amount_text">${value.price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                </div>
            })}
            {orderDiscount?.map((value, index) => {
                return <div className="service_container" key={index}>
                    <Typography className="narrative-service-text">{value.description}</Typography><Typography className="service_amount_text"> - ${value.discount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                </div>
            })}

        </div>
        <div>
            <div className="addition_info_container">
                <Typography className="narrative-details-bold-total">Total</Typography><Typography className="narrative-details-bold">${TotalAmount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
            </div>
            <div className="addition_info_container">
                <Typography className="narrative-service-text">Paid</Typography><Typography className="service_amount_text_due">${AmountPaidSum?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
            </div>
            <div className="addition_info_container">
                <Typography className="narrative-service-text">Due</Typography><Typography className="narrative-details-bold">${Due?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
            </div>
        </div>
        {/* PALM 12072 */}
        {/* <Button className="narrative-details-invoice" disableRipple variant="none" endIcon={<ArrowForwardIosIcon className="narrative-arrow-icon" />} >
            Invoice
        </Button> */}
    </>
}

export default Narrativeservice;