import parse from "html-react-parser";
import { Typography, Grid } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import "../../../../assets/styles/containers/_narrativeReportContent.scss";
import NarrativeSummary from "./NarrativeSummary";

const NarrativeReportContent = ({ categoriesData, summaryData }) => {
    let PhotoCount = 0;
    let VideoCount = 0;
    const addCaptionForMedia = (type, caption) => {
        if (type?.includes("video")) {
            VideoCount++;
            return <div className="media-caption-text">Video {VideoCount}{caption ? ": " + caption : ""}</div>;
        } else if (type?.includes("image")) {
            PhotoCount++;
            return <div className="media-caption-text">Photo {PhotoCount}{caption ? ": " + caption : ""}</div>;
        }
        return null; // Return null if the type is neither video nor image
    };
    return <Grid container className="narrative-report-content-container">
        {(
            categoriesData !== undefined &&
            categoriesData
                ?.filter((item) => item?.is_narrative_template === 1)
                ?.map((template, templateIndex) => {
                    return <>
                        <Grid item xs={12}>
                            <div key={templateIndex}>
                                <Typography className="narrative_template_title" >
                                    {template.report_title ? template.report_title : template.name}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sx={{ p: "30px 20px 20px 20px" }}>
                            <NarrativeSummary summaryData={summaryData?.filter((e) => e?.templateId === template?.template_id)} />
                            <div className="narrative-section-heading">
                                Report
                            </div>
                            {template?.categories?.filter((e) => e?.is_inspection_details === 0 && e?.is_deleted !== 1)?.map((category, categoryIndex) => {
                                return <Grid item xs={12} id={`${category?.prompt}_${category?.category_id}`} key={categoryIndex}>
                                    {category?.lines?.filter((e) => e?.is_deleted !== 1)?.length > 0 && category?.introductory_text !== null && category?.lines?.some((line) => line?.comments?.filter((e) => e?.is_deleted !== 1)?.length > 0) ? (
                                        <div className="narrative-category-heading">
                                            {categoryIndex + 1}. {category?.prompt !== null ? category?.prompt : ""}
                                        </div>
                                    ) : ""}
                                    {category?.introductory_text && category?.lines?.some((line) => line?.comments?.filter((e) => e?.is_deleted !== 1)?.length > 0) ? (
                                        <Grid item xs={12} sx={{ p: "10px 0px 10px 0px" }}>
                                            {parse(category?.introductory_text?.replace(/Â/g, ''))}
                                        </Grid>
                                    ) : ""}
                                    {category?.lines?.filter((e) => e?.is_deleted !== 1)?.map((line) => {
                                        return <Grid item xs={12} sx={{ pt: 1 }} >
                                            {line?.comments?.filter((e) => e?.is_deleted !== 1)?.map((comment) => {
                                                return <>
                                                    <Grid item xs={12}>
                                                        {comment?.comment_text && parse(comment?.comment_text?.replace(/Â/g, ''))}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1} sx={{ mt: "15px" }}>
                                                            {comment?.comment_input_controls?.filter((e) => e?.type === 'Media')?.map((commentMedia) => {
                                                                return commentMedia?.data?.map((media) => {
                                                                    return <Grid item xs={6} sx={{ position: "relative", mb: 3 }}>
                                                                        <div style={{ display: "block" }}>
                                                                            <img src={media?.type?.includes("image") ? (media.thumbnail ? media.thumbnail : media.onlineUrl) : media.thumbnail} alt="thumbnail" className="narrative-media-container" />
                                                                            {media?.type?.includes("video") ? (
                                                                                <PlayCircleIcon
                                                                                    className="narrative-media-play-icon"
                                                                                />
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {addCaptionForMedia(media?.type, media?.description)}
                                                                        </div>
                                                                    </Grid>
                                                                })
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            })}
                                        </Grid>
                                    })}
                                </Grid>
                            })}
                        </Grid>
                    </>
                })
        )}
    </Grid>
}

export default NarrativeReportContent;