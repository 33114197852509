import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    // backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
    zIndex: 99999,
  },
}));

export default function Preloader(props) {
  const classes = useStyles();
  return (
    <div>
      {props.showPreloader ? (
        <>
          <div className={classes.overlay} />
          <Backdrop sx={{ color: "#fff", zIndex: "9999" }} open={true}>
            <Grid container sx={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
              <Grid item xs={12}>
                <CircularProgress color="inherit" />
              </Grid>
              <Grid item xs={12}>
                {props?.isPDFLoader ? (
                  <p className="loading_text" style={{ color: "#fff !important" }}>
                    PDF generation is in progress. Please wait for a while
                  </p>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Backdrop>
        </>
      ) : (
        ""
      )}
    </div>
  );
}