//file imports
import { Preface } from "./Preface";
import { InspectionDetails } from "../InspectionDetails";
import { CoverPageNarrative } from "./CoverPageNarrative";
import { contentStyles, commonStyles } from "../PDFStyles";
import { Text, Document, View } from "@react-pdf/renderer";
import { NarrativeSummaryPDF } from "./NarrativeSummaryPDF";
import { NarrativeFullReportPDF } from "./NarrativeFullReportPDF";

const year = new Date();
const Producer = "Home Inspection";
const Subject = "Home Inspection Report";
const CompanyTag = `Copyright © 1998-${year.getFullYear()}`;

export const Footer = ({ isISN }) => (
    <View style={contentStyles.footerContainer} fixed>
        <View style={commonStyles.flexContainer}>{isISN ? "" : <Text style={contentStyles.footerLeft}>{CompanyTag}</Text>}</View>
        <View style={commonStyles.flexContainer}>
            <Text style={contentStyles.footerRight} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
        </View>
    </View>
);

export const HomeInspectionReportNarrative = ({ data, colorCode, isISN, isNarrative }) => {
    const { addtional_information, attachments, report_title, inspection_details, cover_photo, company_html_report_setting, inspectiontemplates, summaryDataNarrativeTemplate } = data;
    let color = '#D31145';
    return (
        <Document subject={Subject} creator={CompanyTag} producer={Producer}>
            {/* Cover Page */}
            <CoverPageNarrative />
            {/* Inspection Details Page */}
            <InspectionDetails isNarrative={isNarrative} isISN={isISN} inspection_details={inspection_details} color={color} company_html_report_setting={company_html_report_setting} addtional_information={addtional_information} colorCode={colorCode} />
            {/* Preface - comment for now as demo feedback */}
            {/* <Preface isNarrative={isNarrative} isISN={isISN} inspection_details={inspection_details} color={color} company_html_report_setting={company_html_report_setting} addtional_information={addtional_information} colorCode={colorCode} /> */}
            {/* Summary */}
            {summaryDataNarrativeTemplate?.filter((e) => e?.is_deleted !== 1)?.length > 0 && summaryDataNarrativeTemplate !== null ? <NarrativeSummaryPDF summaryDataNarrativeTemplate={summaryDataNarrativeTemplate} isNarrative={isNarrative} isISN={isISN} inspection_details={inspection_details} color={color} company_html_report_setting={company_html_report_setting} addtional_information={addtional_information} colorCode={colorCode} /> : ""}
            {/* Full Report */}
            <NarrativeFullReportPDF inspectiontemplates={inspectiontemplates} summaryDataNarrativeTemplate={summaryDataNarrativeTemplate} isNarrative={isNarrative} isISN={isISN} inspection_details={inspection_details} color={color} company_html_report_setting={company_html_report_setting} addtional_information={addtional_information} colorCode={colorCode} />
        </Document>
    );
};
