import { useState } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Card, CardActions, Grid, Switch } from "@mui/material";
import RepairTotalCostCard from "../../common/collapse/RepairTotalCostCard";
import { checkTotalItemNPrice, findRatingCount, lineRepairDetails } from "../../helpers/HelperFunctions";

const drawerBleeding = 150;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.mode === "light" ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function FilterMobileSection(props) {
  const { window, filteredRating, setCreateReqList,handleToggle,reportData,selectedCount } = props;
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const {totalCost} = checkTotalItemNPrice(reportData?.inspectiontemplates)
  const {lineData} = lineRepairDetails(reportData?.inspectiontemplates)
  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Root className="rl__appDrawer">
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(100% - ${drawerBleeding}px)`,
            overflow: matches ? "visible" : "hidden",
          },
        }}
      />
      {/* <Box sx={{ textAlign: 'center', pt: 1 }}>
        <Button onClick={toggleDrawer(true)}>Open</Button>
      </Box> */}
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        className="swipable_box"
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
          className={open ? "swiper_initial_opened" : "swipper_initial"}
        >
          <Puller className="puller" />
          {open ? (
            <Typography className="swiper_status_text" sx={{ p: 2, color: "text.secondary" }}>
              Swipe down to close
            </Typography>
          ) : (
            <div className="swiper_status_text" sx={{ p: 2, color: "text.secondary" }}>
              Swipe up for options
              <div className="swiper_initial_box">
                <span>Create a request list</span>
                <Card className={`request__list__paper p_cost`}>
                  <CardActions className="rl__cost__footer">
                    <span>Total ({lineData.length})</span>
                    <span>${totalCost}</span>
                  </CardActions>
                </Card>
              </div>
            </div>
          )}
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
          className="swiper__content"
        >
          {/* <Skeleton variant="rectangular" height="100%" /> */}
          <Grid item xs={12} md={3} className="border-paper">
            <div className="close__icon" onClick={() => setOpen(false)}>
              <CloseIcon />
            </div>
            <Typography className="filter__title">Create a request list</Typography>
            <Typography className="filter__subtitle">Toggle to show report items. Select a report item to add it to the request list.</Typography>
            <Grid className="rl__toggler__container">
              {filteredRating?.map((rating, ratingIndex) => (
                <div className="rl__toggler" key={ratingIndex}>
                  <Switch defaultChecked={rating.include_in_summary==='1' || rating?.include_in_summary === 1} name={rating.Name} onChange={(e)=>handleToggle(e)} className="switch" />{" "}
                  <div className="filter__box">
                    <span style={{ background: `${rating.Color}` }} className="filter_rating_color">
                      {rating.include_in_summary === "1" || rating?.include_in_summary === 1 ? <FlagIcon /> :""}
                    </span>
                    {`${rating.Name} (${findRatingCount(reportData?.inspectiontemplates,rating.Name)})`}{" "}
                  </div>
                </div>
              ))}
            </Grid>
            {/* Total cost card             */}
            <RepairTotalCostCard inspData={reportData.inspectiontemplates} />
            <Grid className="rl__buttons">
              <Button className={`border__button ${selectedCount < 1? 'disabled_btn':''}`} sx={{ marginRight: "6px", width: "130px" }} disableFocusRipple>
                <span className={`border__btn__text ${selectedCount <1?'disabled_btn':'' }`} onClick={selectedCount > 0 ? ()=>navigate('/preview'):undefined}>
                  Preview
                </span>
              </Button>
              <Button className={`filled__button ${selectedCount < 1? 'disabled_btn':''}`} sx={{ width: "125px" }}>
                <span className={`filled__btn__text ${selectedCount <1?'disabled_btn':'' }`} onClick={selectedCount>0?()=>setCreateReqList(true):undefined}>
                  Create
                </span>
              </Button>
            </Grid>
          </Grid>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

FilterMobileSection.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default FilterMobileSection;
