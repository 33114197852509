import DeliveryTeam from "./DeliveryTeam";
import DeliveryFooter from "./DeliveryFooter";
import DeliveryHeader from "./DeliveryHeader";
import DeliveryContent from "./DeliveryContent";
import DeliveryDetails from "./DeliveryDetails";
import DeliverySupport from "./DeliverySupport";

const DeliveryPageLayout = (props) => {
  const { data, reportSettings } = props;
  return (
    <>
      {reportSettings !== null ? <DeliveryHeader reportSettings={reportSettings} /> : ""}
      <DeliveryDetails details={data?.data} />
      <DeliveryContent coverPhoto={data?.coverPhoto} />
      <DeliverySupport supportData={data?.data?.company} />
      <DeliveryTeam details={data?.data} reportSettings={reportSettings} />
      <DeliveryFooter />
    </>
  );
};

export default DeliveryPageLayout;
