import { Button, Collapse, Divider, Grid, Paper } from "@material-ui/core";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from '@mui/icons-material/Close';
import {
  CommunicationIcon,
  TickIcon,
  AppliancesIcon,
  AlertIcon,
  PorchIcon,
} from "../../../../assets/svg/Svg";
import { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { origin } from "../../../helpers/HelperFunctions";

const DeliveryPorchAppMobilev1 = ({details}) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpanded = () => setExpanded(!expanded);
  const [open, setOpen] = useState(false);
  let BASE_URL = origin();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
    <Grid container className="mobile_app_section">
      <Grid item xs={12}>
        <p className="mobile_app_title">
          Two easy ways to view your inspection report:{" "}
        </p>
        <Paper
          className="mobile_app_section-paper paper_bg"
          style={{ margin: "15px 8px" }}
        >
          <div className="get_app_mobile" style={{ padding: "18px 12px" }}>
            <div className="get_app_mobile_title">
              <div className="oval_mobile">
                <PhoneIphoneIcon />
              </div>
              <p>Get your inspection report in the Porch app.</p>
            </div>
            <Button className="get_app_mobile_btn"  onClick={handleClickOpen}>
              GET THE APP
            </Button>
          </div>

          <Divider style={{ width: "100%" }} />
          <div className="collapsible_header" onClick={handleExpanded}>
            <div className="c_header">
              <p>
                Why get the app?{" "}
                {!expanded ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowUpIcon />
                )}
              </p>
            </div>
            <Collapse in={expanded}>
              <Grid container className="app_features_mobile">
                <div>
                  <span className="app-feature_text">
                    Save time and money, get access to professional booking
                    services, and get to know your home better:{" "}
                  </span>
                </div>
                <div className="feature_list_wrapper">
                  <div className="f_alert f_flex f_mobile">
                    <AlertIcon />{" "}
                    <span>Get immediate appliance recall alerts and track your appliance lifecycles.</span>
                  </div>
                  {/* <div className="f_apliance f_flex"><AppliancesIcon/><span>Manage and track appliances</span></div> */}
                  <div className="f_tick f_flex f_mobile">
                    <TickIcon />
                    <span>
                      Create to-do lists based on your inspection report
                    </span>
                  </div>
                  <div className="f_communication f_flex f_mobile">
                    <CommunicationIcon />
                    <span>
                      Get free help with your move with Porch Concierge
                    </span>
                  </div>
                </div>
              </Grid>
              <div className="f_powered_by_mobile">
                <span>
                  Powered by <PorchIcon />
                </span>
              </div>
            </Collapse>
          </div>
        </Paper>
        <p className="option">Or</p>
        <Paper
          className="mobile_app_section-paper"
          style={{ margin: "15px 8px" }}
        >
          <div className="get_app_mobile" style={{ padding: "18px 12px" }}>
            <div className="get_app_mobile_title">
              <div className="oval_mobile" style={{width:'79px'}}>
                <svg
                  width="24"
                  height="21"
                  viewBox="0 0 39 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 34.375V30.625H15.75V26.875H4.5C3.46875 26.875 2.58625 26.5081 1.8525 25.7744C1.1175 25.0394 0.75 24.1563 0.75 23.125V4.375C0.75 3.34375 1.1175 2.46062 1.8525 1.72562C2.58625 0.991875 3.46875 0.625 4.5 0.625H34.5C35.5313 0.625 36.4144 0.991875 37.1494 1.72562C37.8831 2.46062 38.25 3.34375 38.25 4.375V23.125C38.25 24.1563 37.8831 25.0394 37.1494 25.7744C36.4144 26.5081 35.5313 26.875 34.5 26.875H23.25V30.625H27V34.375H12ZM4.5 23.125H34.5V4.375H4.5V23.125Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div >
                <p className="report_online_text_title">View your report online.</p>
                <p className="report_online_text">
                  Just interested in your report? Go directly there to see
                  inspector comments, download the PDF, and make repair request
                  lists.
                </p>
              </div>             
            </div>
            <Button className="get_app_mobile_btn" variant="none" onClick={() => window.open(`${window.location.origin}/?guid=${sessionStorage.getItem("guid")}`, "_blank")}>
              view report
            </Button>
          </div>
        </Paper>
      </Grid>
    </Grid>
    {/* modal */}
        
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mob_modal"
      >
         <DialogTitle id="alert-dialog-title" className="rdp_app_modal_title_res">
        <span>How to use the app </span>
        <CloseIcon onClick={handleClose}/>
      </DialogTitle>
        <DialogContent>
        <Grid item xs={12} className="rdp_app_left_panel">
                <Grid container className="rdp_app_left_inner_res">
                    <Grid item xs={1}>
                    <span className="bg">1</span>
                    </Grid>
                    <Grid item xs={11}>
                    <span className="inner_text">Go to the app store, search for Porch, and download the app.</span>
                    <br/>
                    <a href={`${BASE_URL}/generate-porch-mobile-app-link/`} >
                    <Button className="get_app__" variant="none" >
                    download the app
                    </Button> </a> 
                    </Grid>   
                </Grid>
                <Grid container className="rdp_app_left_inner_res">
                    <Grid item xs={1}>
                    <span className="bg">2</span>
                    </Grid>
                    <Grid item xs={11}>
                    <span className="inner_text">When prompted, use your email  ({details?.orderclient?details?.orderclient[0]?.client?.email:''}) to access your specific report. If you use a different email than the one your inspector has on file, you will not have access to the report in the app.</span>
                    <br/>                   
                    </Grid>   
                </Grid>
                <Grid container className="rdp_app_left_inner_res">
                    <Grid item xs={1}>
                    <span className="bg">3</span>
                    </Grid>
                    <Grid item xs={11}>
                    <span className="inner_text">Have any questions or need help accessing the report? We can help.<br/><br/>
                    <span className="light_text">Call:</span> (855) 586-0867<br/>
                    <span className="light_text">Email: </span><u>reporthelp@palmtech.com</u></span>
                    <br/>                   
                    </Grid>   
                </Grid>
            </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeliveryPorchAppMobilev1;
