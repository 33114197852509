import { Grid, CssBaseline, Box } from "@mui/material";
import { useEffect } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import PDFSideMenu from "./PDFSideMenu";
import { useLocation } from "react-router-dom";

import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import AnimatedDots from "../../common/Animated/AnimatedDots";
import PDFView from "./PDFView";
// import { TexasHomeInspectionReport } from "./TexasPDF";
// import { HomeInspectionReport } from "./GeneratePdf";
// import { pdf } from "@react-pdf/renderer";
// import { saveAs } from "file-saver";

const Pdf = ({ pdfURL, isNarrative, isTexasReport, isPDFLoaded, setIsPDFLoader, setShowLoader, colorCode, companySettings, reportData, setPdfUrl, setIfPDFLoaded, showLoader, dataLoaded, isISN, openPdf, categoriesData, inspectionStatus, isPDFTemplate, selectedPDF, setSelectedPDF }) => {
  const location = useLocation();
  const userData = location.state?.name;
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
  });

  // const openPdf = async (screen) => {
  //   if (!isPDFLoaded) {
  //     setIsPDFLoader(true);
  //     setShowLoader(true);
  //     const blob = isTexasReport ? await pdf(<TexasHomeInspectionReport isTexasReport={isTexasReport} colorCode={colorCode} companySettings={companySettings} data={reportData} />).toBlob() : await pdf(<HomeInspectionReport isISN={isISN} colorCode={colorCode} companySettings={companySettings} data={reportData} />).toBlob();
  //     if (screen === 1) {
  //       saveAs(blob, "Home Inspection Report");
  //       setPdfUrl(URL.createObjectURL(blob));
  //       setIfPDFLoaded(true);
  //       setIsPDFLoader(false);
  //       setShowLoader(false);
  //       // uploadPDF(blob);
  //     }
  //     if (screen === 2) {
  //       setPdfUrl(URL.createObjectURL(blob));
  //       setIfPDFLoaded(true);
  //       setIsPDFLoader(false);
  //       setShowLoader(false);
  //       // uploadPDF(blob);
  //     }
  //   }
  // };
  useEffect(() => {
    if (isPDFTemplate === 0 && categoriesData?.length > 0 && !userData) {
      setSelectedPDF(categoriesData[0]?.pdf_report);
    } else {
      if (userData) {
        setSelectedPDF(userData);
      }
    }
    // eslint-disable-next-line
  }, [userData, isPDFTemplate]);

  useEffect(() => {
    if (dataLoaded && isPDFTemplate !== 0) {
      openPdf(2);
    }
  }, [dataLoaded, showLoader]);

  return (
    <>
      <Box sx={{ display: "flex", overflowX: "hidden" }}>
        <CssBaseline />
        <PDFSideMenu isNarrative={isNarrative} isPDFTemplate={isPDFTemplate} isISN={isISN} categoriesData={categoriesData} selected={selectedPDF} setSelected={setSelectedPDF} />
        {window.scroll(0, 0)}
        <Grid container sx={{ pt: { xs: "200px", md: "150px", lg: "150px" } }}>
          <Grid item xs={12} sm={12} md={11} lg={11} sx={{ height: "100vh", pt: "16px", pl: { xs: "5px", md: "'32px'", lg: "32px" }, width: "100%" }}>
            {selectedPDF === "default" ? (
              <>
                {isPDFLoaded ? (
                  <>
                    {inspectionStatus !== "Published" ? (
                      <PDFView fileUrl={pdfURL} inspectionStatus={inspectionStatus} />
                    ) : (
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                        <Viewer defaultScale={1} theme="dark" fileUrl={pdfURL} plugins={[defaultLayoutPluginInstance]} />
                      </Worker>
                    )}
                  </>
                ) : (
                  <div style={{ fontSize: "14px" }}>
                    <p className="loading_text">
                      The PDF version of this inspection report is getting generated
                      <AnimatedDots />
                    </p>
                  </div>
                )}
              </>
            ) : (
              <>
                {selectedPDF ? (
                  <>
                    {inspectionStatus !== "Published" ? (
                      <PDFView fileUrl={selectedPDF} inspectionStatus={inspectionStatus} />
                    ) : (
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
                        <Viewer defaultScale={1} theme="dark" fileUrl={selectedPDF} plugins={[defaultLayoutPluginInstance]} />
                      </Worker>
                    )}
                  </>
                ) : (
                  "No PDF Found."
                )}
              </>
            )}
            <br />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Pdf;
