import { useState } from "react";
import { Button, Grid } from "@mui/material";
// import GrainIcon from "@mui/icons-material/Grain";
import AdjustIcon from "@mui/icons-material/Adjust";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import FilterDramaIcon from "@mui/icons-material/FilterDrama";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import HouseSidingIcon from "@mui/icons-material/HouseSiding";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
// import ConstructionIcon from "@mui/icons-material/Construction";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Paper, Typography, Collapse, Divider } from "@material-ui/core";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
const AdditionalInfo = ({ inspDate, data }) => {
  const [expanded, setExpanded] = useState(true);
  const Others = data?.filter((value) => value?.prompt !== null && value?.is_deleted === 0);
  const middleIndex = Math.ceil(Others?.length / 2);
  const firstHalf = Others?.splice(0, middleIndex);
  const secondHalf = Others?.splice(-middleIndex);
  const handleExpanded = () => setExpanded(!expanded);

  const assignIcon = (type, prompt) => {
    if (prompt === "Weather") {
      return <FilterDramaIcon className="additional-info-icon" />;
    } else if (type === "Temperature") {
      return <ThermostatIcon className="additional-info-icon" />;
    } else if (prompt === "Water Source") {
      return <InvertColorsIcon className="additional-info-icon" />;
    } else if (prompt === "Others Present") {
      return <PeopleAltOutlinedIcon className="additional-info-icon" />;
    } else if (prompt === "Property Occupied") {
      return <HouseSidingIcon className="additional-info-icon" />;
    } else {
      return <AdjustIcon className="additional-info-icon" />;
    }
  };

  return (
    <Paper className="additional-info-paper">
      <Grid container>
        <Grid item xs={11} onClick={handleExpanded}>
          <Typography className="details-heading">ADDITIONAL INFO</Typography>
        </Grid>
        <Grid item xs={1} onClick={handleExpanded}>
          {expanded ? <ArrowDropUpIcon className="details-dropdownicon" /> : <ArrowDropDownIcon className="details-dropdownicon" />}
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expanded}>
            <Grid container sx={{ mt: "36px" }}>
              <Grid item lg={5.8}>
                <Grid container>
                  <Grid item xs={6}>
                    <Button className="additional-info-title" variant="none" startIcon={<DateRangeIcon className="additional-info-icon" />}>
                      Inspection date
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="additional-info-value">{inspDate}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className="inspection-details-divider" />
                  </Grid>
                  <Grid container>
                    {firstHalf
                      ? firstHalf.map((value) => {
                          const { prompt, line_input_controls, type } = value;
                          const dataValue = line_input_controls?.filter((val) => Object?.keys(val)?.includes("data"));
                          return (
                            <>
                              <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", paddingTop: "2px" }}>
                                  {assignIcon(type, prompt)}
                                  <p className="additional-info-title">{prompt}</p>
                                </div>
                                {type === "Temperature" ? (
                                  <p className="additional-info-value">
                                    {dataValue[0]?.data?.value} {dataValue[0]?.data?.type === "Far" ? "°F" : dataValue[0]?.data?.type === "Cel" ? "°C" : dataValue[0]?.data?.type}
                                  </p>
                                ) : (
                                  <p className="additional-info-value">{dataValue[0]?.data?.toString()}</p>
                                )}
                              </Grid>
                              <Grid item xs={12}>
                                <Divider className="inspection-details-divider" />
                              </Grid>
                            </>
                          );
                        })
                      : ""}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={0.4} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "flex" } }}>
                <Divider className="additional-info-divider-vertical" orientation="vertical" />
              </Grid>
              <Grid item lg={5.8}>
                <Grid container>
                  {secondHalf
                    ? secondHalf.map((value) => {
                        const { prompt, line_input_controls, type } = value;
                        const dataValue = line_input_controls?.filter((val) => Object?.keys(val)?.includes("data"));
                        return (
                          <>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                              <div style={{ display: "flex", paddingTop: "2px" }}>
                                {assignIcon(type, prompt)}
                                <p className="additional-info-title">{prompt}</p>
                              </div>
                              {type === "Temperature" ? (
                                <p className="additional-info-value">
                                  {dataValue[0]?.data?.value} {dataValue[0]?.data?.type === "Far" ? "°F" : dataValue[0]?.data?.type === "Cel" ? "°C" : dataValue[0]?.data?.type}
                                </p>
                              ) : (
                                <p className="additional-info-value">{dataValue[0]?.data?.toString()}</p>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Divider className="inspection-details-divider" />
                            </Grid>
                          </>
                        );
                      })
                    : ""}
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default AdditionalInfo;
