import { Box, Grid, Modal, Typography, IconButton, TextField, Button } from "@mui/material";
import { useValidatableForm, ReactValidatableFormProvider } from "react-validatable-form";
import "../../../assets/styles/containers/_shareReport.scss";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { apiCall } from "../../utils/action";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import validator from "validator";
import { useEffect, useState } from "react";
import get from "lodash.get";
import Popup from "../../common/popup/Popup";

const ShareReport = (props) => {
  let count = 0;
  const { open, isISN, clientEmail, handleClose, clientName, sampleReport, inspectionStatus } = props;
  const [showLoader, setShowLoader] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [content, setContent] = useState("Thanks for checking out the Palmtech Complete Interactive Report! This option allows your clients to share their inspection report with others quickly and easily.");
  const [heading, setHeading] = useState("Thanks!");
  const openConfimation = () => setConfirmationModal(true);
  const closeConfirmation = () => setConfirmationModal(false);
  const customRule = (ruleParams) => {
    const { value } = ruleParams;
    if (!value) {
      return "Email is required";
    } else if (value && value?.includes(",")) {
      return "Please provide single email";
    } else if (!validator.isEmail(value)) {
      return "Please enter a valid email address";
    }
    return null;
  };

  const initialFormData = {
    emails: [""],
    textVal1: "",

    textVal2: `Hello,\nI would like to share my inspection report with you. You can view the report by clicking the link below.\n${window.location.origin}/?guid=${sessionStorage.getItem("guid")}\n${clientName}`,
  };
  const rules = [
    { path: "textVal1", ruleSet: [{ rule: "required", customMessage: "Subject is required" }] },
    { path: "textVal2", ruleSet: [{ rule: "required", customMessage: "Message is required" }] },
    {
      listPath: "emails",
      ruleSet: [{ rule: customRule }],
    },
  ];
  const MyForm = () => {
    const { isValid, validationError, formData, setPathValue, setFormIsSubmitted } = useValidatableForm({
      rules,
      initialFormData,
      hideBeforeSubmit: true,
    });

    const shareReport = () => {
      setShowLoader(true);
      const body = get(formData, "textVal2");
      const params = {
        sender_name: clientName,
        sender_email: clientEmail,
        recipient_email: get(formData, "emails"),
        email_subject: get(formData, "textVal1"),
        email_body: body.replace(/\n/g, "<br />"),
        inspection_from: isISN ? "ISN" : "Palmtech",
      };
      apiCall(
        (response) => {
          const { message, success } = response.data;
          if (success) {
            toast.success(message);
            handleClose();
            setShowLoader(false);
          } else {
            toast.error(message);
            setShowLoader(false);
            console.log("else ", message);
          }
        },
        params,
        "SHARE_REPORT"
      );
    };

    const addEmail = () => {
      const updatedEmails = get(formData, "emails");
      if (count < 4) {
        updatedEmails.push("");
        count++;
      }
      setPathValue("emails", updatedEmails);
    };

    const removeEmail = (index) => {
      const updatedEmails = get(formData, "emails");
      if (count > 0) {
        updatedEmails.splice(index, 1);
        count--;
      }
      setPathValue("emails", updatedEmails);
    };

    const formSubmitted = () => {
      setFormIsSubmitted();
      if (isValid) {
        if (sampleReport) {
          openConfimation();
        } else {
          shareReport();
        }
      }
    };

    useEffect(() => {
      if (sampleReport) {
        handleClose();
        openConfimation();
      } else if (!sampleReport && inspectionStatus !== "Published") {
        setContent("This report has not been published yet and cannot be shared.");
        setHeading("Info");
        handleClose();
        openConfimation();
      }
    }, [sampleReport]);

    const emailList = get(formData, "emails").map((email, index) => {
      return (
        <TextField
          fullWidth
          variant="standard"
          id="standard-basic"
          label="Recipient Email"
          value={get(formData, `emails[${index}]`)}
          error={get(validationError, `emails{${index}}`) && "error"}
          helperText={get(validationError, `emails{${index}}`) || " "}
          onChange={(e) => setPathValue(`emails[${index}]`, e.target.value)}
          InputProps={{
            endAdornment:
              index > 0 ? (
                <IconButton onClick={() => removeEmail(index)}>
                  <CloseIcon />
                </IconButton>
              ) : (
                ""
              ),
          }}
        />
      );
    });

    return (
      <Grid container>
        <Grid item xs={12}>
          {emailList}
          <Button variant="none" className="add-recipient-button" endIcon={<AddIcon />} onClick={addEmail}>
            Add recipient
          </Button>
          <TextField fullWidth id="standard-basic" label="Subject" variant="standard" value={get(formData, `textVal1`)} error={get(validationError, `textVal1`) && "error"} helperText={get(validationError, `textVal1`) || " "} onChange={(e) => setPathValue(`textVal1`, e.target.value)} />
          <TextField fullWidth multiline minRows={10} className="contactus-messagebox" id="outlined-basic" placeholder="Message" variant="outlined" value={get(formData, `textVal2`)} error={get(validationError, `textVal2`) && "error"} helperText={get(validationError, `textVal2`) || " "} onChange={(e) => setPathValue(`textVal2`, e.target.value)} />
          <LoadingButton loading={showLoader} onClick={formSubmitted} className="contactus-send-button" variant="none">
            Send
          </LoadingButton>
        </Grid>
      </Grid>
    );
  };
  return (
    <>
      <Popup openPopup={confirmationModal} closePopup={closeConfirmation} content={content} heading={heading} />
      <Modal className="modal" open={open}>
        <Box className="modal-layout">
          <Grid container>
            <Grid item xs={10}>
              <Typography className="modal-title">Share Report</Typography>
            </Grid>
            <Grid item xs={2}>
              <CloseIcon className="close-icon" onClick={handleClose} />
            </Grid>
            <ReactValidatableFormProvider>
              <MyForm />
            </ReactValidatableFormProvider>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ShareReport;
