import { Grid } from '@material-ui/core'
import PTLogo from '../../../../assets/images/pt_horizontal.png'

const DeliveryMobileFooter = () => {
  return (
    <Grid container className="mobile_footer">
    <Grid
      item
      xs={12}
      className="mobile_footer_inner"
      style={{  marginLeft: "20px", alignItems: "center" }}
    >
      <p>Powered by</p>
      <img src={PTLogo}/>
    </Grid>
  </Grid>
  )
}

export default DeliveryMobileFooter