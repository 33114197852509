import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";
import "../../../assets/styles/containers/_carousel.scss";
import { Box, Grid, Modal } from "@mui/material";

const SampleVideoPlay = ({ open, handleClose,sampleVideoLink  }) => {
  return (
    <Modal open={open} onClose={handleClose} className="carousel-modal" sx={{ backgroundColor: "rgb(25,25,25,0.8)" }}>
      <Box className="carousel-modal-layout">
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12}>
            <CloseIcon className="carousel-close-icon" onClick={handleClose} />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>                
                  <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                    <ReactPlayer controls height="75vh" width="90%" url={sampleVideoLink} />
                  </div>
              </Grid>            
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default SampleVideoPlay;
