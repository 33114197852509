//file imports
import { Header } from "./Header";
import { Footer } from "./GeneratePdf";
import { Page, Text, Link, View } from "@react-pdf/renderer";
import { BendArrowIcon } from "../../../assets/svg/Svg";
import { contentStyles, commonStyles, ToCStyles } from "./PDFStyles";

export const TableOfContents = ({ isISN, inspectiontemplates, company_html_report_setting, color, inspection_details, attachments }) => (
  <Page style={contentStyles.body}>
    <Header isISN={isISN} address={inspection_details?.address} clientDetails={inspection_details?.orderclient} companySettings={company_html_report_setting} />
    <View style={commonStyles.headingContainer} fixed>
      <Text style={[commonStyles.headingTitle, { backgroundColor: color }]}> Table of Contents</Text>
    </View>
    <View style={ToCStyles.tocContainer}>
      {inspectiontemplates?.map((templateValue, templateIndex) => {
        return (
          <>
            <Text style={[ToCStyles.templateTitle, { marginTop: templateIndex !== 0 ? "10px" : "0px", color: color }]}>{templateValue.report_title ? templateValue.report_title : templateValue.name}</Text>
            <View style={commonStyles.flexWrapper}>
              <View style={commonStyles.flexContainer}>
                <Text style={ToCStyles.categoryTitle}>Inspection Details</Text>
              </View>
              <View style={[commonStyles.flexContainer, commonStyles.alignClass]}>
                <Text style={ToCStyles.categoryTitle}></Text>
              </View>
            </View>
            <View style={commonStyles.flexWrapper}>
              <View style={commonStyles.flexContainer}>
                <Text style={ToCStyles.categoryTitle}>Definitions</Text>
              </View>
              <View style={[commonStyles.flexContainer, commonStyles.alignClass]}>
                <Text style={ToCStyles.categoryTitle}></Text>
              </View>
            </View>
            {templateValue?.categories
              ?.filter((value) => value?.is_inspection_details !== 1 && value?.is_inspection_details !== 2)
              ?.map((categoryValue) => {
                return <Text style={ToCStyles.categoryTitle}>{categoryValue.prompt}</Text>;
              })}
          </>
        );
      })}
      <Text style={ToCStyles.summaryTitle}>Summary</Text>
      {!attachments ? (
        ""
      ) : (
        <>
          <Text style={[ToCStyles.templateTitle, { marginTop: "32px", color: color }]}>attachments</Text>
          {attachments?.map((value) => {
            return (
              <View style={commonStyles.flexWrapper}>
                <View style={commonStyles.flexContainer}>
                  <Text style={ToCStyles.categoryTitle}>{value?.description}</Text>
                </View>
                <View>
                  <Link src={value?.onlineUrl} target={"_blank"} style={ToCStyles.link}>
                    <BendArrowIcon />
                  </Link>
                </View>
              </View>
            );
          })}
        </>
      )}
    </View>
    <Footer isISN={isISN} />
  </Page>
);
