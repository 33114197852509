import moment from "moment";
import { Grid } from "@mui/material";
const DeliveryDetailsv1 = (props) => {
  const { details,coverPhoto } = props;
  return (
    <Grid container className="delivery__grid delivery-header-details-containerv1">
            <Grid item xs={2} sm={1} md={1} > 
                {coverPhoto ? <img src={coverPhoto} alt="property" className="delivery_property_image" /> : ""}            
            </Grid>
            <Grid item xs={9} sm={6} md={6} className="pl-20">
                <div className="delivery-details-container">
                    <p className="delivery-details-addressv1">
                    {details?.location_address}, {details?.location_address_2 !== null && details?.location_address_2 !== ''?`${details?.location_address_2},`:''} {details?.location_city}, {details?.location_state} {details?.location_zip}
                    </p>
                    <p className="delivery-details-date">Date of inspection: {moment(details?.start_time).format("MMM D, YYYY")}</p>
                </div>
            </Grid>
            <Grid item xs={1} sm={5} md={5} >
                <Grid container>
                    <Grid item sm={6} md={6}>
                        <Grid container>
                            {details?.orderinspector?.map((insp,index)=>(
                                <Grid item xs={12} key={index}>
                                <div className="delivery-details-container">
                                    {<p className="delivery-details-title" style={{ fontSize: "11px" }}>
                                        Inspector
                                    </p>}
                                    <p className="delivery-details-addressv1">
                                        {insp?.inspector?.first_name} {insp?.inspector?.last_name}
                                    </p>
                                    <p className="delivery-insp-number">
                                    <a className="delivery-insp-number" href={`tel:${insp?.inspector?.phone}`}>{insp?.inspector?.phone}</a>
                                      </p>
                                </div>
                            </Grid>
                            ))}                            
                        </Grid>
                   
                    
                    </Grid>
                    <Grid item sm={6} md={6}>
                    <Grid container>
                        {details?.orderagent?.map((agnt,index)=>(
                            agnt?.agent_type === 1?
                            <Grid item xs={12} key={index}>
                                <div className="delivery-details-container">
                                <p className="delivery-details-title" style={{ fontSize: "11px" }}>
                                    Buyers agent
                                </p>
                                <p className="delivery-details-addressv1">
                                {`${agnt?.agent?.first_name} ${agnt?.agent?.last_name!==null?agnt?.agent?.last_name:''}`}
                                {/* {agnt?.agent?.first_name} {agnt?.agent?.last_name} */}
                                </p>
                                <p className="delivery-insp-number"> 
                                <a className="delivery-insp-number" href={`tel:${agnt?.agent?.phone}`}>{agnt?.agent?.phone}</a></p>
                                </div>
                            </Grid>
                            :''
                     ))}
                    </Grid>
                    </Grid>
                </Grid>            
            </Grid>  
    </Grid>
  );
};

export default DeliveryDetailsv1;