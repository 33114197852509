import { useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Paper, Typography, Collapse, Divider, Grid } from "@material-ui/core";
const CollapsibleClientDetails = (props) => {
  const { data, index, length } = props;
  const [expanded, setExpanded] = useState(length >= 2 ? false : true);
  const handleExpanded = () => setExpanded(!expanded);
  return (
    <Paper className="client-details-paper">
      <Grid container>
        <Grid item xs={11} onClick={handleExpanded}>
          <Typography className="details-heading">CLIENT {length > 1 ? `${index + 1}` : ""} </Typography>
          <Typography className="details-inpector-name">
            {data?.client?.first_name} {data?.client?.last_name}
          </Typography>
        </Grid>
        <Grid item xs={1} onClick={handleExpanded}>
          {expanded ? <ArrowDropUpIcon sx={{ mt: 1 }} className="details-dropdownicon" /> : <ArrowDropDownIcon sx={{ mt: 1 }} className="details-dropdownicon" />}
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expanded}>
            <br />
            <Grid container>
              {data?.client?.phone ? (
                <>
                  <Grid item xs={6} className="details-phone-title">
                    Phone
                  </Grid>
                  <Grid item xs={6} className="details-phone-value">
                    {data?.client?.phone}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className="inspection-details-divider" />
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item xs={2} className="details-phone-title">
                Email
              </Grid>
              <Grid item xs={10} className="details-email-value">
                {data?.client?.email}
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CollapsibleClientDetails;
