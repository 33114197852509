import Router from "./router/router";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
function App() {
  return (
    <>
      <Toaster position="bottom-left"
      toastOptions={{
        className: "toastbar"
      }} />
      <div className="app_container">
        <div className="main__container">
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </div>
      </div>
    </>
  );
}
export default App;
