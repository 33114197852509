// file import
import axios from "axios";
import { decode } from "js-base64";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { apiCall } from "../../utils/action";
import React, { useRef, useEffect, useState } from "react";

const MapView = () => {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const [key, setKey] = useState();
  const [zoom, setZoom] = useState(2);
  const [center, setCenter] = useState([71.23, 23.23]);
  mapboxgl.accessToken = key;
  const getKey = () => {
    apiCall(
      (response) => {
        const { success, data } = response.data;
        if (success) {
          setKey(decode(data));
        } else {
          console.log("else", response.data.message);
        }
      },
      "",
      "GET_KEY"
    );
  };
  useEffect(() => {
    getKey();
  }, []);
  // initialize map only once
  useEffect(() => {
    if (key) {
      map.current = new mapboxgl.Map({
        style: "mapbox://styles/mapbox/streets-v11",
        container: mapContainer.current,
        attributionControl: false,
        center: center,
        zoom: zoom,
      });

      axios
        .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${localStorage.getItem("address")}.json?&limit=1&access_token=${key}`)
        .then((response) => {
          setCenter(response.data.features[0].center);
          localStorage.setItem("marker", JSON.stringify(response.data.features[0].center));
          map.current.flyTo({
            center: response.data.features[0].center,
            essential: false,
            speed: 3,
            zoom: 5,
          });
          new mapboxgl.Marker({ color: "Red", display: "block" }).setLngLat(response.data.features[0].center).addTo(map.current);
          setZoom(6);
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log("no response", error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });

      //controls for map
      map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
      //move to marker
      map.current.flyTo({
        essential: false,
        center: center,
        speed: 3,
        zoom: 5,
      });
    }
    // eslint-disable-next-line
  }, [key]);

  //render
  return <>{key ? <div ref={mapContainer} className="map-container" style={{ height: "100vh", width: "100%" }} /> : ""}</>;
};
export default MapView;
