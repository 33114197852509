import { Divider, Grid, Typography } from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { NarrativePhoneIcon } from "../../../../assets/svg/Svg";
const NarrativeClientDetailsLayout = (props) => {
    const { clientDetails, logo } = props;
    return <Grid container sx={{ p: logo ? "12px" : "0px", mt: !logo ? "36px" : "0px" }}>
        {clientDetails.length !== 0 ? <Grid item xs={12}>
            <Typography className="details-heading">CLIENT INFORMATION</Typography>
            <Divider className="narrative-details-divider" />
        </Grid> : ""}
        {clientDetails?.map((value, index) => {
            return <Grid item xs={clientDetails?.length === 1 ? 12 : 6} key={index}>
                <Typography className="narrative-client-name">{value?.client?.first_name} {value?.client?.last_name}</Typography>
                <Grid container sx={{ gap: "8px", mt: "8px" }}>
                    {value?.client?.phone?.trim() && <Grid item xs={12} className="narrative-details">
                        <NarrativePhoneIcon />
                        <Typography sx={{ overflowWrap: "anywhere" }} className="narrative-details-font-size">
                            {value?.client?.phone}
                        </Typography>
                    </Grid>}
                    <Grid item xs={12} className="narrative-details">
                        <MailOutlineIcon className="narrative-mail-icon" />
                        <Typography sx={{ overflowWrap: "anywhere" }} className="narrative-details-font-size" >
                            {value?.client?.email}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        })}
    </Grid>
}

export default NarrativeClientDetailsLayout;