import config_settings from "./config";

let BASE_URL;
let PORTAL_URL;

const origin = () => {
  let origin = window.location.origin.toLowerCase();
  if (origin?.includes("https://")) origin = origin.replace("https://", "");
  else origin = origin.replace("http://", "");
  BASE_URL = config_settings[origin].SERVER_URL;
  PORTAL_URL = config_settings[origin].PORTAL_URL;
  console.log("origin", origin);
  console.log("BASE_URL", BASE_URL);
};
origin();

export const API_CONSTANTS = {
  GET_KEY: `${PORTAL_URL}/get-mapbox-key`,
  SHARE_REPORT: `${BASE_URL}/send-report-mail`,
  GET_REPORT_DATA: `${BASE_URL}/get-report-data`,
  CONTACT_US: `${BASE_URL}/send-contact-us-mail`,
  GET_INSPECTION_DATA: `${BASE_URL}/get-inspection-data`,
  STORE_REPAIR_DATA: `${BASE_URL}/store-request-list-data`,
  CREATE_REPORT_MAIL: `${BASE_URL}/send-request-list-mail`,
  UPLOAD_PDF: `${BASE_URL}/upload-pdf`,
  RW_PDF_GENERATE:`${BASE_URL}/rw-pdf-generate`,
  SEND_DATA_TO_PORCH: `${BASE_URL}/send-porch-data`,
  UPDATE_RDP_CLICK_COUNT: `${BASE_URL}/update-rdp-click-count`,
  CLEAR_SAMPLE_REPORT_DATA:`${BASE_URL}/clear-sample-report-data`,
};

export const API_METHODS = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  UPDATE: "UPDATE",
  PUT: "PUT",
};
