import { Text, Image, View } from "@react-pdf/renderer";
import { contentStyles, commonStyles } from "./PDFStyles";
import HomeTeamLogo from "../../../assets/images/HomeTeamIcon.png";
export const Header = ({ address, clientDetails, companySettings, isNarrative }) => (
  <View style={contentStyles.headerWrapper} fixed>
    {isNarrative ? <View style={commonStyles.flexContainer}><Image style={contentStyles.headerLeft} src={HomeTeamLogo}></Image></View> : companySettings ? <View style={commonStyles.flexContainer}>{companySettings?.use_company_name_as_logo === 1 ? <Text style={{ fontSize: "12px" }}>{companySettings?.company_name}</Text> : companySettings?.company_logo_url ? <Image style={contentStyles.headerLeft} src={companySettings?.company_logo_url}></Image> : ""}</View> : ""}
    <View style={[commonStyles.flexContainer, commonStyles.alignClass]} fixed>
      <Text style={contentStyles.headerRight}>{address?.split(",", 1)}</Text>
      <Text style={contentStyles.headerRightInfo}>
        Prepared for:{" "}
        {clientDetails?.map((value, index) => {
          const { first_name, last_name } = value?.client;
          return `${first_name} ${last_name ? last_name : ""}${index === 0 && clientDetails?.length > 1 ? "," : " "} `;
        })}
      </Text>
    </View>
  </View>
);
