import Html from "react-pdf-html";
import { Text, Image, View } from "@react-pdf/renderer";
import { FlagIconRating, VideoIcon } from "../../../assets/svg/Svg";
import { convertFontTagsToInlineStyles, stylesheet } from "./HtmlConverter";
import { definationStyles, fullReportStyles, mediaStyles, RatingStyles } from "./PDFStyles";
const CommentSectionPDF = ({ comments, color }) => {
  return (
    <>
      {comments?.length > 0 ? <Text style={[fullReportStyles.lineDesc, { marginTop: "5px", marginBottom: "5px", color: color }]}>comments:</Text> : ""}
      {comments?.map((commentValue, commentIdx) => {
        const location = commentValue?.comment_input_controls?.filter((e) => e?.type === "Location");
        return commentValue?.is_selected === 1 ? (
          <>
            {commentValue?.comment_input_controls?.filter((e) => e?.type === "Rating")?.length > 0 ? (
              <View wrap={false} style={{ padding: "2px 7px 2px 10px" }}>
                <View style={[RatingStyles.outerContainer, { flexWrap: "wrap" }]}>
                  {commentValue?.comment_input_controls
                    ?.filter((e) => e?.type === "Rating")
                    ?.map((value) => {
                      const { data } = value;
                      return (
                        <View style={[RatingStyles.innerContainer, { gap: "5px", flexWrap: "wrap" }]}>
                          {data?.map((ratingValue, ratingIndex) => {
                            const { Color, Name, include_in_summary } = ratingValue;
                            let length = Name?.match(/(\w+)/g)?.length;
                            return (
                              <View wrap={false} style={[RatingStyles.coloredContainer, { border: `1px solid ${Color}`, height: length > 48 ? "29px" : "18px", margin: "5px 0px 5px 0px" }]}>
                                <View style={[RatingStyles.flagContainer, { flex: 1 }]}>
                                  <View style={RatingStyles.flagInnerContainer}>
                                    <View style={[RatingStyles.flag, { backgroundColor: Color }]}>{include_in_summary === "1" || include_in_summary === 1 ? <FlagIconRating /> : ""}</View>
                                  </View>
                                  <Text style={RatingStyles.textContainer}>{Name}</Text>
                                </View>
                              </View>
                            );
                          })}
                        </View>
                      );
                    })}
                </View>
              </View>
            ) : (
              ""
            )}
            <View style={{ padding: "2px 7px 2px 10px" }}>
              {commentValue?.is_mannual_prompt === 0 || commentValue?.prompt === "null" || commentValue?.prompt === null || prompt === "" ? (
                commentValue?.recommendation ? (
                  <View style={fullReportStyles.commentHTMLContainer}>
                    <Html stylesheet={stylesheet} style={{ fontSize: "10px" }}>
                      {convertFontTagsToInlineStyles(commentValue?.recommendation)}
                    </Html>
                  </View>
                ) : (
                  ""
                )
              ) : commentValue?.is_mannual_prompt === 1 && commentValue?.prompt ? (
                <Text style={[definationStyles.ratingDisclaimer, { fontWeight: "600" }]}>{commentValue.prompt}</Text>
              ) : prompt ? (
                <Text style={[definationStyles.ratingDisclaimer, { fontWeight: "600" }]}>{commentValue.prompt}</Text>
              ) : (
                ""
              )}
            </View>
            <View style={{ padding: "2px 7px 2px 10px" }}>
              {commentValue?.is_mannual_prompt === 0 || commentValue?.prompt === "null" || commentValue?.prompt === null || commentValue?.prompt === "" ? (
                ""
              ) : (
                <View style={[fullReportStyles.commentHTMLContainer, { paddingLeft: "2px" }]}>
                  {commentValue?.recommendation ? (

                    <Html stylesheet={stylesheet} style={{ fontSize: "10px", paddingLeft: "5px" }}>
                      {convertFontTagsToInlineStyles(commentValue?.recommendation)}
                    </Html>
                  ) : (
                    ""
                  )}
                </View>
              )}
            </View>
            <View style={{ flexDirection: "row", gap: "5px", paddingRight: "5px", justifyContent: "space-between" }}></View>
            {location[0]?.data && location[0]?.data?.length > 0 ? (
              <View style={{ display: "relative", marginLeft: "10px" }}>
                <Text style={[definationStyles.ratingDisclaimer, { display: "block", fontWeight: "600" }]}>
                  {location[0]?.prompt} -{" "}
                  {typeof location[0]?.data === "string" ? (
                    <Text style={definationStyles.ratingDisclaimer}>{location[0]?.data?.replace(/,/g, ", ")}</Text>
                  ) : typeof location[0]?.data === "object" ? (
                    location[0]?.data?.map((item, index) => {
                      return (
                        <Text style={definationStyles.ratingDisclaimer}>
                          {item}
                          {index < location[0]?.data?.length - 1 && ", "}
                        </Text>
                      );
                    })
                  ) : (
                    ""
                  )}
                </Text>
              </View>
            ) : (
              ""
            )}
            {commentValue?.comment_input_controls
              ?.filter((e) => e?.type === "Media")
              ?.map((value) => {
                const { data } = value;
                return data?.filter((e) => e?.online === "online")?.length > 0 ? (
                  <View style={mediaStyles.wrapper}>
                    {data
                      ?.filter((e) => e?.online === "online")
                      ?.map((mediaValue) => {
                        const { type, onlineUrl, description, thumbnail } = mediaValue;
                        return type?.includes("image") ? (
                          <View style={mediaStyles.div}>
                            {thumbnail ? <Image wrap={false} style={mediaStyles.image} src={thumbnail}></Image> : <Image wrap={false} style={mediaStyles.image} src={onlineUrl}></Image>}
                            <Text style={mediaStyles.text}>{description}</Text>
                          </View>
                        ) : type?.includes("video") ? (
                          <View style={mediaStyles.div}>
                            <View style={mediaStyles.innerDiv}>
                              <Image style={mediaStyles.image} src={thumbnail}></Image>
                              <View style={fullReportStyles.videoContainer}>
                                <View wrap={false} style={fullReportStyles.videoInnerContainer}>
                                  <View style={fullReportStyles.videoIconContainer}>
                                    <View style={fullReportStyles.videoIconInnerContainer}>
                                      <View style={fullReportStyles.videoIcon}>
                                        <VideoIcon />
                                      </View>
                                    </View>
                                    <View style={fullReportStyles.videoTitleContainer}>
                                      <Text style={fullReportStyles.videoTitle}>Video</Text>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            </View>
                            <Text style={[mediaStyles.text, { textAlign: description?.length > 50 ? "justify" : "center" }]}>{description}</Text>
                          </View>
                        ) : (
                          ""
                        );
                      })}
                  </View>
                ) : (
                  ""
                );
              })}
            {comments.length !== commentIdx + 1 ? (
              <View style={{ padding: "0px 7px 0px 10px" }}>
                <View style={fullReportStyles.commentDividerGrey}></View>
              </View>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        );
      })}
    </>
  );
};
export default CommentSectionPDF;
