import { Grid } from "@material-ui/core"
import { Button } from "@material-ui/core"
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import LaunchIcon from '@mui/icons-material/Launch';

const DeliveryTeamv1 = ({details}) => {
  function formatPhoneNumber(phoneNumber) {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null; // Return null for invalid input
  }
  return (
    <Grid container className="delivery__grid delivery-header-details-containerv1 d_team">
    <Grid item xs={2} sm={1} md={1}> </Grid>
   
    
     <Grid item xs={12} sm={11} md={11} className="pl-20">
     <p className="d_team_head">Your team</p>
     <Grid container>
     {details?.orderinspector?.map((insp,index)=>(
    <Grid item sm={6} md={3} key={index}>
      
        <div className="ins_agn_section">
            <p className="ins_ajn_title">Your Inspector</p>                
            <p className="ins_ajn_name"> {insp?.inspector?.first_name} {insp?.inspector?.last_name}</p>
            <div className="ins_ajn_flex">
                <div className="contact_icon_desk"><CallIcon/><span><a className="delivery-insp-number" href={`tel:${insp?.inspector?.phone}`}>{formatPhoneNumber(insp?.inspector?.phone)}</a> </span></div>
                <div className="contact_icon_desk"><MailIcon/><span><a href={`mailto:${insp?.inspector?.email}`}>{insp?.inspector?.email}</a></span></div>
                {details?.company?.website !=='' && details?.company?.website !== null?<div className="contact_icon_desk"><LaunchIcon/><span><a href={`mailto:${details?.company?.website}`}>{details?.company?.website}</a></span></div>:''}
            </div>
            
        </div>
           
        {index === 0 &&
        <a href={`mailto:${insp?.inspector?.email}`}>
          <Button className="get_app_mobile_btn_team" variant="none">
            send a message
            </Button>  
        </a>}
        
    </Grid>
     ))}
     {details?.orderagent?.map((agnt,index)=>(
     <Grid item sm={6} md={3} key={index}>
     <div className="ins_agn_section">
            <p className="ins_ajn_title">Your agent</p>                
            <p className="ins_ajn_name"> {agnt?.agent?.first_name} {agnt?.agent?.last_name}</p>
            <div className="ins_ajn_flex">
                <div className="contact_icon_desk"><CallIcon/><span><a className="delivery-insp-number" href={`tel:${agnt?.agent?.phone}`}>{formatPhoneNumber(agnt?.agent?.phone)}</a> </span></div>
                <div className="contact_icon_desk"><MailIcon/><span><a href={`mailto:${agnt?.agent?.email}`}>{agnt?.agent?.email}</a> </span></div>
            </div>
        </div>
     </Grid>
     ))}
    
     </Grid>
     </Grid>       
    </Grid>
  )
}

export default DeliveryTeamv1